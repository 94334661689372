import React from 'react';
import Loading from '../../../../components/loading/Loading';
import { jwtDecode } from 'jwt-decode';
import { useEffect, useState, useCallback } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../context/useAuth';
import { PERMISSIONS } from '../../../../utils/permissions';
import { AXIOS_API_CALL } from '../../../../utils/endpoint';
import axios from 'axios';
import { SERVER_URL } from '../../../../config/index';
import { notification } from 'antd';

const ViewOrder = () => {
	const { orderId } = useParams();
	const { user } = useAuth();
	const location = useLocation();
	const [groceryPermissions, setGroceryPermissions] = useState([]);
	const [orderDetails, setOrderDetails] = useState(null); // State to store product data
	const [readDataLoading, setReadDataLoading] = useState(true);
	const navigate = useNavigate();

	useEffect(() => {
		const { token } = user || {};
		if (token) {
			const decodedToken = jwtDecode(token);
			const permissions = decodedToken?.roleData?.permissions;

			if (permissions && location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`)) {
				if (Object.keys(permissions).some((permission) => permission.includes('grocery'))) {
					setGroceryPermissions(permissions.grocery || []);
				}
			}
		}
	}, [user, location]);

	useEffect(() => {
		const getReadData = async () => {
			const { token } = user;
			try {
				const response = await axios.get(`${SERVER_URL}/${AXIOS_API_CALL.getOrderDetails}/${orderId}`, {
					withCredentials: false,
					headers: { Authorization: `Bearer ${token}` },
				});
				setOrderDetails(response.data);
			} catch (err) {
				console.error('Error fetching data:', err);
			} finally {
				setReadDataLoading(false);
			}
		};

		if (orderId) {
			getReadData();
		}
	}, [orderId, user]);

	const onVerify = useCallback(
		async (verificationId) => {
			const { token } = user || {};
			if (!token) {
				notification.error({ message: 'Authorization token not found. Please log in again.' });
				return;
			}

			try {
				const res = await axios.put(
					`${SERVER_URL}/${AXIOS_API_CALL.verifyOrder}/${verificationId}`,
					{},
					{
						headers: { Authorization: `Bearer ${token}` },
					}
				);

				if (res.status === 200) {
					notification.success({ message: 'Order verified successfully!' });
					setTimeout(() => {
						window.location.reload();
					}, 500);
				}
			} catch (err) {
				console.error('Error verifying order:', err);
				if (err.response.status === 404) {
					notification.error({ message: err.response.data.error });
				} else if (err.response.status === 403) {
					notification.info({ message: err.response.data.message });
				} else if (err.response.status === 409) {
					notification.error({ message: err.response.data.error || 'Request Timeout' });
				} else {
					notification.error({ message: 'Failed to verify order.' });
				}
			}
		},
		[user]
	);

	const onCancelOrder = useCallback(
		async (_id) => {
			const { token } = user || {};
			if (!token) {
				notification.error({ message: 'Authorization token not found. Please log in again.' });
				return;
			}

			try {
				const res = await axios.put(
					`${SERVER_URL}/${AXIOS_API_CALL.cancelOrder}/${_id}`,
					{},
					{
						headers: { Authorization: `Bearer ${token}` },
					}
				);

				if (res.status === 200) {
					notification.success({ message: 'Order canceled successfully!' });
					setTimeout(() => {
						window.location.reload();
					}, 500);
				}
			} catch (err) {
				console.error('Error verifying order:', err);
				if (err.response.status === 404) {
					notification.error({ message: err.response.data.error });
				} else if (err.response.status === 403) {
					notification.info({ message: err.response.data.message });
				} else if (err.response.status === 409) {
					notification.error({ message: err.response.data.error || 'Request Timeout' });
				} else {
					notification.error({ message: 'Failed to verify order.' });
				}
			}
		},
		[user]
	);

	const onAuthenticate = useCallback(
		async (_id) => {
			const { token } = user || {};
			if (!token) {
				notification.error({ message: 'Authorization token not found. Please log in again.' });
				return;
			}

			try {
				const res = await axios.put(
					`${SERVER_URL}/${AXIOS_API_CALL.authenticateOrder}/${_id}`,
					{},
					{
						headers: { Authorization: `Bearer ${token}` },
					}
				);

				if (res.status === 200) {
					notification.success({ message: 'Order authenticated successfully!' });
					setTimeout(() => {
						window.location.reload();
					}, 500);
				}
			} catch (err) {
				console.error('Error verifying order:', err);
				if (err.response.status === 404) {
					notification.error({ message: err.response.data.error });
				} else if (err.response.status === 403) {
					notification.info({ message: err.response.data.message });
				} else if (err.response.status === 409) {
					notification.error({ message: err.response.data.error || 'Request Timeout' });
				} else {
					notification.error({ message: 'Failed to verify order.' });
				}
			}
		},
		[user]
	);

	const onApproveReject = useCallback(
		async (_id, status) => {
			const { token } = user || {};
			if (!token) {
				notification.error({ message: 'Authorization token not found. Please log in again.' });
				return;
			}

			try {
				const res = await axios.put(
					`${SERVER_URL}/${AXIOS_API_CALL.approveRejectTransaction}/${_id}`,
					{
						status: status,
					},
					{
						headers: { Authorization: `Bearer ${token}` },
					}
				);

				if (res.status === 200) {
					notification.success({ message: 'Order authenticated successfully!' });
					setTimeout(() => {
						window.location.reload();
					}, 500);
				}
			} catch (err) {
				console.error('Error verifying order:', err);
				if (err.response.status === 404) {
					notification.error({ message: err.response.data.error });
				} else if (err.response.status === 403) {
					notification.info({ message: err.response.data.message || err.response.data.error });
				} else if (err.response.status === 409) {
					notification.error({ message: err.response.data.error || 'Request Timeout' });
				} else {
					notification.error({ message: 'Failed to verify order.' });
				}
			}
		},
		[user]
	);

	const onCancel = useCallback(
		async (verificationId) => {
			const { token } = user || {};
			if (!token) {
				notification.error({ message: 'Authorization token not found. Please log in again.' });
				return;
			}

			try {
				const res = await axios.put(
					`${SERVER_URL}/${AXIOS_API_CALL.rejectVerification}/${verificationId}`,
					{},
					{
						headers: { Authorization: `Bearer ${token}` },
					}
				);

				if (res.status === 200) {
					notification.success({ message: 'Order cancelled!' });
					setTimeout(() => {
						window.location.reload();
					}, 500);
				}
			} catch (err) {
				console.error('Error canceling order:', err);
				if (err.response.status === 403) {
					notification.info({ message: err.response.data.message });
				} else if (err.response.status === 409) {
					notification.error({ message: err.response.data.error || 'Request Timeout' });
				} else {
					notification.error({ message: 'Failed to cancel order.' });
				}
			}
		},
		[user]
	);

	const onReject = useCallback(
		async (_id) => {
			const { token } = user || {};
			if (!token) {
				notification.error({ message: 'Authorization token not found. Please log in again.' });
				return;
			}

			try {
				const res = await axios.put(
					`${SERVER_URL}/${AXIOS_API_CALL.rejectOrder}/${_id}`,
					{},
					{
						headers: { Authorization: `Bearer ${token}` },
					}
				);

				if (res.status === 200) {
					notification.success({ message: 'Order rejcted!' });
					setTimeout(() => {
						window.location.reload();
					}, 500);
				}
			} catch (err) {
				console.error('Error canceling order:', err);
				if (err.response.status === 403) {
					notification.info({ message: err.response.data.message });
				} else if (err.response.status === 409) {
					notification.error({ message: err.response.data.error || 'Request Timeout' });
				} else {
					notification.error({ message: 'Failed to cancel order.' });
				}
			}
		},
		[user]
	);

	const onClose = useCallback(() => {
		navigate(-1);
	}, [navigate, location]);

	if (!orderDetails) return <p className="dark">No order data available</p>;

	const { status, totalPrice, shoePrice, createdAt, shoeDetails, sellerDetails, buyerDetails, verificationDetails, _id, ID, orderNumber, confirmationExpiredAt, verificationExpiredAt } = orderDetails;

	const { name, colorway, sku, size } = shoeDetails;

	const { firstName: buyerFirstName, lastName: buyerLastName, transactionFee, shippingPrice } = buyerDetails;

	const { firstName: sellerFirstName, lastName: sellerLastName, paymentProcFee, totalSellerPayout } = sellerDetails;

	console.log(orderDetails);

	return (
		<div className="view-order">
			<h4 className="product-name">Order #{orderNumber}</h4>
			<div className="view-order__footer">
				<div className={`form-group form-group-left `}>
					<button onClick={() => onCancelOrder(_id)} data-cy="modal-close-btn" disabled={status !== 'PENDING_ADMIN_APPROVAL' && status !== 'PENDING_CONFIRMATION' && status !== 'PENDING_VERIFICATION' && status !== 'DELIVERED_FOR_AUTHENTICATION' && status !== 'SENT_ON_VERIFICATION' && status !== 'SENT_ON_VERIFICATION'} type="button" className="ant-btn btn btn-primary-link btn-block btn-cancel-order">
						<span className="text">Cancel Order</span>
					</button>

					<div className="form-group form-group-sidebar">
						<button data-cy="modal-close-btn" type="button" disabled={status !== 'DELIVERED_FOR_AUTHENTICATION'} className="btn btn-primary-link" onClick={() => onAuthenticate(_id)}>
							<span className="text">Authenticate</span>
						</button>
						<button data-cy="modal-close-btn" type="button" disabled={status !== 'DELIVERED_FOR_AUTHENTICATION'} className="btn btn-primary-outline" onClick={() => onReject(_id)}>
							<span className="text">Reject</span>
						</button>
					</div>

					<a href={orderDetails.label_url} target="_blank" rel="noopener noreferrer" className="ant-btn btn btn-primary-outline pt-1" disabled={status != 'PENDING_SHIPMENT_TO_ADMIN' && status != 'PENDING_SHIPMENT_TO_BUYER' && status != 'PENDING_SHIPMENT_TO_SELLER'}>
						<span className="text">Print shipping label</span>
					</a>

					<div className="form-group form-group-sidebar">
						<button data-cy="modal-close-btn" type="button" disabled={status !== 'SENT_ON_VERIFICATION'} className="btn btn-primary-link" onClick={() => onVerify(verificationDetails.verificationId)}>
							<span className="text">Verify</span>
						</button>
						<button data-cy="modal-close-btn" type="button" disabled={status !== 'SENT_ON_VERIFICATION'} className="btn btn-primary-outline" onClick={() => onCancel(verificationDetails.verificationId)}>
							<span className="text">Cancel</span>
						</button>
					</div>

					<button onClick={onClose} data-cy="modal-close-btn" type="button" className="ant-btn btn btn-primary-link btn-block btn-back">
						<span className="text">Go Back</span>
					</button>
				</div>

				<div className="form-group form-group-right">
					<button data-cy="modal-close-btn" type="button" disabled={status != 'PENDING_ADMIN_APPROVAL'} className="btn btn-primary-link" onClick={() => onApproveReject(_id, 'approve')}>
						<span className="text">Approve Transaction</span>
					</button>
					<button data-cy="modal-close-btn" type="button" disabled={status != 'PENDING_ADMIN_APPROVAL'} className="btn btn-primary-link" onClick={() => onApproveReject(_id, 'reject')}>
						<span className="text">Reject Transaction</span>
					</button>
				</div>
			</div>
			<div className="view-order__details">
				<div className="view-order__details-left">
					{/* Order Summary Section */}
					<div className="order-info order-summary">
						<h5>Order Summary</h5>
						<div className="form-group">
							<label className="label mb-1 d-block">
								<b>Status:</b>
							</label>
							<p className="value viewValue">{status}</p>
						</div>
						<div className="form-group">
							<label className="label mb-1 d-block">
								<b>Total Price:</b>
							</label>
							<p className="value viewValue">${totalPrice}</p>
						</div>
						<div className="form-group">
							<label className="label mb-1 d-block">
								<b>Shoe Price:</b>
							</label>
							<p className="value viewValue">${shoePrice}</p>
						</div>
						<div className="form-group">
							<label className="label mb-1 d-block">
								<b>Created At:</b>
							</label>
							<p className="value viewValue">{new Date(createdAt).toLocaleString()}</p>
						</div>
						{confirmationExpiredAt ? (
							<div className="form-group">
								<label className="label mb-1 d-block">
									<b>Confirmation Expired At:</b>
								</label>
								<p className="value viewValue">{new Date(confirmationExpiredAt).toLocaleString()}</p>
							</div>
						) : null}

						{verificationExpiredAt ? (
							<div className="form-group">
								<label className="label mb-1 d-block">
									<b>Verification Expired At:</b>
								</label>
								<p className="value viewValue">{new Date(verificationExpiredAt).toLocaleString()}</p>
							</div>
						) : null}
					</div>

					{/* Buyer Details Section */}
					<div className="order-info buyer-details">
						<h5>Buyer Details</h5>
						<div className="form-group">
							<label className="label mb-1 d-block">
								<b>Name:</b>
							</label>
							<p className="value viewValue">{`${buyerFirstName} ${buyerLastName}`}</p>
						</div>
						<div className="form-group">
							<label className="label mb-1 d-block">
								<b>Transaction Fee:</b>
							</label>
							<p className="value viewValue">${transactionFee}</p>
						</div>
						<div className="form-group">
							<label className="label mb-1 d-block">
								<b>Shipping Price:</b>
							</label>
							<p className="value viewValue"> ${shippingPrice}</p>
						</div>
					</div>

					{/* Seller Details Section */}
					<div className="order-info seller-details">
						<h5>Seller Details</h5>
						<div className="form-group">
							<label className="label mb-1 d-block">
								<b>Name:</b>
							</label>
							<p className="value viewValue">{`${sellerFirstName} ${sellerLastName}`}</p>
						</div>
						<div className="form-group">
							<label className="label mb-1 d-block">
								<b>Payment Proc Fee:</b>
							</label>
							<p className="value viewValue">{paymentProcFee}</p>
						</div>
						<div className="form-group">
							<label className="label mb-1 d-block">
								<b>Total Seller Payout:</b>
							</label>
							<p className="value viewValue">{totalSellerPayout?.toFixed(2)}</p>
						</div>
					</div>

					{/* Shoe Details Section */}
					<div className="order-info shoe-details">
						<h5>Shoe Details</h5>
						<div className="form-group">
							<label className="label mb-1 d-block">
								<b>Name:</b>
							</label>
							<p className="value viewValue">{name}</p>
						</div>
						<div className="form-group">
							<label className="label mb-1 d-block">
								<b>Colorway:</b>
							</label>
							<p className="value viewValue">{colorway}</p>
						</div>
						<div className="form-group">
							<label className="label mb-1 d-block">
								<b>SKU:</b>
							</label>
							<p className="value viewValue">{sku}</p>
						</div>
						<div className="form-group">
							<label className="label mb-1 d-block">
								<b>Shoe size:</b>
							</label>
							<p className="value viewValue">{size}</p>
						</div>
					</div>
				</div>
				<div className="view-order__details-right">
					{/* Verification Details Section */}
					{verificationDetails && Object.keys(verificationDetails).length > 0 && (
						<div className="order-info verification-details">
							<h5>Verification Details</h5>
							{/* <div className="form-group">
							<label className="label mb-1 d-block">
								<b>Verified:</b>
							</label>
							<p className="value viewValue">{verificationDetails.verified ? 'Yes' : 'No'}</p>
						</div> */}
							<div className="form-groups">
								<div className="form-group">
									<label className="label mb-1 d-block">
										<b>Right Tag Code:</b>
									</label>
									<p className="value viewValue">{verificationDetails.rightTagCode}</p>
								</div>
								<div className="form-group">
									<label className="label mb-1 d-block">
										<b>Left Tag Code:</b>
									</label>
									<p className="value viewValue">{verificationDetails.leftTagCode}</p>
								</div>
								<div className="form-group">
									<label className="label mb-1 d-block">
										<b>Comment:</b>
									</label>
									<p className="value viewValue">{verificationDetails.comment}</p>
								</div>
							</div>

							{/* Display images for verification details */}
							<div className="form-group gallery">
								{Object.keys(verificationDetails).map(
									(key) =>
										!['verified', 'rightTagCode', 'leftTagCode', 'comment', 'uploadedBy', 'verificationId'].includes(key) && (
											<div className="form-group" key={key}>
												<label className="label mb-1 d-block">
													<p>{key.replace(/([A-Z])/g, ' $1')}:</p>
												</label>
												{typeof verificationDetails[key] === 'string' && verificationDetails[key].startsWith('http') ? <img src={verificationDetails[key]} alt={key} width="260" className="verification-image" /> : <p className="value viewValue">{verificationDetails[key]}</p>}
											</div>
										)
								)}
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default ViewOrder;
