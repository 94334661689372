import React, { useRef } from 'react';
import Loading from '../../../../components/loading/Loading';
import { jwtDecode } from 'jwt-decode';
import { useEffect, useState, useCallback } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../context/useAuth';
import { PERMISSIONS } from '../../../../utils/permissions';
import { AXIOS_API_CALL } from '../../../../utils/endpoint';
import axios from 'axios';
import { SERVER_URL } from '../../../../config/index';
import { notification } from 'antd';
import { EditSellerAddress } from '../edit-seller-address/EditSellerAddress';
import { EditBuyerAddress } from '../edit-buyer-address/EditBuyerAddress';
import OrderStatusDropdown from '../../../../components/orderStatusDropdown/OrderStatusDropdown';

const ViewOrder = () => {
	const { orderId } = useParams();
	const { user } = useAuth();
	const location = useLocation();
	const [groceryPermissions, setGroceryPermissions] = useState([]);
	const [orderDetails, setOrderDetails] = useState(null); // State to store product data
	const [readData, setReadData] = useState([]);
	const [readDataRefetch, setReadDataRefetch] = useState(false);
	const [readDataLoading, setReadDataLoading] = useState(true);
	const navigate = useNavigate();

	useEffect(() => {
		const { token } = user || {};
		if (token) {
			const decodedToken = jwtDecode(token);
			const permissions = decodedToken?.roleData?.permissions;

			if (permissions && location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`)) {
				if (Object.keys(permissions).some((permission) => permission.includes('grocery'))) {
					setGroceryPermissions(permissions.grocery || []);
				}
			}
		}
	}, [user, location]);

	const getReadData = async () => {
		const { token } = user;
		try {
			const response = await axios.get(`${SERVER_URL}/${AXIOS_API_CALL.getOrderDetails}/${orderId}`, {
				withCredentials: false,
				headers: { Authorization: `Bearer ${token}` },
			});
			setOrderDetails(response.data);
			setBuyerAddress(response.data.buyerDetails.shippingAddress);
			setSellerAddress(response.data.sellerDetails.shippingAddress);
		} catch (err) {
			console.error('Error fetching data:', err);
		} finally {
			setReadDataLoading(false);
		}
	};

	const dataFetchedRef = useRef(false);

	useEffect(() => {
		if (!dataFetchedRef.current) {
			const fetchData = async () => {
				setReadDataLoading(true);
				await getReadData();
				setReadDataLoading(false);
			};

			fetchData();
			dataFetchedRef.current = true; // Set to true after fetching
		}
	}, [readDataRefetch]);

	const handleCheckboxToggle = (key) => {
		console.log(key);
		const validKey = `is${key.charAt(0).toUpperCase() + key.slice(1)}Valid`;
		// Assuming orderDetails?.verificationDetails is an object with boolean values
		setOrderDetails((prevDetails) => ({
			...prevDetails,
			verificationDetails: {
				...prevDetails.verificationDetails,
				[validKey]: !prevDetails.verificationDetails[validKey], // Toggle the specific checkbox
			},
		}));

		console.log(orderDetails);
	};

	const getFalseVerificationDetails = () => {
		if (orderDetails?.verificationDetails) {
			return Object.keys(orderDetails.verificationDetails).filter((key) => orderDetails.verificationDetails[key] === false);
		}
		return [];
	};

	const [toggleUpdateSeller, setToggleUpdateSeller] = useState(false);
	const [toggleUpdateBuyer, setToggleUpdateBuyer] = useState(false);

	const [buyerAddress, setBuyerAddress] = useState([]);
	const [sellerAddress, setSellerAddress] = useState([]);

	const [loadingAddress, setLoadingAddress] = useState(false);

	const updateModalFormRef = useRef(null);
	const [refetch, setRefetch] = useState(false);

	const handleToggle = (props) => {
		const { name, state, data } = props;
		console.log(props);
		switch (name) {
			case 'updateBuyer':
				setToggleUpdateSeller(false);
				setToggleUpdateBuyer(true);
				setBuyerAddress(state.data);
				break;
			case 'updateSeller':
				setToggleUpdateBuyer(false);
				setToggleUpdateSeller(true);
				setSellerAddress(state.data);
				break;
			default:
				console.error('handleToggle ---- error');
		}
	};

	const updateBuyerFormFields = [
		{
			name: 'phone',
			value: buyerAddress?.phone || '', // Use optional chaining
		},
		{
			name: 'state',
			value: buyerAddress?.state || '',
		},
		{
			name: 'address',
			value: buyerAddress?.address || '',
		},
		{
			name: 'address2',
			value: buyerAddress?.address2 || '',
		},
		{
			name: 'city',
			value: buyerAddress?.city || '',
		},
		{
			name: 'zip_code',
			value: buyerAddress?.zip_code || '',
		},
		{
			name: 'isDefault',
			value: buyerAddress?.isDefault || false,
		},
		{
			name: 'firstName',
			value: buyerAddress?.firstName || '', // Use optional chaining
		},
		{
			name: 'lastName',
			value: buyerAddress?.lastName || '', // Use optional chaining
		},
		{
			name: 'id',
			value: buyerAddress?._id || false,
		},
	];

	const updateSellerFormFields = [
		{
			name: 'address',
			value: sellerAddress?.address || '',
		},
		{
			name: 'address2',
			value: sellerAddress?.address2 || '',
		},
		{
			name: 'zip_code',
			value: sellerAddress?.zip_code || '',
		},
		{
			name: 'state',
			value: sellerAddress?.state || '',
		},
		{
			name: 'city',
			value: sellerAddress?.city || '',
		},
		{
			name: 'isDefault',
			value: sellerAddress?.isDefault || false,
		},
		{
			name: 'firstName',
			value: sellerAddress?.firstName || '', // Use optional chaining
		},
		{
			name: 'lastName',
			value: sellerAddress?.lastName || '', // Use optional chaining
		},
		{
			name: 'phone',
			value: sellerAddress?.phone || '', // Use optional chaining
		},
		{
			name: 'id',
			value: sellerAddress?._id || false,
		},
	];

	const handleOnFinish = async (props) => {
		const { name, data } = props;

		const { token } = user;
		console.log(data);
		switch (name) {
			case 'update':
				try {
					const addressId = data?.id;
					let updateData = [];

					// Date to backend --
					updateData = updateData = {
						firstName: data?.firstName || '',
						lastName: data?.lastName || '',
						userFullName: data?.firstName + ' ' + data?.lastName || '',
						phone: data?.phone || '',
						address: data?.address || '',
						address2: data?.address2 || '',
						zip_code: data?.zip_code || '',
						state: data?.state || '',
						city: data?.city || '',
						isDefault: data?.isDefault || false,
					};
					const employeeUpdateResponse = await axios.put(
						`${SERVER_URL}/${AXIOS_API_CALL.updateAddress}/${addressId}`,
						{ ...updateData },
						{
							withCredentials: false,
							headers: { Authorization: `Bearer ${token}` },
						}
					);

					setRefetch((prevState) => !prevState);
					setToggleUpdateBuyer(false);
					setToggleUpdateSeller(false);
					notification.success({
						message: 'Address is updated',
						placement: 'bottomLeft',
					});
					setTimeout(() => {
						updateModalFormRef.current.resetFields();
						window.location.reload();
					}, 750);
				} catch (err) {
					console.error(err);
					notification.error({
						message: 'Address is not updated',
						placement: 'bottomLeft',
					});
				}
				break;

			default:
				console.error('handleOnFinish ---- error');
				notification.success({
					message: 'Address is updated',
					placement: 'bottomLeft',
				});
		}
	};

	const buyerProps = {
		active: toggleUpdateBuyer,
		onToggle: setToggleUpdateBuyer,
		formRef: updateModalFormRef,
		data: buyerAddress,
		onData: setBuyerAddress,
	};

	const sellerProps = {
		active: toggleUpdateSeller,
		onToggle: setToggleUpdateSeller,
		formRef: updateModalFormRef,
		data: sellerAddress,
		onData: setSellerAddress,
	};

	const addressSellerProps = {
		sellerProps,
		updateModalFormRef,
		handleOnFinish,
		updateSellerFormFields,
		loadingAddress,
		setToggleUpdateSeller,
	};

	const addressBuyerProps = {
		buyerProps,
		updateModalFormRef,
		handleOnFinish,
		updateBuyerFormFields,
		loadingAddress,
		setToggleUpdateBuyer,
	};

	const onVerify = useCallback(
		async (verificationId) => {
			const { token } = user || {};
			if (!token) {
				notification.error({ message: 'Authorization token not found. Please log in again.' });
				return;
			}

			try {
				const res = await axios.put(
					`${SERVER_URL}/${AXIOS_API_CALL.verifyOrder}/${verificationId}`,
					{},
					{
						headers: { Authorization: `Bearer ${token}` },
					}
				);

				if (res.status === 200) {
					notification.success({ message: 'Order verified successfully!' });
					setTimeout(() => {
						window.location.reload();
					}, 500);
				}
			} catch (err) {
				console.error('Error verifying order:', err);
				if (err.response.status === 404) {
					notification.error({ message: err.response.data.error });
				} else if (err.response.status === 403) {
					notification.info({ message: err.response.data.message });
				} else if (err.response.status === 409) {
					notification.error({ message: err.response.data.error || 'Request Timeout' });
				} else {
					notification.error({ message: 'Failed to verify order.' });
				}
			}
		},
		[user]
	);

	const onCancelOrder = useCallback(
		async (_id) => {
			const { token } = user || {};
			if (!token) {
				notification.error({ message: 'Authorization token not found. Please log in again.' });
				return;
			}

			try {
				const res = await axios.put(
					`${SERVER_URL}/${AXIOS_API_CALL.cancelOrder}/${_id}`,
					{},
					{
						headers: { Authorization: `Bearer ${token}` },
					}
				);

				if (res.status === 200) {
					notification.success({ message: 'Order canceled successfully!' });
					setTimeout(() => {
						window.location.reload();
					}, 500);
				}
			} catch (err) {
				console.error('Error verifying order:', err);
				if (err.response.status === 404) {
					notification.error({ message: err.response.data.error });
				} else if (err.response.status === 403) {
					notification.info({ message: err.response.data.message });
				} else if (err.response.status === 409) {
					notification.error({ message: err.response.data.error || 'Request Timeout' });
				} else {
					notification.error({ message: 'Failed to verify order.' });
				}
			}
		},
		[user]
	);

	const onAuthenticate = useCallback(
		async (_id) => {
			const { token } = user || {};
			if (!token) {
				notification.error({ message: 'Authorization token not found. Please log in again.' });
				return;
			}

			try {
				const res = await axios.put(
					`${SERVER_URL}/${AXIOS_API_CALL.authenticateOrder}/${_id}`,
					{},
					{
						headers: { Authorization: `Bearer ${token}` },
					}
				);

				if (res.status === 200) {
					notification.success({ message: 'Order authenticated successfully!' });
					setTimeout(() => {
						window.location.reload();
					}, 500);
				}
			} catch (err) {
				console.error('Error verifying order:', err);
				if (err.response.status === 404) {
					notification.error({ message: err.response.data.error });
				} else if (err.response.status === 403) {
					notification.info({ message: err.response.data.message });
				} else if (err.response.status === 409) {
					notification.error({ message: err.response.data.error || 'Request Timeout' });
				} else {
					notification.error({ message: 'Failed to verify order.' });
				}
			}
		},
		[user]
	);

	const onApproveReject = useCallback(
		async (_id, status) => {
			const { token } = user || {};
			if (!token) {
				notification.error({ message: 'Authorization token not found. Please log in again.' });
				return;
			}

			try {
				const res = await axios.put(
					`${SERVER_URL}/${AXIOS_API_CALL.approveRejectTransaction}/${_id}`,
					{
						status: status,
					},
					{
						headers: { Authorization: `Bearer ${token}` },
					}
				);

				if (res.status === 200) {
					notification.success({ message: 'Order authenticated successfully!' });
					setTimeout(() => {
						window.location.reload();
					}, 500);
				}
			} catch (err) {
				console.error('Error verifying order:', err);
				if (err.response.status === 404) {
					notification.error({ message: err.response.data.error });
				} else if (err.response.status === 403) {
					notification.info({ message: err.response.data.message || err.response.data.error });
				} else if (err.response.status === 409) {
					notification.error({ message: err.response.data.error || 'Request Timeout' });
				} else {
					notification.error({ message: 'Failed to verify order.' });
				}
			}
		},
		[user]
	);

	const onCancel = useCallback(
		async (verificationId, images) => {
			const { token } = user || {};
			if (!token) {
				notification.error({ message: 'Authorization token not found. Please log in again.' });
				return;
			}
			const imagesForDelete = getFalseVerificationDetails(); // Get the current state
			console.log('Images for delete:', imagesForDelete);

			try {
				const res = await axios.put(
					`${SERVER_URL}/${AXIOS_API_CALL.rejectVerification}/${verificationId}`,
					{ images },
					{
						headers: { Authorization: `Bearer ${token}` },
					}
				);

				if (res.status === 200) {
					notification.success({ message: 'Order cancelled!' });
					setTimeout(() => {
						window.location.reload();
					}, 500);
				}
			} catch (err) {
				console.error('Error canceling order:', err);
				if (err.response.status === 403) {
					notification.info({ message: err.response.data.message });
				} else if (err.response.status === 409) {
					notification.error({ message: err.response.data.error || 'Request Timeout' });
				} else {
					notification.error({ message: 'Failed to cancel order.' });
				}
			}
		},
		[user]
	);

	const onReject = useCallback(
		async (_id) => {
			const { token } = user || {};
			if (!token) {
				notification.error({ message: 'Authorization token not found. Please log in again.' });
				return;
			}

			try {
				const res = await axios.put(
					`${SERVER_URL}/${AXIOS_API_CALL.rejectOrder}/${_id}`,
					{},
					{
						headers: { Authorization: `Bearer ${token}` },
					}
				);

				if (res.status === 200) {
					notification.success({ message: 'Order rejcted!' });
					setTimeout(() => {
						window.location.reload();
					}, 500);
				}
			} catch (err) {
				console.error('Error canceling order:', err);
				if (err.response.status === 403) {
					notification.info({ message: err.response.data.message });
				} else if (err.response.status === 409) {
					notification.error({ message: err.response.data.error || 'Request Timeout' });
				} else {
					notification.error({ message: 'Failed to cancel order.' });
				}
			}
		},
		[user]
	);

	const onClose = useCallback(() => {
		navigate(-1);
	}, [navigate, location]);

	const handleStatusChange = async (status) => {
		const { token } = user || {};
		if (!token) {
			notification.error({ message: 'Authorization token not found. Please log in again.' });
			return;
		}

		try {
			const res = await axios.put(
				`${SERVER_URL}/dashboard/orders/${AXIOS_API_CALL.updateOrder}/${_id}`,
				{
					status: status,
				},
				{
					headers: { Authorization: `Bearer ${token}` },
				}
			);

			if (res.status === 200) {
				notification.success({ message: 'Status changed!' });
				setTimeout(() => {
					window.location.reload();
				}, 500);
			}
		} catch (err) {
			console.error('Error changing order status:', err);
			notification.info({ message: err?.response?.data?.message || 'Error changing order status' });
		}
	};

	return (
		<div className="view-order">
			<h4 className="product-name">Order #{orderDetails?.orderNumber}</h4>
			<div className="view-order__footer">
				<div className={`form-group form-group-left `}>
					<button onClick={() => onCancelOrder(orderDetails?._id)} data-cy="modal-close-btn" disabled={orderDetails?.status !== 'PENDING_ADMIN_APPROVAL' && orderDetails?.status !== 'PENDING_CONFIRMATION' && orderDetails?.status !== 'PENDING_VERIFICATION' && orderDetails?.status !== 'DELIVERED_FOR_AUTHENTICATION' && orderDetails?.status !== 'SENT_ON_VERIFICATION' && orderDetails?.status !== 'SENT_ON_VERIFICATION'} type="button" className="ant-btn btn btn-primary-link btn-block btn-cancel-order">
						<span className="text">Cancel Order</span>
					</button>
					<OrderStatusDropdown orderStatus={orderDetails?.status || null} handleStatusChange={handleStatusChange} />

					<div className="form-group form-group-sidebar">
						<button data-cy="modal-close-btn" type="button" disabled={orderDetails?.status !== 'DELIVERED_FOR_AUTHENTICATION'} className="btn btn-primary-link" onClick={() => onAuthenticate(orderDetails?._id)}>
							<span className="text">Authenticate</span>
						</button>
						<button data-cy="modal-close-btn" type="button" disabled={orderDetails?.status !== 'DELIVERED_FOR_AUTHENTICATION'} className="btn btn-primary-outline" onClick={() => onReject(orderDetails?._id)}>
							<span className="text">Reject</span>
						</button>
					</div>

					<a href={orderDetails?.label_url} target="_blank" rel="noopener noreferrer" className="ant-btn btn btn-primary-outline pt-1" disabled={orderDetails?.status != 'PENDING_SHIPMENT_TO_ADMIN' && orderDetails?.status != 'PENDING_SHIPMENT_TO_BUYER' && orderDetails?.status != 'PENDING_SHIPMENT_TO_SELLER'}>
						<span className="text">Print shipping label</span>
					</a>

					<div className="form-group form-group-sidebar">
						<button data-cy="modal-close-btn" type="button" disabled={orderDetails?.status !== 'SENT_ON_VERIFICATION'} className="btn btn-primary-link" onClick={() => onVerify(orderDetails?.verificationDetails.verificationId)}>
							<span className="text">Verify</span>
						</button>
						<button data-cy="modal-close-btn" type="button" disabled={orderDetails?.status !== 'SENT_ON_VERIFICATION'} className="btn btn-primary-outline" onClick={() => onCancel(orderDetails?.verificationDetails.verificationId, getFalseVerificationDetails())}>
							<span className="text">Cancel</span>
						</button>
					</div>

					<button onClick={onClose} data-cy="modal-close-btn" type="button" className="ant-btn btn btn-primary-link btn-block btn-back">
						<span className="text">Go Back</span>
					</button>
				</div>

				<div className="form-group form-group-right">
					<button data-cy="modal-close-btn" type="button" disabled={orderDetails?.status != 'PENDING_ADMIN_APPROVAL'} className="btn btn-primary-link" onClick={() => onApproveReject(orderDetails?._id, 'approve')}>
						<span className="text">Approve Transaction</span>
					</button>
					<button data-cy="modal-close-btn" type="button" disabled={orderDetails?.status != 'PENDING_ADMIN_APPROVAL'} className="btn btn-primary-link" onClick={() => onApproveReject(orderDetails?._id, 'reject')}>
						<span className="text">Reject Transaction</span>
					</button>
				</div>
			</div>
			<div className="second-row-btns ">
				<button data-cy="modal-close-btn" type="button" disabled className="ant-btn btn btn-primary-link btn-block btn-back">
					<span className="text">Gel Seller Invoice</span>
				</button>
				<button data-cy="modal-close-btn" type="button" disabled className="ant-btn btn btn-primary-link btn-block btn-back">
					<span className="text">Get Buyer Invoice</span>
				</button>
				<button data-cy="modal-close-btn" type="button" disabled className="ant-btn btn btn-primary-link btn-block btn-back">
					<span className="text">Seller Sale Confirmation</span>
				</button>
				<button data-cy="modal-close-btn" type="button" disabled className="ant-btn btn btn-primary-link btn-block btn-back">
					<span className="text">Item Shipped to SoleSolution</span>
				</button>
			</div>
			<div className="view-order__details">
				<div className="view-order__details-left">
					{/* Order Summary Section */}
					<div className="order-info order-summary">
						<div className="form-group-wrapper">
							<h5>Order Summary</h5>
							<div className="form-group">
								<label className="label mb-1 d-block">
									<b>Status:</b>
								</label>
								<p className="value viewValue">{orderDetails?.status}</p>
							</div>
							<div className="form-group">
								<label className="label mb-1 d-block">
									<b>Total Price:</b>
								</label>
								<p className="value viewValue">${orderDetails?.totalPrice}</p>
							</div>
							<div className="form-group">
								<label className="label mb-1 d-block">
									<b>Shoe Price:</b>
								</label>
								<p className="value viewValue">${orderDetails?.shoePrice}</p>
							</div>
							<div className="form-group">
								<label className="label mb-1 d-block">
									<b>Created At:</b>
								</label>
								<p className="value viewValue">{new Date(orderDetails?.createdAt).toLocaleString()}</p>
							</div>
							<div className="form-group">
								<label className="label mb-1 d-block">
									<b>Tracking Number:</b>
								</label>
								<p className="value viewValue">
									{orderDetails?.tracking_number ? (
										<a href={orderDetails?.tracking_url} target="_blank">
											{orderDetails?.tracking_number}
										</a>
									) : (
										'Not available'
									)}
								</p>
							</div>
							{orderDetails?.confirmationExpiredAt ? (
								<div className="form-group">
									<label className="label mb-1 d-block">
										<b>Confirmation Expired At:</b>
									</label>
									<p className="value viewValue">{new Date(orderDetails?.confirmationExpiredAt).toLocaleString()}</p>
								</div>
							) : null}

							{orderDetails?.verificationExpiredAt ? (
								<div className="form-group">
									<label className="label mb-1 d-block">
										<b>Verification Expired At:</b>
									</label>
									<p className="value viewValue">{new Date(orderDetails?.verificationExpiredAt).toLocaleString()}</p>
								</div>
							) : null}
						</div>
					</div>

					{/* Buyer Details Section */}
					<div className="order-info buyer-details">
						<div className="form-group-wrapper">
							<h5>Buyer Details</h5>
							<div className="form-group">
								<label className="label mb-1 d-block">
									<b>Name:</b>
								</label>
								<p className="value viewValue">{`${orderDetails?.buyerDetails?.firstName} ${orderDetails?.buyerDetails?.lastName}`}</p>
							</div>
							<div className="form-group">
								<label className="label mb-1 d-block">
									<b>TAX:</b>
								</label>
								<p className="value viewValue">${orderDetails?.buyerDetails?.transactionFee}</p>
							</div>
							<div className="form-group">
								<label className="label mb-1 d-block">
									<b>Shipping Price:</b>
								</label>
								<p className="value viewValue"> ${orderDetails?.buyerDetails?.shippingPrice}</p>
							</div>
							<div className="form-group">
								<label className="label mb-1 d-block">
									<b>Verification Fee:</b>
								</label>
								<p className="value viewValue"> ${orderDetails?.buyerDetails?.verificationFee}</p>
							</div>
							<div className={`form-group ${!orderDetails?.buyerDetails?.shippingAddress.isValid ? 'invalid-address' : ''}`}>
								<label className="label mb-1 d-block">
									<b>Shipping Address:</b>
								</label>
								<p className="value viewValue">{orderDetails?.buyerDetails?.shippingAddress?.address && orderDetails?.buyerDetails?.shippingAddress?.city && orderDetails?.buyerDetails?.shippingAddress?.state && orderDetails?.buyerDetails?.shippingAddress?.zip_code ? `${orderDetails?.buyerDetails?.shippingAddress?.address}, ${orderDetails?.buyerDetails?.shippingAddress?.address2 ? orderDetails?.buyerDetails?.shippingAddress?.address2 + ', ' : ''}${orderDetails?.buyerDetails?.shippingAddress?.city}, ${orderDetails?.buyerDetails?.shippingAddress?.state} ${orderDetails?.buyerDetails?.shippingAddress?.zip_code}` : 'No address'}</p>
							</div>
						</div>

						<button onClick={() => handleToggle({ name: 'updateBuyer', state: buyerProps })} data-cy="modal-close-btn" type="button" className="ant-btn btn btn-primary-link btn-block btn-cancel-order">
							<span className="text">Edit address</span>
						</button>
					</div>

					{/* Seller Details Section */}
					<div className="order-info seller-details">
						<div className="form-group-wrapper">
							<h5>Seller Details</h5>
							<div className="form-group">
								<label className="label mb-1 d-block">
									<b>Name:</b>
								</label>
								<p className="value viewValue">{`${orderDetails?.sellerDetails?.firstName} ${orderDetails?.sellerDetails?.lastName}`}</p>
							</div>
							<div className="form-group">
								<label className="label mb-1 d-block">
									<b>Payment Proc Fee:</b>
								</label>
								<p className="value viewValue">{orderDetails?.sellerDetails?.paymentProcFee}</p>
							</div>
							<div className="form-group">
								<label className="label mb-1 d-block">
									<b>Total Seller Payout:</b>
								</label>
								<p className="value viewValue">{orderDetails?.sellerDetails?.totalSellerPayout?.toFixed(2)}</p>
							</div>
							<div className={`form-group ${!orderDetails?.sellerDetails?.shippingAddress.isValid ? 'invalid-address' : ''}`}>
								<label className="label mb-1 d-block">
									<b>Shipping Address:</b>
								</label>
								<p className="value viewValue">{orderDetails?.sellerDetails.shippingAddress?.address && orderDetails?.sellerDetails.shippingAddress?.city && orderDetails?.sellerDetails.shippingAddress?.state && orderDetails?.sellerDetails.shippingAddress?.zip_code ? `${orderDetails?.sellerDetails.shippingAddress.address}, ${orderDetails?.sellerDetails.shippingAddress.address2 ? orderDetails?.sellerDetails.shippingAddress.address2 + ', ' : ''}${orderDetails?.sellerDetails.shippingAddress.city}, ${orderDetails?.sellerDetails.shippingAddress.state} ${orderDetails?.sellerDetails.shippingAddress.zip_code}` : 'No address'}</p>
							</div>
						</div>

						<button onClick={() => handleToggle({ name: 'updateSeller', state: sellerProps })} data-cy="modal-close-btn" type="button" className="ant-btn btn btn-primary-link btn-block btn-cancel-order">
							<span className="text">Edit address</span>
						</button>
					</div>

					{/* Shoe Details Section */}
					<div className="order-info shoe-details">
						<div className="form-group-wrapper">
							<h5>Shoe Details</h5>
							<div className="form-group">
								<label className="label mb-1 d-block">
									<b>Name:</b>
								</label>
								<p className="value viewValue">{orderDetails?.shoeDetails?.name}</p>
							</div>
							<div className="form-group">
								<label className="label mb-1 d-block">
									<b>Colorway:</b>
								</label>
								<p className="value viewValue">{orderDetails?.shoeDetails?.colorway}</p>
							</div>
							<div className="form-group">
								<label className="label mb-1 d-block">
									<b>SKU:</b>
								</label>
								<p className="value viewValue">{orderDetails?.shoeDetails?.sku}</p>
							</div>
							<div className="form-group">
								<label className="label mb-1 d-block">
									<b>Shoe size:</b>
								</label>
								<p className="value viewValue">{orderDetails?.shoeDetails?.size}</p>
							</div>
						</div>
					</div>
				</div>
				<div className="view-order__details-right">
					{/* Verification Details Section */}
					{orderDetails?.verificationDetails && Object.keys(orderDetails?.verificationDetails).length > 0 && (
						<div className="order-info verification-details">
							<h5>Verification Details</h5>
							{/* <div className="form-group">
							<label className="label mb-1 d-block">
								<b>Verified:</b>
							</label>
							<p className="value viewValue">{verificationDetails.verified ? 'Yes' : 'No'}</p>
						</div> */}
							<div className="form-groups">
								<div className="form-group">
									<label className="label mb-1 d-block">
										<b>Right Tag Code:</b>
									</label>
									<p className="value viewValue">{orderDetails?.verificationDetails.rightTagCode}</p>
								</div>
								<div className="form-group">
									<label className="label mb-1 d-block">
										<b>Left Tag Code:</b>
									</label>
									<p className="value viewValue">{orderDetails?.verificationDetails.leftTagCode}</p>
								</div>
								<div className="form-group">
									<label className="label mb-1 d-block">
										<b>Comment:</b>
									</label>
									<p className="value viewValue">{orderDetails?.verificationDetails.comment}</p>
								</div>
							</div>

							{/* Display images for verification details */}
							<div className="form-group gallery">
								{Object.keys(orderDetails?.verificationDetails).map((key) => {
									// Check if the key does not include 'Valid' and is not in the excluded list
									if (!['verified', 'rightTagCode', 'leftTagCode', 'comment', 'uploadedBy', 'verificationId'].includes(key) && !key.toLowerCase().includes('valid')) {
										// Construct the corresponding valid key
										const validKey = `is${key.charAt(0).toUpperCase() + key.slice(1)}Valid`;
										const isValid = orderDetails?.verificationDetails[validKey]; // Get the value of the corresponding valid field

										return (
											<div className="form-group" key={key}>
												<label className="label mb-1 d-block">
													<p>{key.replace(/([A-Z])/g, ' $1')}:</p>
												</label>
												<div className="checkbox-image">
													<img src={orderDetails?.verificationDetails[key]} alt={key} width="260" className={`verification-image ${isValid ? 'checked' : ''}`} />
													<input className="checkbox" type="checkbox" checked={!isValid} onChange={() => handleCheckboxToggle(key)} />
													{/* Display the validity status */}
												</div>
											</div>
										);
									}
									return null; // Return null for excluded keys
								})}
							</div>
						</div>
					)}
					<EditBuyerAddress {...addressBuyerProps} />
					<EditSellerAddress {...addressSellerProps} />
				</div>
			</div>
		</div>
	);
};

export default ViewOrder;
