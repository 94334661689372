import React, { useEffect } from 'react';
import Modal from '../../../../components/modal/Modal';
import { Input, Button, Form, Row } from 'antd';
import { useForm } from 'antd/es/form/Form';

export const UpdateAskPrice = ({ editAskPriceProps, editAskPriceModalFormRef, handleOnFinish, editAskPriceFormFields, editAskPriceBtnLoader, setToggleEditAskPrice }) => {
  const [askEditPriceForm] = useForm();

  useEffect(() => {
    if (editAskPriceFormFields && editAskPriceFormFields[0]) {
      askEditPriceForm.setFieldsValue({
        regularPrice: editAskPriceFormFields[0]?.value?.regularPrice,
      });
    }
  }, [editAskPriceFormFields, askEditPriceForm]);

  return (
    <Modal {...editAskPriceProps} className="sidebar__modal--center" title="">
      <Form name="askEditPriceForm" ref={editAskPriceModalFormRef} form={askEditPriceForm} wrapperCol={{ span: 24 }} onFinish={(data) => handleOnFinish({ name: 'edit-ask-price', data: { ...data, _id: editAskPriceFormFields[0]?.value?._id } })}>
        <h2 className="text-center mb-1">Edit price for item: {editAskPriceFormFields[0]?.value?.name}</h2>

        <Form.Item name="regularPrice">
          <Input type="number" placeholder="Enter price" style={{ fontSize: '1rem' }} />
        </Form.Item>

        <Form.Item>
          <Row gutter={16} style={{ justifyContent: 'center', columnGap: '20px' }}>
            {!editAskPriceBtnLoader ? (
              <Button type="submit" htmlType="submit" className="btn btn-primary-link btn-block">
                <span>Confirm</span>
              </Button>
            ) : (
              <Button type="button" className="btn btn-primary-link btn-block">
                <span>Updating...</span>
              </Button>
            )}
            <Button type="primary" htmlType="button" className="btn btn-primary btn-block" onClick={() => setToggleEditAskPrice(false)}>
              <span>Cancel</span>
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};
