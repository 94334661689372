import React, { useEffect } from 'react';
import { Select, Input, Button, Form, Row, Col, Checkbox } from 'antd';
import SidebarModal from '../../../../components/sidebarModal/SidebarModal';
import { stateUS } from '../../../../helpers/state';
import Loading from '../../../../components/loading/Loading';

export const EditBuyerAddress = ({ buyerProps, updateModalFormRef, handleOnFinish, updateBuyerFormFields, loadingAddress, setToggleUpdateBuyer }) => {

	return (
		<SidebarModal {...buyerProps} onCancel={buyerProps.active} className="sidebar__modal--right" title="Edit Buyer Address">
			{!loadingAddress ? (
				<Form
					name="update"
					autoComplete="off"
					ref={updateModalFormRef}
					wrapperCol={{ span: 24 }}
					initialValues={{ remember: false }}
					onFinish={(data) => {
						handleOnFinish({ name: 'update', data: data });
					}}
					fields={updateBuyerFormFields}
				>
					<Form.Item name="address" label="Address:" rules={[{ required: false, message: 'Please input your address!' }]}>
						<Input placeholder="Address:" />
					</Form.Item>
					<Form.Item name="address2" label="Address:" rules={[{ required: false, message: 'Please input your address!' }]}>
						<Input placeholder="Address:" />
					</Form.Item>
					<Form.Item name="city" label="City:" rules={[{ required: false, message: 'Please input your city!' }]}>
						<Input placeholder="City:" />
					</Form.Item>
					<Form.Item name="state" label="Select State:" rules={[{ required: false, message: 'Please input your state!' }]}>
						<Select>
							<Option value="">Select State</Option>

							{stateUS.map((item) => {
								return (
									<Option key={item.name} value={item.abbreviation}>
										{item.name}
									</Option>
								);
							})}
						</Select>
					</Form.Item>
					<Form.Item
						name="zip_code"
						label="Zip Code:"
						rules={[
							{
								required: false,
								message: 'Please input your zip code!',
							},
						]}
					>
						<Input placeholder="Zip Code:" />
					</Form.Item>

					<Form.Item name="phone" label="Phone:" rules={[{ required: false, message: 'Please input your phone!' }]}>
						<Input placeholder="Phone:" />
					</Form.Item>

					<Form.Item name="id" label="ID:" hidden="true" rules={[{ required: false, message: 'Please input your phone!' }]}>
						<Input placeholder="Id:" />
					</Form.Item>

					<Form.Item name="firstName" label="ID:" hidden="true" rules={[{ required: false, message: 'Please input your phone!' }]}>
						<Input placeholder="Id:" />
					</Form.Item>
					<Form.Item name="lastName" label="ID:" hidden="true" rules={[{ required: false, message: 'Please input your phone!' }]}>
						<Input placeholder="Id:" />
					</Form.Item>

					<Form.Item className="ant-col-btn" wrapperCol={{ offset: 8 }}>
						<Row gutter={16}>
							<Col span={12}>
								<Button
									type="primary"
									htmlType="button"
									className="btn btn-primary-link btn-block"
									onClick={() => {
										setToggleUpdateBuyer(false);
									}}
								>
									<span>Cancel</span>
								</Button>
							</Col>
							<Col span={12}>
								<Button type="submit" htmlType="submit" className="btn btn-primary btn-block">
									<span>Save Address</span>
								</Button>
							</Col>
						</Row>
					</Form.Item>
				</Form>
			) : (
				<Loading />
			)}
		</SidebarModal>
	);
};
