import React, { useEffect, useState } from 'react';
import Message from '../../../components/message/Message';
import { contentMessage } from '../../../helpers/contentMessage';
import Pagination from '../../../components/pagination/Pagination';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DraggableRow from './DraggableRow';
import { AXIOS_API_CALL } from '../../../utils/endpoint';
import { SERVER_URL } from '../../../config/index';
import axios from 'axios';
import { useAuth } from '../../../context/useAuth';
import { jwtDecode } from 'jwt-decode';
import { PERMISSIONS } from '../../../utils/permissions';
import { notification } from 'antd';

const Table = (props) => {
	const { user } = useAuth();
	const { data: initialData, groceryPermissions, setGroceryPermissions, getGroceryPermissions, deleteActions, pagination, handleToggle } = props;
	const [data, setData] = useState(initialData);
	const { token } = user || {};

	useEffect(() => {
		const { token } = user || {};
		if (token) {
			const decodedToken = jwtDecode(token);
			const permissions = decodedToken?.roleData?.permissions;
			console.log(permissions);
			if (permissions && location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`)) {
				if (Object.keys(permissions).some((permission) => permission.includes('grocery'))) {
					setGroceryPermissions(permissions.grocery || []);
				}
			}
		}
	}, [user, location]);

	const saveOrder = async () => {
		const payload = {
			banners: data.map((item, index) => ({
				id: item._id, // Assuming _id is the identifier for the banner
				newIndex: index,
			})),
		};
		try {
			// Update the order on the server
			const res = await axios.put(`${SERVER_URL}/${AXIOS_API_CALL.imagesOrder}`, payload, {
				withCredentials: false,
				headers: { Authorization: `Bearer ${token}` }, // Use the token here
			});
			if (res.status === 200) {
				notification.success({ message: 'Banner images order updated successfully!' });
			}
		} catch (err) {
			console.error('Error updating order:', err);
			if (err.response && err.response.status === 409) {
				notification.error({ message: err.response.data.error || 'Request Timeout' });
			} else {
				notification.error({ message: 'Banner images order failed.' });
			}
		}
	};
  
  const moveRow = (fromIndex, toIndex) => {
		const updatedData = [...data];
		const [movedItem] = updatedData.splice(fromIndex, 1); // Remove the item from the original position
		updatedData.splice(toIndex, 0, movedItem); // Insert it at the new position
		setData(updatedData); // Update the state with the new order
	};
	useEffect(() => {
		console.log(data);
	}, [data]);
	return (
		<>
			<DndProvider backend={HTML5Backend}>
				<div className="d-flex justify-start p-2">
					<button className="btn btn-primary-link pl-2 pr-2" type="button" onClick={saveOrder}>
						Save order
					</button>
				</div>

				<table className="table">
					<thead className="thead">
						<tr>
							<th></th>
							<th>ID</th>
							<th>Title</th>
							<th>Kind</th>
							<th>Active</th>
							<th>Actions</th>
						</tr>
					</thead>
					<tbody className="tbody">
						{data && data.length > 0 ? (
							data.map((item, idx) => <DraggableRow key={item._id} item={item} index={idx} moveRow={moveRow} groceryPermissions={groceryPermissions} getGroceryPermissions={getGroceryPermissions} deleteActions={deleteActions} handleToggle={handleToggle} />)
						) : (
							<tr>
								<td colSpan={20} style={{ width: '100%' }}>
									<Message className="content-message">
										<h2 style={{ color: '#666' }}>{contentMessage.dataNotFound}</h2>
									</Message>
								</td>
							</tr>
						)}
					</tbody>
				</table>
			</DndProvider>

			{data && data.length > 0 && <Pagination {...pagination} />}
		</>
	);
};

export default Table;
