import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { useAuth } from '../../../context/useAuth';
import { SERVER_URL } from '../../../config/index';
import Loading from '../../../components/loading/Loading';
import LoadingPlaceholder from '../../../components/loadingPlaceholder/LoadingPlaceholder';
import GalleryUpload from './GalleryUpload';
import { Section, Main } from '../../../components/content';
import { PERMISSIONS } from '../../../utils/permissions';
import GalleryImages from './GalleryImages';
import { AXIOS_API_CALL } from '../../../utils/endpoint';
import { ProductProvider } from '../../../context/products';

const Gallery = () => {
	const { user } = useAuth();

	const location = useLocation();
	const [groceryPermissions, setGroceryPermissions] = useState([]);

	// GET USER TOKEN
	useEffect(() => {
		const { token } = user;
		if (user && token) {
			const decodeToken = jwtDecode(token);
			const permissions = decodeToken.roleData?.permissions;

			if (location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`)) {
				if (Object.keys(permissions).some((permission) => permission.includes(PERMISSIONS.grocery))) {
					setGroceryPermissions(permissions.grocery);
				}
			}
		}
	}, [user, location]);

	// SEARCH
	const [search, setSearch] = useState(null);
	const [searchForm, setSearchForm] = useState({ query: '' });
	const [refetch, setRefetch] = useState(false);

	const handleOnSubmitSearch = (e) => {
		e.preventDefault();
		setSearch(searchForm.query);
		setRefetch((prevState) => !prevState);
	};

	const handleOnChangeSearch = (value) => {
		setSearchForm({ query: value });
	};

	const handleOnClearSearch = () => {
		setSearch(null);
		setSearchForm({ query: '' });
		setRefetch((prevState) => !prevState);
	};

	const [toggle, setToggle] = useState(false);

	// READ
	const [readData, setReadData] = useState([]);
	const [readDataRefetch, setReadDataRefetch] = useState(false);
	const [readDataLoading, setReadDataLoading] = useState(true);

	const getReadData = useCallback(async () => {
		const { token } = user;

		try {
			const url = search === null ? `${AXIOS_API_CALL.galleryImages}` : `${AXIOS_API_CALL.galleryImagesSearch}` + search;
			const response = await axios
				.get(`${SERVER_URL}/${url}`, {
					withCredentials: false,
					headers: {
						department: PERMISSIONS.grocery,
						Authorization: `Bearer ${token}`,
					},
				})
				.then((res) => {
					setTimeout(() => {
						setReadDataLoading(false);
					}, 200);

					return res;
				})
				.catch((err) => {
					setReadDataLoading(false);
					console.error(err);
				});

			return response;
		} catch (err) {}
	}, [user, search]);

	useEffect(() => {
		let isMounted = true;

		new Promise((resolve, reject) => {
			setReadDataLoading(true);
			setTimeout(() => {
				resolve(getReadData());
			}, 700);
		}).then((res) => {
			if (isMounted) {
				setReadData(res?.data);
			}
		});

		return () => {
			isMounted = false;
		};
	}, [getReadData, readDataRefetch]);

	return (
		<ProductProvider>
			<h2 className="page-title">Gallery</h2>

			<Section className="section__wrapper section__gallery">
				<header className="section__header">
					<div className="search">
						{!readDataLoading ? (
							<div className="search">
								<form className="search-form" onSubmit={(e) => handleOnSubmitSearch(e)} data-cy="search-roles">
									<div className="form-group m-0">
										<span className="icon icon-search">
											<img src="/assets/icons/search.svg" alt="Search" title="Search" data-cy="search-input-field" />
										</span>
										<input value={searchForm.query || ''} type="text" className="input" placeholder="Search" onChange={(e) => handleOnChangeSearch(e.target.value)} />
										<span className={`icon icon-close ${!!searchForm.query && searchForm.query.length > 0 ? 'isActive' : ''}`} onClick={() => handleOnClearSearch()}>
											<img src="/assets/icons/times.svg" alt="Clear" title="Clear" />
										</span>
									</div>
									<button className="form-submit" data-cy="search-submit-btn" disabled={!searchForm.query || searchForm.query.length === 0}>
										Search
									</button>
								</form>
							</div>
						) : (
							<LoadingPlaceholder style={{ width: '100%', maxWidth: '850px', height: '50px' }} />
						)}
					</div>

					<div className="actions">
						{!readDataLoading ? (
							<button onClick={() => setToggle((t) => !t)} className="btn btn-primary pl-2 pr-2" type="button">
								<span className="text">Add New</span>
							</button>
						) : (
							<LoadingPlaceholder style={{ width: '104.5px', height: '50px' }} />
						)}
					</div>
				</header>

				<Main className="section__content relative min-h-table-content">
					{readDataLoading ? (
						<Loading />
					) : (
						<>
							{toggle ? (
								<>
									<GalleryUpload data={readData} setToggle={setToggle} setRefetch={setReadDataRefetch} />
								</>
							) : null}

							<GalleryImages data={readData} setRefetch={setReadDataRefetch} />
						</>
					)}
				</Main>
			</Section>
		</ProductProvider>
	);
};

export default Gallery;
