import React from 'react';
import Modal from '../../../../components/modal/Modal';

export const DeleteDocument = ({ deleteProps, onSubmit, setToggleDelete, deleteDocId }) => {
	return (
		<Modal {...deleteProps} className="sidebar__modal--center" title="">
			<form name="delete" onSubmit={(event) => onSubmit({ event: event, name: 'delete', data: deleteDocId })}>
				<h2 className="text-center mb-4">Are you sure you want to remove the selected document?</h2>

				<div className="form-group form-group-modal mb-4">
					<button data-cy="modal-delete-submit-btn" type="submit" className="btn btn-primary-link">
						<span className="text">Yes</span>
					</button>

					<button data-cy="modal-delete-cancel-btn" type="button" className="btn btn-primary" onClick={() => setToggleDelete(false)}>
						<span className="text">No</span>
					</button>
				</div>
			</form>
		</Modal>
	);
};
