import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../../context/useAuth';
import { SERVER_URL } from '../../../config/index';
import { Section, Main } from '../../../components/content';
import { AXIOS_API_CALL } from '../../../utils/endpoint';
import { PERMISSIONS } from '../../../utils/permissions';
import Loading from '../../../components/loading/Loading';
import Table from './Table';
import { getGroceryPermissions } from '../../../middleware';
import { notification } from 'antd';
import { SellerView } from './SellerView';
import { VerifyForm } from './verify-form/VerifyForm';
import { RejectForm } from './reject-form/RejectForm';
import SelectCustom from '../../../components/customSelect/CustomSelect';
import LoadingPlaceholder from '../../../components/loadingPlaceholder/LoadingPlaceholder';

const options = [5, 10, 20, 50, 100];

const SellerVerificationRequests = () => {
				const { user } = useAuth();
				const location = useLocation();
				const [groceryPermissions, setGroceryPermissions] = useState([]);

				// GET USER TOKEN
				useEffect(() => {
					const { token } = user || {};
					if (token) {
						const decodedToken = jwtDecode(token);
						const permissions = decodedToken?.roleData?.permissions;

						if (permissions && location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`)) {
							if (Object.keys(permissions).some((permission) => permission.includes('grocery'))) {
								setGroceryPermissions(permissions.grocery || []);
							}
						}
					}
				}, [user, location]);

				// READ
				const [readData, setReadData] = useState([]);
				const [readDataRefetch, setReadDataRefetch] = useState(false);
				const [readDataLoading, setReadDataLoading] = useState(true);

				// SEARCH
				const [search, setSearch] = useState(null);
				const [searchForm, setSearchForm] = useState({ query: '' });
				const [filterStatus, setFilterStatus] = useState(null);

				const [viewUserId, setViewUserId] = useState('');
				const [viewUser, setViewUser] = useState([]);
				const [verifyUser, setVerifyUser] = useState([]);
				const [rejectUser, setRejectUser] = useState([]);
				const [viewLoading, setViewLoading] = useState(false);

				const [toggleVerify, setToggleVerify] = useState(false);
				const [toggleReject, setToggleReject] = useState(false);
				const verifyModalFormRef = useRef(null);
				const rejectModalFormRef = useRef(null);
				const [verifyBtnLoader, setVerifyBtnLoader] = useState(false);
				const [verifyBlock, setVerifyeBlock] = useState(false);
				const [rejectBtnLoader, setRejectBtnLoader] = useState(false);
				const [rejectBlock, setRejecteBlock] = useState(false);

				const handleOnSubmitSearch = (e) => {
					e.preventDefault();
					setSearch(searchForm.query);
					setReadDataRefetch((prevState) => !prevState);
				};

				const handleOnChangeSearch = (value) => {
					setSearchForm({ query: value });
				};

				const handleOnClearSearch = () => {
					setSearch(null);
					setSearchForm({ query: '' });
					setReadDataRefetch((prevState) => !prevState);
				};

				// PAGE
				const [currentPage, setCurrentPage] = useState(1);
				const [limitPage, setLimitPage] = useState(10);

				// FILTER STATUS
				const handleStatusFilter = (value) => {
					setFilterStatus(value);
					setReadDataRefetch((prevState) => !prevState);
				};

				// FILTERS
				const [disabledButton, setDisabledButton] = useState(false);
				const handleDisabledButton = (status) => {
					setDisabledButton(status);
				};

				// Get selected rows id one or more
				const [checkedRow, setCheckedRow] = useState([]);
				const handleCheckedRows = (data) => {
					setCheckedRow(data);
				};

				const getReadData = useCallback(async () => {
					const { token } = user;

					try {
						const response = await axios
							.get(`${SERVER_URL}/${AXIOS_API_CALL.getVerifications}/${currentPage}/${limitPage}/${search || null}/${filterStatus || null}`, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } })
							.then((res) => {
								return res;
							})
							.catch((err) => console.error(err))
							.finally(
								setTimeout(() => {
									setReadDataLoading(false);
								}, 200)
							);
						return response;
					} catch (err) {
						console.error('Error fetching data:', err);
					}
				}, [user, limitPage, search, currentPage, filterStatus]);

				function handleToggle(props) {
					const { name, state, data } = props;

					switch (name) {
						case 'view':
							setViewUserId(data);
							setViewUser(data);
							state.onToggle((prevState) => !prevState);
							break;
						case 'verify':
							setVerifyUser(data);
							state.onToggle((prevState) => !prevState);
							break;

						case 'reject':
							setRejectUser(data);
							state.onToggle((prevState) => !prevState);
							break;

						default:
							console.warn('Default of: handleToggle function');
					}
				}

				useEffect(() => {
					let isMounted = true;
					setReadDataLoading(true);

					new Promise((resolve) => {
						setTimeout(() => {
							resolve(getReadData());
						}, 700);
					}).then((res) => {
						if (isMounted) {
							setReadData(res?.data);
						}
					});

					return () => {
						isMounted = false;
					};
				}, [getReadData, readDataRefetch, viewUserId]);

				useEffect(() => {
					if (!!search) {
						setCurrentPage(1);
					}
				}, [search, setCurrentPage]);

				useEffect(() => {
					setCurrentPage(1);
				}, [limitPage, setCurrentPage]);

				// FUNCTION

				const handleOnFinish = async (props) => {
					const { name, data } = props;
					const { token } = user;

					switch (name) {
						case 'view':
							setViewUser(data);
							break;
						case 'verify':
							try {
								const userId = data?.verifyUserId?.uploadedBy[0]?._id;
								const verifyData = {
									userId: userId,
									verified: 'approved',
								};
								await axios.put(
									`${SERVER_URL}/${AXIOS_API_CALL.verifyUser}`,
									{ ...verifyData },
									{
										withCredentials: false,
										headers: { Authorization: `Bearer ${token}` },
									}
								);

								notification.success({
									message: 'User is verified.',
									placement: 'bottomLeft',
								});
								setToggleVerify(false);
                setReadDataRefetch((prevState) => !prevState);
							} catch (err) {
								notification.error({
									message: 'Failed to verify user',
									placement: 'bottomLeft',
								});
								console.error(err);
							}
							break;
						case 'reject':
							try {
								const userId = data?.rejectUserId?.uploadedBy[0]?._id;
								const rejectData = {
									email: data?.rejectUserId?.uploadedBy[0]?.email,
									verified: 'rejected',
								};
								console.log(rejectData, data);
								await axios.post(
									`${SERVER_URL}/${AXIOS_API_CALL.updateCustomers}/${userId}`,
									{ ...rejectData },
									{
										withCredentials: false,
										headers: { Authorization: `Bearer ${token}` },
									}
								);

								notification.success({
									message: 'User is rejected.',
									placement: 'bottomLeft',
								});
								setToggleReject(false);
                setReadDataRefetch((prevState) => !prevState);
							} catch (err) {
								console.log(data);
								notification.error({
									message: 'Failed to reject user',
									placement: 'bottomLeft',
								});
								console.error(err);
							}
							break;
						default:
							console.error('handleOnFinish ---- error');
					}
				};

				// UPDATE
				const viewModalFormRef = useRef(null);
				const [toggleView, setToggleView] = useState(false);
				const [viewFormId, setViewFormId] = useState({ id: '' });
				const [viewForm, setViewForm] = useState({}); // data

				const handleClose = () => {
					setToggleView(false);
				};

				// update
				const viewProps = {
					active: toggleView,
					onToggle: setToggleView,
					formRef: viewModalFormRef,
					data: viewUserId,
					onData: setViewForm,
				};

				const verifyFormFields = [
					{
						name: 'verifyUserId',
						value: verifyUser,
					},
					{
						name: 'status',
						value: verifyUser.status,
					},
				];

        const rejectFormFields = [
					{
						name: 'rejectUserId',
						value: rejectUser,
					},
					{
						name: 'status',
						value: rejectUser.status,
					},
				];
				const verifyProps = {
					active: toggleVerify,
					onToggle: setToggleVerify,
					formRef: verifyModalFormRef,
					data: verifyUser,
					onData: setVerifyUser,
				};


        const rejectProps = {
					active: toggleReject,
					onToggle: setToggleReject,
					formRef: rejectModalFormRef,
					data: rejectUser,
					onData: setRejectUser,
				};

				console.log(verifyProps);
				const verifyFormProps = {
					verifyProps,
					verifyModalFormRef,
					handleOnFinish,
					verifyFormFields,
					verifyBtnLoader,
					setToggleVerify,
				};

        const rejectFormProps = {
					rejectProps,
					rejectModalFormRef,
					handleOnFinish,
					rejectFormFields,
					rejectBtnLoader,
					setToggleReject,
				};

				const viewVerificationProps = {
					viewProps: viewProps,
					viewLoading: viewLoading,
					viewData: viewUser,
					handleClose,
				};

				const tableActionsProps = {
					handleToggle: handleToggle,
					handleDisabledButton: handleDisabledButton,
					handleCheckedRows: handleCheckedRows,
					viewActions: viewProps,
					verifyActions: verifyProps,
          rejectActions: rejectProps,
					location: location,
					groceryPermissions: groceryPermissions,
					getGroceryPermissions: getGroceryPermissions,
				};

				const tableFiltersProps = {
					handleStatusFilter: handleStatusFilter,
				};

				// PAGINATION
				const paginationProps = {
					current: currentPage,
					setCurrentPage: setCurrentPage,
					limitPage: limitPage,
					buttonLimit: 3,
					pagesCount: 1,
					totalCount: 1,
				};

				console.log(readData);

				return (
					<>
						<h2 className="page-title">Seller Verification Requests</h2>
						<Section className="section__wrapper section__sellerReq">
							<header className="section__header">
								{!readDataLoading ? (
									<div className="filter">
										<span style={{ color: '#333', fontWeight: '500' }}>Show:</span>
										<SelectCustom options={options} label={'Items'} limit={limitPage} setLimit={setLimitPage} setRefetch={setReadDataRefetch} loading={readDataLoading} />
									</div>
								) : (
									<LoadingPlaceholder style={{ width: '155.5px', height: '50px' }} />
								)}

								{!readDataLoading ? (
									<div className="search">
										<form className="search-form" onSubmit={(e) => handleOnSubmitSearch(e)} data-cy="search-roles">
											<div className="form-group m-0">
												<span className="icon icon-search">
													<img src="/assets/icons/search.svg" alt="Search" title="Search" />
												</span>
												<input value={searchForm.query || ''} type="text" data-cy="search-input-field" className="input" placeholder="Search" onChange={(e) => handleOnChangeSearch(e.target.value)} />
												<span className={`icon icon-close ${!!searchForm.query && searchForm.query.length > 0 ? 'isActive' : ''}`} onClick={() => handleOnClearSearch()}>
													<img src="/assets/icons/times.svg" alt="Clear" title="Clear" />
												</span>
											</div>
											<button className="form-submit" data-cy="search-submit-btn" disabled={!searchForm.query || searchForm.query.length === 0}>
												Search
											</button>
										</form>
									</div>
								) : (
									<LoadingPlaceholder style={{ width: '100%', maxWidth: '850px', height: '50px' }} />
								)}

								<div className="actions">
									
								</div>
							</header>
							{/* Main content */}
							<Main className="section__content section__content relative min-h-table-content">{readDataLoading ? <Loading /> : <Table data={readData?.data?.Data} {...tableActionsProps} {...tableFiltersProps} pagination={paginationProps} />}</Main>
							<SellerView {...viewVerificationProps} />
							<VerifyForm {...verifyFormProps} />
							<RejectForm {...rejectFormProps} />
						</Section>
					</>
				);
			};;

export default SellerVerificationRequests;
