import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { useAuth } from '../../../context/useAuth';
import { SERVER_URL } from '../../../config/index';
import Loading from '../../../components/loading/Loading';
import LoadingPlaceholder from '../../../components/loadingPlaceholder/LoadingPlaceholder';
import GalleryUpload from './GalleryUpload';
import { Section, Main } from '../../../components/content';
import { PERMISSIONS } from '../../../utils/permissions';
import SlideshowImages from './SlideshowImages';
import { AXIOS_API_CALL } from '../../../utils/endpoint';
import { ProductProvider } from '../../../context/products';
import Table from './Table';
import { type } from '@testing-library/user-event/dist/cjs/utility/type.js';
import { getGroceryPermissions } from '../../../middleware';
import { DeleteBanner } from './delete-banner/DeleteBanner';
import { EditBanner } from './edit-banner/EditBanner';
import { notification } from 'antd';

const Slideshow = () => {
	const { user } = useAuth();
	const navigate = useNavigate();
	const location = useLocation();
	const [groceryPermissions, setGroceryPermissions] = useState([]);

	// GET USER TOKEN
	useEffect(() => {
		const { token } = user || {};
		if (token) {
			const decodedToken = jwtDecode(token);
			const permissions = decodedToken?.roleData?.permissions;
			console.log(permissions);
			if (permissions && location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`)) {
				if (Object.keys(permissions).some((permission) => permission.includes('grocery'))) {
					setGroceryPermissions(permissions.grocery || []);
				}
			}
		}
	}, [user, location]);

	// SEARCH
	const [search, setSearch] = useState(null);
	const [searchForm, setSearchForm] = useState({ query: '' });
	const [refetch, setRefetch] = useState(false);

	const [toggle, setToggle] = useState(false);

	// READ
	const [readData, setReadData] = useState([]);
	const [readDataRefetch, setReadDataRefetch] = useState(false);
	const [readDataLoading, setReadDataLoading] = useState(true);

	const getReadData = useCallback(async () => {
		const { token } = user;

		try {
			const url = search === null ? `${AXIOS_API_CALL.slideshowgalleryImages}` : `${AXIOS_API_CALL.galleryImagesSearch}` + search;
			const response = await axios
				.get(`${SERVER_URL}/${url}`, {
					withCredentials: false,
					headers: {
						department: PERMISSIONS.grocery,
						Authorization: `Bearer ${token}`,
					},
				})
				.then((res) => {
					setTimeout(() => {
						setReadDataLoading(false);
					}, 200);

					return res;
				})
				.catch((err) => {
					setReadDataLoading(false);
					console.error(err);
				});

			return response;
		} catch (err) {}
	}, [user, search]);

	useEffect(() => {
		let isMounted = true;

		new Promise((resolve, reject) => {
			setReadDataLoading(true);
			setTimeout(() => {
				resolve(getReadData());
			}, 700);
		}).then((res) => {
			if (isMounted) {
				setReadData(res?.data);
			}
		});

		return () => {
			isMounted = false;
		};
	}, [getReadData, readDataRefetch]);

	const [imageData, setImageData] = useState({
		upload: [],
		image: [],
		blob: '',
	});
	const [imageFile, setImageFile] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [limitPage, setLimitPage] = useState(10);

	const [bannerId, setBannerId] = useState('');
	const [bannerData, setBannerData] = useState({});
	const [bannerDeleteId, setBannerDeleteId] = useState('');
	const deleteModalFormRef = useRef(null);
	const [toggleDelete, setToggleDelete] = useState(false);

	const [bannerUpdateId, setBannerUpdateId] = useState('');
	const updateModalFormRef = useRef(null);
	const [toggleUpdate, setToggleUpdate] = useState(false);

	const [shoeSearch, getShoeSearch] = useState('');

	const imageProps = {
		type: 'banner',
		slideshow: false,
		navigate: '/admin/shoes/banners',
		multiple: true,
	};

	const handleToggle = (props) => {
		const { name, state, data } = props;
		switch (name) {
			case 'create':
				state.onToggle((prevState) => !prevState);
				break;
			case 'update':
				setToggleUpdate(true);
				setBannerData(data);
				setBannerUpdateId(data._id);
				setImageData(data?.url || '');
				break;
			case 'delete':
				setBannerDeleteId(data);
				state.onToggle((prevState) => !prevState);
				break;
			default:
				console.error('handleToggle ---- error');
		}
	};

	const onDelete = async (bannerId) => {
		const id = bannerId;
		const token = user.token;

		try {
			await axios
				.delete(`${SERVER_URL}/${AXIOS_API_CALL.deleteGalleryImage}/${id}`, { withCredentials: false, headers: { department: PERMISSIONS.grocery, Authorization: `Bearer ${token}` } })
				.then((res) => {
					console.log(res);
					if (res.status === 200) {
						console.log('Delete successful:', res.data);
						setToggleDelete(false);
						notification.success({
							message: res.data.message,
							placement: 'bottomLeft',
						});
						// notifications

						setTimeout(() => {
							window.location.reload();
						}, 700);
					}
				})
				.catch((err) => {
					setDeleteBtnLoader(false);
					console.error(err);
				})
				.finally(() => {
					setDeleteBtnLoader(false);
				});
		} catch (err) {}
	};

	useEffect(() => {
		const fetchImageData = async () => {
			if (!bannerUpdateId) return; // Exit if no ID is set

			const token = user.token; // Get the token from user context

			try {
				const response = await axios.get(`${SERVER_URL}/${AXIOS_API_CALL.getBanner}/${bannerUpdateId}`, {
					headers: {
						Authorization: `Bearer ${token}`,
						department: PERMISSIONS.grocery,
					},
				});

				if (response.status === 200) {
				}
			} catch (error) {
				console.error('Error fetching image data:', error);
				notification.error({
					message: 'Failed to fetch image data.',
					description: error.response?.data?.message || 'An error occurred.',
				});
			}
		};

		fetchImageData();
	}, [bannerUpdateId, user.token]);

	useEffect(() => {
		const fetchShoeData = async () => {
			const token = user.token; // Get the token from user context

			try {
				const response = await axios.get(`${SERVER_URL}/${AXIOS_API_CALL.getSearchShoes}`, {
					headers: {
						Authorization: `Bearer ${token}`,
						department: PERMISSIONS.grocery,
					},
				});

				if (response.status === 200) {
					getShoeSearch(response.data);
					console.log(response.data);
					console.log('Fetched image data:', response.data);
				}
			} catch (error) {
				console.error('Error fetching image data:', error);
				notification.error({
					message: 'Failed to fetch image data.',
					description: error.response?.data?.message || 'An error occurred.',
				});
			}
		};

		fetchShoeData();
	}, [user.token]);

	useEffect(() => {
		console.log('imageFile', imageFile);
	}, [imageFile]);

	async function onSubmit(props) {
		const { event, name, data } = props;
		event.preventDefault();

		console.log(props);

		const id = data?.updateBannerId;
		const { token } = user;
		console.log('imagefile', imageFile);
		const formData = new FormData();
		formData.append('file', imageFile);
		const image = formData.get('file');
		console.log(image);
		switch (name) {
			case 'update':
				try {
					// Construct bannerData object
					const bannerData = {
						title: data?.bannerTitle || '',
						altTitle: data?.bannerTitle || '',
						sliderUrlRedirect: data?.bannerProduct || data?.bannerSliderUrlRedirect || '',
						googleDescription: data?.bannerTitle || '',
						description: data?.bannerTitle || '',
						status: data?.activeBanner ? 'active' : 'inactive',
						redirectionType: data?.bannerKind || '',
						image: image,
						relatedShoe: data?.bannerProduct,
					};

					// Make API call
					const response = await axios.put(`${SERVER_URL}/${AXIOS_API_CALL.updateGalleryImage}/${id}`, bannerData, {
						withCredentials: false,
						headers: {
							'Content-Type': 'multipart/form-data',
							department: PERMISSIONS.grocery,
							Authorization: `Bearer ${token}`,
						},
					});

					if (response.status === 200) {
						console.log('Update successful:', response);

						// Reset state
						setImageData({});
						setBannerUpdateId('');
						setRefetch((prev) => !prev);
						setToggleUpdate(false);

						// Show notification
						notification.success({
							message: response.data.message,
							placement: 'bottomLeft',
						});
						setTimeout(() => {
							window.location.reload();
						}, 500);
					}
				} catch (error) {
					console.error('Error during update:', error);

					// Error notification
					notification.error({
						message: 'Update failed. Please try again.',
						placement: 'bottomLeft',
					});
				}
				break;

			default:
				console.warn('Default case of onSubmit function');
		}
	}

	const deleteFormFields = [
		{
			name: 'deleteBannerId',
			value: bannerDeleteId,
		},
	];

	const updateFormFields = [
		{
			name: 'updateBannerId',
			value: bannerUpdateId,
		},
		{
			name: 'bannerTitle',
			value: bannerData.description,
		},
		{
			name: 'bannerImage',
			value: bannerData.localPath,
		},
		{
			name: 'bannerKind',
			value: bannerData.redirectionType,
		},
		{
			name: 'bannerProduct',
			value: bannerData.relatedShoe,
		},
		{
			name: 'bannerSliderUrlRedirect',
			value: bannerData.sliderUrlRedirect,
		},
		{
			name: 'activeBanner',
			value: bannerData.status === 'active' ? true : false,
		},
	];

	const handleStatusFilter = (value) => {
		setFilterStatus(value);
		setReadDataRefetch((prevState) => !prevState);
	};

	const handleImageUpload = (image) => {
		setTimeout(() => {
			window.location.reload();
		}, 1200);
	};

	const deleteProps = {
		active: toggleDelete,
		onToggle: setToggleDelete,
		formRef: deleteModalFormRef,
	};

	const deleteBannerProps = {
		deleteProps,
		onDelete,
		setToggleDelete,
		bannerDeleteId,
	};

	const updateProps = {
		imageData: imageData,
		active: toggleUpdate,
		onToggle: setToggleUpdate,
		formRef: updateModalFormRef,
		data: bannerData,
	};

	const updateBannerProps = {
		updateProps,
		onSubmit,
		setToggleUpdate,
		bannerUpdateId,
		updateFormFields,
		imageData,
		setImageData,
		shoeSearch,
		setImageFile,
		imageFile,
	};

	const tableActionsProps = {
		location: location,
		handleToggle: handleToggle,
		groceryPermissions: groceryPermissions,
		getGroceryPermissions: getGroceryPermissions,
		setGroceryPermissions: setGroceryPermissions,
		deleteActions: deleteProps,
	};

	const tableFiltersProps = {
		handleStatusFilter: handleStatusFilter,
	};

	// PAGINATION
	const paginationProps = {
		current: readData?.data?.pageNumber,
		setCurrentPage: setCurrentPage,
		limitPage: limitPage,
		buttonLimit: 3,
		pagesCount: readData?.data?.pagesCount,
		totalCount: readData?.data?.totalCount,
	};

	return (
		<ProductProvider>
			<h2 className="page-title">Banners</h2>

			<Section className="section__wrapper section__gallery">
				<header className="section__header">
					{/* <div className="search">
						{!readDataLoading ? (
							<div className="search">
								<form className="search-form" onSubmit={(e) => handleOnSubmitSearch(e)} data-cy="search-roles">
									<div className="form-group m-0">
										<span className="icon icon-search">
											<img src="/assets/icons/search.svg" alt="Search" title="Search" data-cy="search-input-field" />
										</span>
										<input value={searchForm.query || ''} type="text" className="input" placeholder="Search" onChange={(e) => handleOnChangeSearch(e.target.value)} />
										<span className={`icon icon-close ${!!searchForm.query && searchForm.query.length > 0 ? 'isActive' : ''}`} onClick={() => handleOnClearSearch()}>
											<img src="/assets/icons/times.svg" alt="Clear" title="Clear" />
										</span>
									</div>
									<button className="form-submit" data-cy="search-submit-btn" disabled={!searchForm.query || searchForm.query.length === 0}>
										Search
									</button>
								</form>
							</div>
						) : (
							<LoadingPlaceholder style={{ width: '100%', maxWidth: '850px', height: '50px' }} />
						)}
					</div> */}

					<div className="actions">
						{!readDataLoading ? (
							<button onClick={() => setToggle((t) => !t)} className="btn btn-primary pl-2 pr-2" type="button">
								<span className="text">Add banner</span>
							</button>
						) : (
							<LoadingPlaceholder style={{ width: '104.5px', height: '50px' }} />
						)}
					</div>
				</header>

				<Main className="section__content relative min-h-table-content">
					{readDataLoading ? (
						<Loading />
					) : (
						<>
							{toggle ? (
								<>
									{imageData && <img src={imageData} alt="Uploaded banner logo" width={400} height={120} style={{ border: 'dashed 1px gray' }} />}
									<GalleryUpload props={imageProps} data={readData} onImageUpload={handleImageUpload} setRefetch={setReadDataRefetch} />
								</>
							) : null}
							{/* <SlideshowImages data={readData} setRefetch={setReadDataRefetch} /> */}
							<Table data={readData} {...tableActionsProps} {...tableFiltersProps} pagination={paginationProps} />
							<DeleteBanner {...deleteBannerProps} />
							<EditBanner {...updateBannerProps} />
						</>
					)}
				</Main>
			</Section>
		</ProductProvider>
	);
};

export default Slideshow;
