import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../../context/useAuth';
import { SERVER_URL } from '../../../config/index';
import { Section, Main } from '../../../components/content';
import { AXIOS_API_CALL } from '../../../utils/endpoint';
import { PERMISSIONS } from '../../../utils/permissions';
import SelectCustom from '../../../components/customSelect/CustomSelect';
import Loading from '../../../components/loading/Loading';
import Table from './Table';
import LoadingPlaceholder from '../../../components/loadingPlaceholder/LoadingPlaceholder';
import { getGroceryPermissions } from '../../../middleware';
import { notification } from 'antd';
import UnderConstruction from './UnderConstruction';
import { DeleteForm } from '../users-page/delete-form/DeleteForm';
import { UnblockForm } from './unblock-form/UnblockForm';
import { notificationMessages } from '../../../helpers/notificationMessages';

const options = [5, 10, 20, 50, 100];

const BlockList = () => {
	const { user } = useAuth();
	const location = useLocation();
	const [groceryPermissions, setGroceryPermissions] = useState([]);
	const [refetch, setRefetch] = useState(false);

	// GET USER TOKEN
	useEffect(() => {
		const { token } = user || {};
		if (token) {
			const decodedToken = jwtDecode(token);
			const permissions = decodedToken?.roleData?.permissions;

			if (permissions && location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`)) {
				if (Object.keys(permissions).some((permission) => permission.includes('grocery'))) {
					setGroceryPermissions(permissions.grocery || []);
				}
			}
		}
	}, [user, location]);

	// READ
	const [readData, setReadData] = useState([]);
	const [readDataRefetch, setReadDataRefetch] = useState(false);
	const [readDataLoading, setReadDataLoading] = useState(true);

	// SEARCH
	const [search, setSearch] = useState(null);
	const [searchForm, setSearchForm] = useState({ query: '' });
	const [filterStatus, setFilterStatus] = useState(null);

	const handleOnSubmitSearch = (e) => {
		e.preventDefault();
		setSearch(searchForm.query);
		setReadDataRefetch((prevState) => !prevState);
	};

	const handleOnChangeSearch = (value) => {
		setSearchForm({ query: value });
	};

	const handleOnClearSearch = () => {
		setSearch(null);
		setSearchForm({ query: '' });
		setReadDataRefetch((prevState) => !prevState);
	};

	// PAGE
	const [currentPage, setCurrentPage] = useState(1);
	const [limitPage, setLimitPage] = useState(10);

	// FILTER STATUS
	const handleStatusFilter = (value) => {
		setFilterStatus(value);
		setReadDataRefetch((prevState) => !prevState);
	};

	// FILTERS

	// Get selected rows id one or more
	const [checkedRow, setCheckedRow] = useState([]);
	const handleCheckedRows = (data) => {
		setCheckedRow(data);
	};

	const getReadData = useCallback(async () => {
		const { token } = user;

		try {
			const response = await axios
				.get(`${SERVER_URL}/${AXIOS_API_CALL.getBlockedCustomers}/${currentPage}/${limitPage}/${search || null}/${filterStatus || null}`, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } })
				.then((res) => {
				
					return res;
				})
				.catch((err) => console.error(err))
				.finally(
					setTimeout(() => {
						setReadDataLoading(false);
					}, 200)
				);
			return response;
		} catch (err) {
			console.error('Error fetching data:', err);
		}
	}, [user, limitPage, search, currentPage, filterStatus]);

	useEffect(() => {
		let isMounted = true;

		new Promise((resolve, reject) => {
			setReadDataLoading(true);
			setTimeout(() => {
				resolve(getReadData());
			}, 700);
		}).then((res) => {
			if (isMounted) {
				setReadData(res?.data);
			}
		});

		return () => {
			isMounted = false;
		};
	}, [getReadData, readDataRefetch, refetch]);

	useEffect(() => {
		if (!!search) {
			setCurrentPage(1);
		}
	}, [search, setCurrentPage]);

	useEffect(() => {
		setCurrentPage(1);
	}, [limitPage, setCurrentPage]);

	useEffect(() => {
		getReadData();
	}, [getReadData, refetch]);

	// FUNCTION

	async function onSubmit(props) {
		const { event, name, data } = props;
		event.preventDefault();
		const { token } = user;
	}

	// CREATE
	const createModalFormRef = useRef(null);
	const [toggleCreate, setToggleCreate] = useState(false);
	const [createForm, setCreateForm] = useState({}); // data

	const [deleteUser, setDeleteUser] = useState([]);
	const [employeeDeleteId, setEmployeeDeleteId] = useState('');
	const [unblockBtnLoader, setUnblockBtnLoader] = useState(false);
	const [deleteBtnLoader, setDeleteBtnLoader] = useState(false);

	// UPDATE
	const updateModalFormRef = useRef(null);
	const unblockModalFormRef = useRef(null);
	const deleteModalFormRef = useRef(null);
	const [toggleBlock, setToggleBlock] = useState(false);
	const [unblockUser, setUnblockUser] = useState([]);
	const [toggleUpdate, setToggleUpdate] = useState(false);
	const [toggleDelete, setToggleDelete] = useState(false);
	const [updateFormId, setUpdateFormId] = useState({ id: '' });
	const [updateForm, setUpdateForm] = useState({}); // data

	function handleToggle(props) {
		const { name, state, data } = props;
		switch (name) {
			case 'create':
				state.onToggle(true);
				break;
			case 'delete':
				setDeleteUser(data);
				state.onToggle((prevState) => !prevState);
				break;
			case 'unblock':
				setUnblockUser(data);
				setToggleBlock(true);
				break;
			default:
				console.warn('Default of: handleToggle function');
		}
	}

	const handleOnFinish = async (props) => {
		const { name, data } = props;
		const { token } = user;

		switch (name) {
			case 'statusSingle':
				break;
			case 'unblock':
				try {
					const userId = data.unblockUserId._id;
					let newStatus = 'ACTIVE';
					const userStatusData = {
						...data,
						status: newStatus,
					};
					await axios.post(
						`${SERVER_URL}/${AXIOS_API_CALL.updateCustomers}/${userId}`,
						{ ...userStatusData },
						{
							withCredentials: false,
							headers: { Authorization: `Bearer ${token}` },
						}
					);

					setRefetch((prevState) => !prevState);

					setToggleBlock(false);
					setStatusSingleEmoloyee([]);
					setFilterStatus(null);
					setUserStatusSingleId({
						id: '',
						status: '',
					});

					statusModalSingleFormRef.current.resetFields();

					notification.success({
						message: 'User successfully modified!',
						placement: 'bottomLeft',
					});
				} catch (err) {
					console.error(err);
				}
				break;
			case 'delete':
				setDeleteBtnLoader(true);
				try {
					const userId = data?.deleteUserId;

					await axios.delete(
						`${SERVER_URL}/${AXIOS_API_CALL.deleteCustomers}/${userId}`,

						{
							withCredentials: false,
							headers: { Authorization: `Bearer ${token}` },
						}
					);

					setRefetch((prevState) => !prevState);
					setCurrentPage(1);
					setToggleDelete(false);
					setDeleteUser([]);
					setEmployeeDeleteId('');
					deleteModalFormRef.current.resetFields();

					notification.success({
						message: 'User is deleted.',
						placement: 'bottomLeft',
					});
					setTimeout(() => {
						setDeleteBtnLoader(false);
					}, 700);
				} catch (err) {
					notification.error({
						message: 'Failed to delete user',
						placement: 'bottomLeft',
					});
					console.error(err);
					setDeleteBtnLoader(false);
				}
				break;
				
			default:
				console.error('handleOnFinish ---- error');
		}
	};
	const deleteFormFields = [
		{
			name: 'deleteUserId',
			value: deleteUser,
		},
	];

	const unblockFormFields = [
		{
			name: 'unblockUserId',
			value: unblockUser,
		},
		{
			name: 'status',
			value: unblockUser.status,
		},
	];

	const unblockProps = {
		active: toggleBlock,
		onToggle: setToggleBlock,
		formRef: unblockModalFormRef,
		data: unblockUser,
		onData: setUnblockUser,
	};

	const deleteProps = {
		active: toggleDelete,
		onToggle: setToggleDelete,
		formRef: deleteModalFormRef,
		data: deleteUser,
		onData: setDeleteUser,
	};

	const deleteFormProps = {
		deleteProps,
		deleteModalFormRef,
		handleOnFinish,
		deleteFormFields,
		deleteBtnLoader,
		setToggleDelete,
	};
	const unblockFormProps = {
		unblockProps,
		unblockModalFormRef,
		handleOnFinish,
		unblockFormFields,
		unblockBtnLoader,
		setToggleDelete,
	};


	// create
	const createProps = {
		active: toggleCreate,
		onToggle: setToggleCreate,
		formRef: createModalFormRef,
		data: createForm,
		onData: setCreateForm,
	};

	// update
	const updateProps = {
		active: toggleUpdate,
		onToggle: setToggleUpdate,
		formRef: updateModalFormRef,
		data: updateForm,
		onData: setUpdateForm,
	};

	// PROPS
	const tableProps = {};

	const tableActionsProps = {
		handleToggle: handleToggle,
		unblockActions: unblockProps,
		updateActions: updateProps,
		deleteActions: deleteProps,
		location: location,
		groceryPermissions: groceryPermissions,
		getGroceryPermissions: getGroceryPermissions,
	};

	const tableFiltersProps = {
		handleStatusFilter: handleStatusFilter,
	};

	// PAGINATION
	const paginationProps = {
		current: currentPage,
		setCurrentPage: setCurrentPage,
		limitPage: limitPage,
		buttonLimit: 3,
		pagesCount: 1,
		totalCount: 1,
	};



	return (
		<>
			<h2 className="page-title">Block list</h2>
			<Section className="section__wrapper section__blockList">
				<header className="section__header">
					{!readDataLoading ? (
						<div className="filter">
							<span style={{ color: '#333', fontWeight: '500' }}>Show:</span>
							<SelectCustom options={options} label={'Items'} limit={limitPage} setLimit={setLimitPage} setRefetch={setReadDataRefetch} loading={readDataLoading} />
						</div>
					) : (
						<LoadingPlaceholder style={{ width: '155.5px', height: '50px' }} />
					)}
					{!readDataLoading ? (
						<div className="search">
							<form className="search-form" onSubmit={(e) => handleOnSubmitSearch(e)} data-cy="search-roles">
								<div className="form-group m-0">
									<span className="icon icon-search">
										<img src="/assets/icons/search.svg" alt="Search" title="Search" />
									</span>
									<input value={searchForm.query || ''} type="text" data-cy="search-input-field" className="input" placeholder="Search" onChange={(e) => handleOnChangeSearch(e.target.value)} />
									<span className={`icon icon-close ${!!searchForm.query && searchForm.query.length > 0 ? 'isActive' : ''}`} onClick={() => handleOnClearSearch()}>
										<img src="/assets/icons/times.svg" alt="Clear" title="Clear" />
									</span>
								</div>
								<button className="form-submit" data-cy="search-submit-btn" disabled={!searchForm.query || searchForm.query.length === 0}>
									Search
								</button>
							</form>
						</div>
					) : (
						<LoadingPlaceholder style={{ width: '100%', maxWidth: '850px', height: '50px' }} />
					)}
				</header>
				<Main className="section__content section__content relative min-h-table-content">{readDataLoading ? <Loading /> : <Table data={readData?.data?.Data} {...tableActionsProps} {...tableFiltersProps} pagination={paginationProps} />}</Main>
				{/* Delete User - delete */}
				<DeleteForm {...deleteFormProps} />
				<UnblockForm {...unblockFormProps} />
			</Section>
		</>
	);
};

export default BlockList;
