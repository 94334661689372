import React, { useCallback, useEffect, useState } from 'react';
import SidebarModal from '../../../components/sidebarModal/SidebarModal';
import Loading from '../../../components/loading/Loading';
import { useAuth } from '../../../context/useAuth';
import { AXIOS_API_CALL } from '../../../utils/endpoint';
import { PERMISSIONS } from '../../../utils/permissions';
import { SERVER_URL } from '../../../config/index';
import { jwtDecode } from 'jwt-decode';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

export const SellerView = ({ viewProps, viewLoading, viewData, handleClose }) => {
	console.log(viewData);
	const { user } = useAuth();
	const location = useLocation();
	const [groceryPermissions, setGroceryPermissions] = useState([]);

	const [readData, setReadData] = useState([]);
	const [readDataRefetch, setReadDataRefetch] = useState(false);
	const [readDataLoading, setReadDataLoading] = useState(true);
	const { token } = user || {};

	useEffect(() => {
		if (token) {
			const decodedToken = jwtDecode(token);
			const permissions = decodedToken?.roleData?.permissions;
			console.log(permissions);

			if (permissions && location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`)) {
				if (Object.keys(permissions).some((permission) => permission.includes('grocery'))) {
					setGroceryPermissions(permissions.grocery || []);
				}
			}
		}
	}, [user, location]);

	const getReadData = useCallback(async () => {
		console.log('Token:', token); // Log token to check if it's define
		console.log('ViewProps Data:', viewProps);
		if (!viewProps || !token) {
			console.error('Missing token or viewProps.data');
			return;
		}
		try {
			const response = await axios.get(`${SERVER_URL}/${AXIOS_API_CALL.getVerifications}/${viewData}`, {
				withCredentials: false,
				headers: { Authorization: `Bearer ${token}` },
			});

			if (response.data) {
				setReadData(response.data); // Set the data properly
			}
		} catch (err) {
			console.error('Error fetching data:', err);
			setReadDataLoading(false); // Ensure loading is false even in case of an erro
		}
	}, [viewData, token]);

	useEffect(() => {
		console.log('Sidebar active:', viewProps.active); // Debugging if active state is correct
		if (viewProps.active) {
			// setReadDataLoading(true); // Start loading
			getReadData(); // Fetch data
		}
	}, [viewProps.active, getReadData]);

	const formatDate = (isoString) => {
		const date = new Date(isoString);
		return date.toLocaleString(); // You can customize this forma
	};

	console.log(readData);

	const verification = readData?.verification || {};
	const userName = verification.uploadedBy ? `${verification.uploadedBy.firstName} ${verification.uploadedBy.lastName}` : 'No user';
	const userId = verification.uploadedBy ? `${verification.uploadedBy._id}` : 'No user';
	const userImage = verification.selfieImage ? `${verification.selfieImage.url}` : 'No image';
	const stockImg = verification.stockXImage ? `${verification.stockXImage.url}` : '';
	const goatImg = verification.goatImage ? `${verification.goatImage.url}` : '';
	const verified = verification.uploadedBy ? `${verification.uploadedBy.verified}` : '';

	useEffect(() => {
		if (readData && readData.verification) {
		} else {
			console.log('Verification data is missing');
		}
	}, [readData]);
	console.log(viewProps);
	return (
		<SidebarModal {...viewProps} className="sidebar__modal--right" title="Seller Verification Request">
			{readData?.verification ? (
				<div className="product-details">
					{/* <h4 className="product-name">Seller Verification Request {id}</h4> */}

					<div className="form-group">
						<label className="label mb-1 d-block" htmlFor="viewUser">
							<b>User:</b>
						</label>
						<p id="viewUser" className="value viewValue">
							{userName || 'No user'}
						</p>
					</div>

					<div className="form-group">
						<label className="label mb-1 d-block" htmlFor="viewId">
							<b>ID:</b>
						</label>
						<p id="viewId" className="value viewValue">
							{userId || 'No ID'}
						</p>
					</div>

					{/* <div className="form-group">
						<label className="label mb-1 d-block" htmlFor="viewPhoto">
							<b>Photo:</b>
						</label>
						<p id="viewPhoto" className="value viewValue">
							{userIdImage || 'No images'}
						</p>
					</div> */}

					<div className="form-group">
						<label className="label mb-1 d-block" htmlFor="viewGallery">
							<b>Photo:</b>
						</label>
						<p id="viewGallery" className="value viewValue">
							{userImage ? <img src={userImage} height={400}></img> : 'No images'}
						</p>
					</div>
					<div className="form-group">
						<label className="label mb-1 d-block" htmlFor="viewGallery">
							<b>Gallery:</b>
						</label>
						<p id="viewGallery" className="value viewValue">
							{stockImg ? <img src={stockImg} height={200}></img> : 'No images'}
							{goatImg ? <img src={goatImg} height={200}></img> : 'No images'}
						</p>
					</div>

					<div className="form-group">
						<label className="label mb-1 d-block" htmlFor="viewDocuments">
							<b>Documents:</b>
						</label>
						<p id="viewDocuments" className="value viewValue"></p>
					</div>

					<div className="form-group">
						<label className="label mb-1 d-block" htmlFor="viewCreated">
							<b>Created At:</b>
						</label>
						<p id="viewCreated" className="value viewValue">
							{formatDate(readData?.verification.createdAt)}
						</p>
					</div>

					<div className="form-group">
						<label className="label mb-1 d-block" htmlFor="viewUpdated">
							<b>Updated At:</b>
						</label>
						<p id="viewUpdated" className="value viewValue">
							{formatDate(readData?.verification.updatedAt)}
						</p>
					</div>

					<div className="form-group">
						<label className="label mb-1 d-block" htmlFor="viewAwaitingApproval">
							<b>Awaiting Approval:</b>
						</label>
						<p id="viewAwaitingApproval" className="value viewValue">
							{verified}
						</p>
					</div>
					<div className="form-group">
						<label className="label mb-1 d-block" htmlFor="viewApproval">
							<b>Approved:</b>
						</label>
						<p id="viewApproval" className="value viewValue">
							{verified}
						</p>
					</div>
					<div className="form-group">
						<label className="label mb-1 d-block" htmlFor="viewInstagram">
							<b>Instagram:</b>
						</label>
						<p id="viewInstagram" className="value viewValue">
							{readData?.verification.instagramUsername || ''}
						</p>
					</div>

					<div className="form-group">
						<label className="label mb-1 d-block" htmlFor="viewTwitter">
							<b>Twitter:</b>
						</label>
						<p id="viewTwitter" className="value viewValue">
							{readData?.verification.twitterUsername || ''}
						</p>
					</div>
					<div className="form-group">
						<label className="label mb-1 d-block" htmlFor="viewDiscord">
							<b>Discord:</b>
						</label>
						<p id="viewDiscord" className="value viewValue">
							{readData?.verification.discordUsername || ''}
						</p>
					</div>
				</div>
			) : (
				<Loading />
			)}

			<div className="form-group form-group-sidebar">
				<button data-cy="modal-close-btn" type="button" className="btn btn-primary-link" onClick={handleClose}>
					<span className="text">Close</span>
				</button>
			</div>
		</SidebarModal>
	);
};
