import React from 'react';
import Modal from '../../../../components/modal/Modal';
import { Input, Button, Form, Row } from 'antd';

export const DeleteAsk = ({ deleteProps, deleteModalFormRef, handleOnFinish, deleteFormFields, deleteBtnLoader, setToggleDelete }) => {
					return (
						<Modal {...deleteProps} className="sidebar__modal--center" title="">
							<Form name="delete" ref={deleteModalFormRef} wrapperCol={{ span: 24 }} initialValues={{ remember: false }} onFinish={(data) => handleOnFinish({ name: 'delete', data: data })} fields={deleteFormFields}>
								<h2 className="text-center mb-4">Are you sure you want to delete the selected ask?</h2>

								<Form.Item name="deleteUserId" style={{ display: 'none' }}>
									<Input type="hidden" />
								</Form.Item>

								<Form.Item>
									<Row gutter={16} style={{ justifyContent: 'center', columnGap: '20px' }}>
										{!deleteBtnLoader ? (
											<Button type="submit" htmlType="submit" className="btn btn-primary-link btn-block">
												<span>Yes</span>
											</Button>
										) : (
											<Button type="button" className="btn btn-primary-link btn-block">
												<span>Deleting...</span>
											</Button>
										)}

										<Button type="primary" htmlType="button" className="btn btn-primary btn-block" onClick={() => setToggleDelete(false)}>
											<span>No</span>
										</Button>
									</Row>
								</Form.Item>
							</Form>
						</Modal>
					);
				};
