import React from 'react';
import SidebarModal from '../../../../components/sidebarModal/SidebarModal';
import Loading from '../../../../components/loading/Loading';
import { jwtDecode } from 'jwt-decode';
import { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../../../context/useAuth';
import { PERMISSIONS } from '../../../../utils/permissions';
import { AXIOS_API_CALL } from '../../../../utils/endpoint';
import axios from 'axios';
import { SERVER_URL } from '../../../../config/index';

export const ViewProduct = ({ viewProps, viewLoading, viewData, handleClose }) => {
	const { user } = useAuth();
	const location = useLocation();
	const [groceryPermissions, setGroceryPermissions] = useState([]);
	const [productDetails, setProductDetails] = useState(null); // State to store product data
	const [readDataLoading, setReadDataLoading] = useState(true);
	if (!viewData) return <p>No product data available</p>;

	const sizeChartEntries = [
		{ usMens: 'US M 3.5', usWmns: 'US W 5', usChldrns: 'US 3.5 Y' },
		{ usMens: 'US M 4', usWmns: 'US W 5.5', usChldrns: 'US 4 Y' },
		{ usMens: 'US M 4.5', usWmns: 'US W 6', usChldrns: 'US 4.5 Y' },
		{ usMens: 'US M 5', usWmns: 'US W 6.5', usChldrns: 'US 5 Y' },
		{ usMens: 'US M 5.5', usWmns: 'US W 7', usChldrns: 'US 5.5 Y' },
		{ usMens: 'US M 6', usWmns: 'US W 7.5', usChldrns: 'US 6 Y' },
		{ usMens: 'US M 6.5', usWmns: 'US W 8', usChldrns: 'US 6.5 Y' },
		{ usMens: 'US M 7', usWmns: 'US W 8.5', usChldrns: 'US 7 Y' },
		{ usMens: 'US M 7.5', usWmns: 'US W 9', usChldrns: '' },
		{ usMens: 'US M 8', usWmns: 'US W 9.5', usChldrns: '' },
		{ usMens: 'US M 8.5', usWmns: 'US W 10', usChldrns: '' },
		{ usMens: 'US M 9', usWmns: 'US W 10.5', usChldrns: '' },
		{ usMens: 'US M 9.5', usWmns: 'US W 11', usChldrns: '' },
		{ usMens: 'US M 10', usWmns: 'US W 11.5', usChldrns: '' },
		{ usMens: 'US M 10.5', usWmns: 'US W 12', usChldrns: '' },
		{ usMens: 'US M 11', usWmns: 'US W 12.5', usChldrns: '' },
		{ usMens: 'US M 11.5', usWmns: 'US W 13', usChldrns: '' },
		{ usMens: 'US M 12', usWmns: 'US W 13.5', usChldrns: '' },
		{ usMens: 'US M 12.5', usWmns: 'US W 14', usChldrns: '' },
		{ usMens: 'US M 13', usWmns: 'US W 14.5', usChldrns: '' },
		{ usMens: 'US M 13.5', usWmns: 'US W 15', usChldrns: '' },
		{ usMens: 'US M 14', usWmns: 'US W 15.5', usChldrns: '' },
		{ usMens: 'US M 14.5', usWmns: 'US W 16', usChldrns: '' },
		{ usMens: 'US M 15', usWmns: 'US W 16.5', usChldrns: '' },
		{ usMens: 'US M 15.5', usWmns: 'US W 17', usChldrns: '' },
	];

	useEffect(() => {
		const { token } = user || {};
		if (token) {
			const decodedToken = jwtDecode(token);
			const permissions = decodedToken?.roleData?.permissions;

			if (permissions && location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`)) {
				if (Object.keys(permissions).some((permission) => permission.includes('grocery'))) {
					setGroceryPermissions(permissions.grocery || []);
				}
			}
		}
	}, [user, location]);

	useEffect(() => {
		const getReadData = async () => {
			const { token } = user;
			try {
				const response = await axios.get(`${SERVER_URL}/${AXIOS_API_CALL.getProduct}/${viewData}`, {
					withCredentials: false,
					headers: { Authorization: `Bearer ${token}` },
				});
				setProductDetails(response.data);
			} catch (err) {
				console.error('Error fetching data:', err);
			} finally {
				setReadDataLoading(false);
			}
		};

		if (viewData) {
			getReadData();
		}
	}, [viewData, user]);

	if (readDataLoading) return <Loading />; // Show loading indicator while fetching

	if (!productDetails) return <p>No product data available</p>;

	console.log(productDetails);

	const { name = '', brand = [], category = [], colorway = '', factoryColor = '', styleSeason = '', sku = '', gender = '', retailPrice = '', fee = '', releaseDate = '', images = [], active = false, futureRelease = false, goatPrices = [], stockxPrices = [], stockxFee = '', usedForStockxAPI = false, goatUpdatedAt = '', stockxUpdatedAt = '', colors = '', usedForGOAT = false } = productDetails;

	const brandName = brand.name || 'No brand';
	const imageUrl = images.length > 0 ? images[0].url : '';
	const sortedGoatPrices = goatPrices.sort((a, b) => {
		// Extract the numeric part of the size and convert it to a float for comparison
		const sizeA = parseFloat(a.size.replace(/[^0-9.]/g, ''));
		const sizeB = parseFloat(b.size.replace(/[^0-9.]/g, ''));

		return sizeA - sizeB; // Sort in ascending order
	});
	const goatPricesMap = sortedGoatPrices.map(({ size, price }) => {
		// Find the entry in sizeChartEntries that matches the current size
		let displaySize = size; // Default to the original size

		// Check if the gender is "Children"
		if (gender === 'Children') {
			// Find the entry in sizeChartEntries that matches the current size
			const entry = sizeChartEntries.find((entry) => entry.usMens === size);

			// If an entry is found, use the equivalent children's size; otherwise, keep the original size
			displaySize = entry && entry.usChldrns ? entry.usChldrns : entry.usMens; // Fallback to original size if no match found
		} else if (gender === 'Female') {
			const entry = sizeChartEntries.find((entry) => entry.usMens === size);

			// If an entry is found, use the equivalent children's size; otherwise, keep the original size
			displaySize = entry ? entry.usWmns : size;
		}
		return (
			<div key={`goat-${size}`} className="price-detail">
				For {displaySize} = ${price}
			</div>
		);
	});
	const sortedStockXPrices = stockxPrices.sort((a, b) => {
		// Extract the numeric part of the size and convert it to a float for comparison
		const sizeA = parseFloat(a.size.replace(/[^0-9.]/g, ''));
		const sizeB = parseFloat(b.size.replace(/[^0-9.]/g, ''));

		return sizeA - sizeB; // Sort in ascending order
	});
	const stockxPricesMap = sortedStockXPrices.map(({ size, price }) => {
		let displaySize = size; // Default to the original size

		// Check if the gender is "Children"
		if (gender === 'Children') {
			// Find the entry in sizeChartEntries that matches the current size
			const entry = sizeChartEntries.find((entry) => entry.usMens === size);
			console.log(size);
			// If an entry is found, use the equivalent children's size; otherwise, keep the original size
			displaySize = entry && entry.usChldrns ? entry.usChldrns : entry.usMens; // Fallback to original size if no match found
		} else if (gender === 'Female') {
			const entry = sizeChartEntries.find((entry) => entry.usMens === size);

			// If an entry is found, use the equivalent children's size; otherwise, keep the original size
			displaySize = entry ? entry.usWmns : size;
		}
		return (
			<div key={`stockx-${size}`} className="price-detail">
				For {displaySize} = ${price}
			</div>
		);
	});

	return (
		<SidebarModal {...viewProps} className="sidebar__modal--right" title="View Product Details">
			{!viewLoading ? (
				<div className="product-details">
					<h4 className="product-name">{name}</h4>

					<div className="form-group">
						<label className="label mb-1 d-block" htmlFor="viewBrand">
							<b>Brand:</b>
						</label>
						<p id="viewBrand" className="value viewValue">
							{brandName}
						</p>
					</div>

					<div className="form-group">
						<label className="label mb-1 d-block" htmlFor="viewCategory">
							<b>Category:</b>
						</label>
						<p id="viewCategory" className="value viewValue">
							{category.length > 0 ? category[0].name : 'No category'}
						</p>
					</div>

					<div className="form-group">
						<label className="label mb-1 d-block" htmlFor="viewColorway">
							<b>Colorway:</b>
						</label>
						<p id="viewColorway" className="value viewValue">
							{colorway || ''}
						</p>
					</div>

					<div className="form-group">
						<label className="label mb-1 d-block" htmlFor="viewFactoryColor">
							<b>Factory Color:</b>
						</label>
						<p id="viewFactoryColor" className="value viewValue">
							{factoryColor || ''}
						</p>
					</div>

					<div className="form-group">
						<label className="label mb-1 d-block" htmlFor="viewColor">
							<b> Color:</b>
						</label>
						<p id="viewColor" className="value viewValue">
							{colors || ''}
						</p>
					</div>

					<div className="form-group">
						<label className="label mb-1 d-block" htmlFor="viewStyleSeason">
							<b>Style Season:</b>
						</label>
						<p id="viewStyleSeason" className="value viewValue">
							{styleSeason || ''}
						</p>
					</div>

					<div className="form-group">
						<label className="label mb-1 d-block" htmlFor="viewSku">
							<b>SKU:</b>
						</label>
						<p id="viewSku" className="value viewValue">
							{sku || ''}
						</p>
					</div>

					<div className="form-group">
						<label className="label mb-1 d-block" htmlFor="viewGender">
							<b>Gender:</b>
						</label>
						<p id="viewGender" className="value viewValue">
							{gender || ''}
						</p>
					</div>

					<div className="form-group">
						<label className="label mb-1 d-block" htmlFor="viewReleaseDate">
							<b>Release Date:</b>
						</label>
						<p id="viewReleaseDate" className="value viewValue">
							{new Date(releaseDate).toLocaleDateString() || ''}
						</p>
					</div>

					{images && images.length > 0 && (
						<div className="form-group">
							<label className="label mb-1 d-block" htmlFor="viewImage">
								<b>Image:</b>
							</label>
							<img id="viewImage" src={images[0].url} alt={images[0].altTitle || 'Image'} width="400" className="product-image" />
						</div>
					)}

					<div className="form-group">
						<label className="label mb-1 d-block" htmlFor="viewActive">
							<b>Active:</b>
						</label>
						<p id="viewActive" className="value viewValue">
							{active ? 'Yes' : 'No'}
						</p>
					</div>

					<div className="form-group">
						<label className="label mb-1 d-block" htmlFor="viewFutureRelease">
							<b>Future Release:</b>
						</label>
						<p id="viewFutureRelease" className="value viewValue">
							{futureRelease ? 'Yes' : 'No'}
						</p>
					</div>
					<div className="form-group">
						<label className="label mb-1 d-block" htmlFor="viewRetailPrice">
							<b>Retail Price:</b>
						</label>
						<p id="viewRetailPrice" className="value viewValue">
							{retailPrice || ''}
						</p>
					</div>
					{/* Display GOAT Prices */}
					<div className="form-group">
						<label className="label mb-1 d-block" htmlFor="viewGoatPrices">
							<b>GOAT Prices:</b>
						</label>
						<p id="viewGoatPrices" className="value viewValue">
							{goatPrices.length > 0 ? goatPricesMap : <p>No GOAT prices available</p>}
						</p>
					</div>
					<div className="form-group">
						<label className="label mb-1 d-block" htmlFor="viewUsedForGOAT">
							<b>Used for GOAT API:</b>
						</label>
						<p id="viewUsedForGOAT" className="value viewValue">
							{usedForGOAT ? 'Yes' : 'No'}
						</p>
					</div>
					<div className="form-group">
						<label className="label mb-1 d-block" htmlFor="viewGoatUpdated">
							<b>GOAT Price Updated At:</b>
						</label>
						<p id="viewGoatUpdated" className="value viewValue">
							{new Date(goatUpdatedAt).toLocaleString('en-US', { dateStyle: 'medium', timeStyle: 'short' }) || ''}
						</p>
					</div>

					{/* Display StockX Fee */}
					<div className="form-group">
						<label className="label mb-1 d-block" htmlFor="viewStockxFee">
							<b>StockX Fee:</b>
						</label>
						<p id="viewGoatFee" className="value viewValue">
							{stockxFee ? stockxFee : <p>No StockX prices available</p>}
						</p>
					</div>

					<div className="form-group">
						<label className="label mb-1 d-block" htmlFor="viewStockxUpdated">
							<b>Stock X Price Updated At:</b>
						</label>
						<p id="viewStockxUpdated" className="value viewValue">
							{new Date(stockxUpdatedAt).toLocaleString('en-US', { dateStyle: 'medium', timeStyle: 'short' }) || ''}
						</p>
					</div>

					{/* Display StockX Prices */}
					<div className="form-group">
						<label className="label mb-1 d-block" htmlFor="viewStockxPrices">
							<b>StockX Prices:</b>
						</label>
						<p id="viewGoatPrices" className="value viewValue">
							{stockxPrices.length > 0 ? stockxPricesMap : <p>No StockX prices available</p>}
						</p>
					</div>
					<div className="form-group">
						<label className="label mb-1 d-block" htmlFor="viewUsedForStockxAPI">
							<b>Used for Stock x API:</b>
						</label>
						<p id="viewUsedForStockxAPI" className="value viewValue">
							{usedForStockxAPI ? 'Yes' : 'No'}
						</p>
					</div>
				</div>
			) : (
				<Loading />
			)}

			<div className="form-group form-group-sidebar">
				<button data-cy="modal-close-btn" type="button" className="btn btn-primary-link" onClick={handleClose}>
					<span className="text">Close</span>
				</button>
			</div>
		</SidebarModal>
	);
};
