import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../../context/useAuth';
import { SERVER_URL } from '../../../config/index';
import { Section, Main } from '../../../components/content';
import { AXIOS_API_CALL } from '../../../utils/endpoint';
import { PERMISSIONS } from '../../../utils/permissions';
import SelectCustom from '../../../components/customSelect/CustomSelect';
import { notification } from 'antd';
import Loading from '../../../components/loading/Loading';
import Table from './Table';
import LoadingPlaceholder from '../../../components/loadingPlaceholder/LoadingPlaceholder';
import { getGroceryPermissions } from '../../../middleware';
import { DeleteForm } from '../users-page/delete-form/DeleteForm';
import { DeleteAsk } from './delete-ask/DeleteAsk';
import StatusDropdown from '../../../components/statusDropdown/StatusDropdown';
import { DeactivateAsk } from './deactivate-ask/DeactivateAsk';
import { UpdateAskPrice } from './update-ask-price/UpdateAskPrice';

const options = [5, 10, 20, 50, 100];

const Asks = () => {
	const { user } = useAuth();
	const location = useLocation();
	const [groceryPermissions, setGroceryPermissions] = useState([]);

	// GET USER TOKEN
	useEffect(() => {
		const { token } = user || {};
		if (token) {
			const decodedToken = jwtDecode(token);
			const permissions = decodedToken?.roleData?.permissions;
			console.log(permissions);

			if (permissions && location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`)) {
				if (Object.keys(permissions).some((permission) => permission.includes('grocery'))) {
					setGroceryPermissions(permissions.grocery || []);
				}
			}
		}
	}, [user, location]);

	// READ
	const [readData, setReadData] = useState([]);
	const [readDataRefetch, setReadDataRefetch] = useState(false);
	const [readDataLoading, setReadDataLoading] = useState(true);

	// SEARCH
	const [search, setSearch] = useState(null);
	const [searchForm, setSearchForm] = useState({ query: '' });
	const [filterStatus, setFilterStatus] = useState(null);

	const handleOnSubmitSearch = (e) => {
		e.preventDefault();
		setSearch(searchForm.query);
		setReadDataRefetch((prevState) => !prevState);
		console.log(search);
	};

	const handleOnChangeSearch = (value) => {
		setSearchForm({ query: value });
	};

	const handleOnClearSearch = () => {
		setSearch(null);
		setSearchForm({ query: '' });
		setReadDataRefetch((prevState) => !prevState);
	};

	// PAGE
	const [currentPage, setCurrentPage] = useState(1);
	const [limitPage, setLimitPage] = useState(10);

	// FILTER STATUS
	const handleStatusFilter = (value) => {
		setFilterStatus(value);
		setReadDataRefetch((prevState) => !prevState);
	};

	// FILTERS
	const [disabledButton, setDisabledButton] = useState(false);
	const handleDisabledButton = (status) => {
		setDisabledButton(status);
	};

	// Get selected rows id one or more
	const [checkedRow, setCheckedRow] = useState([]);
	const handleCheckedRows = (data) => {
		setCheckedRow(data);
	};

	const getReadData = useCallback(async () => {
		const { token } = user;

		const payload = {
			department: PERMISSIONS.grocery,
			searchTerm: search,
		};

		try {
			const response = await axios
				.get(`${SERVER_URL}/${AXIOS_API_CALL.getAsks}/${currentPage}/${limitPage}/${search || null}/${filterStatus || null}`, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } })
				.then((res) => {
					console.log('Response data:', res.data);
					return res;
				})
				.catch((err) => console.error(err))
				.finally(
					setTimeout(() => {
						setReadDataLoading(false);
					}, 200)
				);
			return response;
		} catch (err) {
			console.error('Error fetching data:', err);
		}
	}, [user, limitPage, search, currentPage, filterStatus]);

	useEffect(() => {
		let isMounted = true;

		new Promise((resolve, reject) => {
			setReadDataLoading(true);
			setTimeout(() => {
				resolve(getReadData());
			}, 700);
		}).then((res) => {
			if (isMounted) {
				setReadData(res?.data);
			}
		});

		return () => {
			isMounted = false;
		};
	}, [getReadData, readDataRefetch]);


	useEffect(() => {
		if (!!search) {
			setCurrentPage(1);
		}
		localStorage.setItem('totalActiveAsks', readData?.totalActiveAsks);
		localStorage.setItem('valueOfActiveAsks', readData?.valueOfActiveAsks);
	}, [search, setCurrentPage, readData]);

	useEffect(() => {
		setCurrentPage(1);
	}, [limitPage, setCurrentPage, filterStatus]);

	const [askDeleteId, setAskDeleteId] = useState('');
	const [askDeactivateId, setAskDeactivateId] = useState('');
	const [askEditPriceData, setAskEditPriceData] = useState(null);
	const [toggleDelete, setToggleDelete] = useState(false);
	const [toggleDeactivate, setToggleDeactivate] = useState(false);
	const [toggleEditAskPrice, setToggleEditAskPrice] = useState(false);
	const deleteModalFormRef = useRef(null);
	const deactivateModalFormRef = useRef(null);
	const editAskPriceModalFormRef = useRef(null);
	const [deleteBtnLoader, setDeleteBtnLoader] = useState(false);
	const [deactivateBtnLoader, setDeactivateBtnLoader] = useState(false);
	const [editAskPriceBtnLoader, setEditAskPriceBtnLoader] = useState(false);
	const [refetch, setRefetch] = useState(false);

	const handleToggle = (props) => {
		const { name, state, data } = props;
		switch (name) {
			case 'delete':
				setAskDeleteId(data);
				state.onToggle((prevState) => !prevState);
				break;
			case 'deactivate':
				setAskDeactivateId(data);
				state.onToggle((prevState) => !prevState);
				break;
			case 'edit-ask-price':
				setAskEditPriceData(data);
				state.onToggle((prevState) => !prevState);
				break;

			default:
				console.error('handleToggle ---- error');
		}
	};

	const deleteFormFields = [
		{
			name: 'deleteUserId',
			value: askDeleteId,
		},
	];

	const deactivateFormFields = [
		{
			name: 'deactivateUserId',
			value: askDeactivateId,
		},
	];

	const editAskPriceFormFields = [
    {
      name: 'askEditPriceData',
      value: askEditPriceData,
    },
  ];

	const handleOnFinish = async (props) => {
		const { name, data } = props;
		const { token } = user;

		console.log(props);

		switch (name) {
			case 'delete':
				setDeleteBtnLoader(true);

				const deleteId = data.deleteUserId;

				await axios
					.delete(
						`${SERVER_URL}/${AXIOS_API_CALL.deleteAsks}/${deleteId}`,

						{
							withCredentials: false,
							headers: { Authorization: `Bearer ${token}` },
						}
					)
					.then((res) => {
						if (res.status === 200) {
							setRefetch((prevState) => !prevState);
							setToggleDelete(false);
							deleteModalFormRef.current.resetFields();
							notification.success({
								message: 'Ask is deleted.',
								placement: 'bottomLeft',
							});
							setTimeout(() => {
								window.location.reload();
							}, 500);
						}
					})
					.catch((err) => {
						console.error('Error when deleting ask:', err);
						notification.error({
							message: err?.response?.data?.error || 'Failed to delete ask',
							placement: 'bottomLeft',
						});
					})
					.finally(() => {
						setDeleteBtnLoader(false);
					});
				break;
        case 'deactivate':
        await axios
          .put(
            `${SERVER_URL}/${AXIOS_API_CALL.deactivateAsk}/${askDeactivateId}`,
            null,
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            if (res.status === 200) {
              setRefetch((prevState) => !prevState);
              setToggleDeactivate(false);
              deactivateModalFormRef.current.resetFields();
              notification.success({
                message: 'Ask is deactivated.',
                placement: 'bottomLeft',
              });
              setTimeout(() => {
                window.location.reload();
              }, 500);
            }
          })
          .catch((err) => {
            console.log('Error when deactivating ask:', err);
            setToggleDeactivate(false);
            notification.error({
              message: err?.response?.data?.error || 'Failed to deactivate ask',
              placement: 'bottomLeft',
            });
          })
          .finally(() => {
            setDeactivateBtnLoader(false);
          });
        break;
        case 'edit-ask-price':
        const {regularPrice, _id} = data
        await axios
          .put(
            `${SERVER_URL}/${AXIOS_API_CALL.updateAskPrice}/${_id}`,
            {
              regularPrice: regularPrice
            },
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            if (res.status === 200) {
              setRefetch((prevState) => !prevState);
              setToggleEditAskPrice(false);
              editAskPriceModalFormRef.current.resetFields();
              notification.success({
                message: 'Price is edited.',
                placement: 'bottomLeft',
              });
              setTimeout(() => {
                window.location.reload();
              }, 500);
            }
          })
          .catch((err) => {
            console.error('Error when editing ask price:', err);
            notification.error({
              message: 'Failed to edit price',
              placement: 'bottomLeft',
            });
          })
          .finally(() => {
            setEditAskPriceBtnLoader(false);
          });
        break;
			default:
				console.error('handleOnFinish ---- error');
		}
	};

	const deleteProps = {
		active: toggleDelete,
		onToggle: setToggleDelete,
		formRef: deleteModalFormRef,
		data: askDeleteId,
		onData: setAskDeleteId,
	};

	const deactivateProps = {
		active: toggleDeactivate,
		onToggle: setToggleDeactivate,
		formRef: deactivateModalFormRef,
		data: askDeactivateId,
		onData: setAskDeactivateId,
	};

	const editAskPriceProps = {
		active: toggleEditAskPrice,
		onToggle: setToggleEditAskPrice,
		formRef: editAskPriceModalFormRef,
		data: askEditPriceData,
		onData: setAskEditPriceData,
	};

	const deleteFormProps = {
		deleteProps,
		deleteModalFormRef,
		handleOnFinish,
		deleteFormFields,
		deleteBtnLoader,
		setToggleDelete,
	};

	const deactivateFormProps = {
		deactivateProps,
		deactivateModalFormRef,
		handleOnFinish,
		deactivateFormFields,
		deactivateBtnLoader,
		setToggleDeactivate,
	};

	const editAskPriceFormProps = {
    editAskPriceProps,
    editAskPriceModalFormRef,
    handleOnFinish,
    editAskPriceFormFields,
    editAskPriceBtnLoader,
    setToggleEditAskPrice,
  };


	const tableActionsProps = {
		handleToggle: handleToggle,
		deleteActions: deleteProps,
    deactivateActions: deactivateProps,
    editAskPriceActions: editAskPriceProps,
		location: location,
		groceryPermissions: groceryPermissions,
		getGroceryPermissions: getGroceryPermissions,
	};

	const tableFiltersProps = {
		handleStatusFilter: handleStatusFilter,
	};

	// PAGINATION
	const paginationProps = {
		current: currentPage,
		setCurrentPage: setCurrentPage,
		limitPage: limitPage,
		buttonLimit: 4,
		pagesCount: readData?.data?.pagesCount,
		totalCount: readData?.data?.totalCount,
	};


	return (
		<>
			<h2 className="page-title">Asks</h2>
			<div className="page-info">
				<div className="page-info__wrapper">
					<p>Active Asks: {readData?.totalActiveAsks}</p>
				</div>
				<div className="page-info__wrapper">
					<p>
						Value of active asks: <span>{readData?.valueOfActiveAsks}</span>
					</p>
				</div>
        <StatusDropdown filterStatus={filterStatus} setFilterStatus={setFilterStatus} />
			</div>
			<Section className="section__wrapper section__asks">
				<header className="section__header">
					{!readDataLoading ? (
						<div className="filter">
							<span style={{ color: '#333', fontWeight: '500' }}>Show:</span>
							<SelectCustom options={options} label={'Items'} limit={limitPage} setLimit={setLimitPage} setRefetch={setReadDataRefetch} loading={readDataLoading} />
						</div>
					) : (
						<LoadingPlaceholder style={{ width: '155.5px', height: '50px' }} />
					)}
					{!readDataLoading ? (
						<div className="search">
							<form className="search-form" onSubmit={(e) => handleOnSubmitSearch(e)} data-cy="search-roles">
								<div className="form-group m-0">
									<span className="icon icon-search">
										<img src="/assets/icons/search.svg" alt="Search" title="Search" />
									</span>
									<input value={searchForm.query || ''} type="text" data-cy="search-input-field" className="input" placeholder="Search" onChange={(e) => handleOnChangeSearch(e.target.value)} />
									<span className={`icon icon-close ${!!searchForm.query && searchForm.query.length > 0 ? 'isActive' : ''}`} onClick={() => handleOnClearSearch()}>
										<img src="/assets/icons/times.svg" alt="Clear" title="Clear" />
									</span>
								</div>
								<button className="form-submit" data-cy="search-submit-btn" disabled={!searchForm.query || searchForm.query.length === 0}>
									Search
								</button>
							</form>
						</div>
					) : (
						<LoadingPlaceholder style={{ width: '100%', maxWidth: '850px', height: '50px' }} />
					)}
				</header>
				{/* Main content */}
				<Main className="section__content section__content relative min-h-table-content">{readDataLoading ? <Loading /> : <Table data={readData?.data?.Data} {...tableActionsProps} {...tableFiltersProps} pagination={paginationProps} />}</Main>
				{/* Delete User - delete */}
				<DeleteAsk {...deleteFormProps} />
				<DeactivateAsk {...deactivateFormProps} />
				<UpdateAskPrice {...editAskPriceFormProps} />
			</Section>
		</>
	);
};

export default Asks;
