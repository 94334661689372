import React from 'react';
import { Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';

const menuData = [
  {
    key: 'SELECT_STATUS',
    value: null,
    label: 'All',
  },
  {
    key: 'ACTIVE',
    value: 'active',
    label: 'Active',
  },
  {
    key: 'INACTIVE',
    value: 'inactive',
    label: 'Inactive',
  },
  {
    key: 'RETURNED',
    value: 'returned',
    label: 'Returned',
  },
  {
    key: 'REJECTED',
    value: 'rejected',
    label: 'Rejected',
  },
  {
    key: 'COMPLETED',
    value: 'completed',
    label: 'Completed',
  },
  {
    key: 'PENDING_ADMIN_APPROVAL',
    value: 'pending_admin_approval',
    label: 'Pending Admin Approval',
  },
  {
    key: 'PENDING_CONFIRMATION',
    value: 'pending_confirmation',
    label: 'Pending Confirmation',
  },
  {
    key: 'PENDING_VERIFICATION',
    value: 'pending_verification',
    label: 'Pending Verification',
  },
  {
    key: 'SENT_ON_VERIFICATION',
    value: 'sent_on_verification',
    label: 'Sent on Verification',
  },
  {
    key: 'PENDING_SHIPMENT_TO_ADMIN',
    value: 'pending_shipment_to_admin',
    label: 'Pending Shipment to Admin',
  },
  {
    key: 'PENDING_SHIPMENT_TO_BUYER',
    value: 'pending_shipment_to_buyer',
    label: 'Pending Shipment to Buyer',
  },
  {
    key: 'PENDING_SHIPMENT_TO_SELLER',
    value: 'pending_shipment_to_seller',
    label: 'Pending Shipment to Seller',
  },
  {
    key: 'SHIPPED_TO_ADMIN',
    value: 'shipped_to_admin',
    label: 'Shipped to Admin',
  },
];

const StatusDropdown = ({ filterStatus, setFilterStatus }) => {
  const menu = (
    <Menu>
      {menuData.map((item) => (
        <Menu.Item key={item.key}>
          <li className={`${filterStatus === item.value ? 'selected-status-item' : ''} status-dropdown-item`} onClick={() => setFilterStatus(item.value)}>
            {item.label}
          </li>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <button className="dropdown-btn">
        {filterStatus ? menuData.find((item) => item.value === filterStatus)?.label || 'Select Status' : 'Select Status'} <DownOutlined />
      </button>
    </Dropdown>
  );
};

export default StatusDropdown;
