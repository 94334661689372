import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from '../../../context/useAuth';
import { SERVER_URL } from '../../../config/index';
import { DatePicker, Space } from 'antd';
import Table from './Table';
import SelectCustom from '../../../components/customSelect/CustomSelect';
import Loading from '../../../components/loading/Loading';
import LoadingPlaceholder from '../../../components/loadingPlaceholder/LoadingPlaceholder';
import { Section, Main } from '../../../components/content';
import { AXIOS_API_CALL } from '../../../utils/endpoint';
import { PERMISSIONS } from '../../../utils/permissions';

const { RangePicker } = DatePicker;

const options = [5, 10, 20, 50, 100];

const Activity = () => {
	const { user } = useAuth();

	const [currentPage, setCurrentPage] = useState(1);
	const [limitPage, setLimitPage] = useState(10);
	const [list, setList] = useState([]);
	const [refetch, setRefetch] = useState(false);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState('');
	const [listDownloadId, setListDownloadId] = useState({ id: '' });
	// FILTER DATA PICKER
	const [dataPicker, setDatePicker] = useState({ from: '', to: '' });

	// SEARCH
	const [search, setSearch] = useState(null);
	const [searchForm, setSearchForm] = useState({ query: '' });

	const handleOnSubmitSearch = (e) => {
		e.preventDefault();
		setSearch(searchForm.query);
		setRefetch((prevState) => !prevState);
	};

	const handleOnChangeSearch = (value) => {
		setSearchForm({ query: value });
	};

	const handleOnClearSearch = () => {
		setSearch(null);
		setSearchForm({ query: '' });
		setRefetch((prevState) => !prevState);
	};

	useEffect(() => {
		if (!!search) {
			setCurrentPage(1);
		}
	}, [search]);

	useEffect(() => {
		setCurrentPage(1);
	}, [limitPage]);

	useEffect(() => {
		if (!!dataPicker.from && !!dataPicker.to) {
			setCurrentPage(1);
		} else {
			setCurrentPage(1);
		}
	}, [dataPicker]);

	// GET ACTIVITY DATA
	useEffect(() => {
		const userId = user.id;

		const { token } = user;

		let dataBody = [];

		const dateFrom = dataPicker.from && new Date(dataPicker.from);
		const dateTo = dataPicker.to && new Date(dataPicker.to);

		if (dateFrom.length !== 0 || dateTo.length !== 0) {
			const formatDateFrom = new Date(dateFrom.getTime() - dateFrom.getTimezoneOffset() * 60000).toISOString().split('T')[0] + 'T00:00:00Z';
			const formatDateTo = new Date(dateTo.getTime() - dateTo.getTimezoneOffset() * 60000).toISOString().split('T')[0] + 'T23:59:59Z';

			dataBody = {
				department: PERMISSIONS.grocery,
				start_date: formatDateFrom,
				end_date: formatDateTo,
			};
		} else {
			dataBody = {
				department: PERMISSIONS.grocery,
				start_date: 'null',
				end_date: 'null',
			};
		}

		if (!!userId) {
			try {
				setLoading(true);
				axios
					.post(`${SERVER_URL}/${AXIOS_API_CALL.logs}/${currentPage}/${limitPage}/${search || null}`, { ...dataBody }, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } })
					.then((res) => {
						setList(res.data);
						setTimeout(() => {
							setLoading(false);
						}, 700);
					})
					.catch(setError);
			} catch (err) {
				console.error(err);
				setLoading(false);
			}
		}
	}, [currentPage, limitPage, search, refetch, dataPicker, user]);

	// UPDATE - TOGGLE MODAL
	const handleToggle = (props) => {
		const { name, data } = props;

		switch (name) {
			case 'download':
				setListDownloadId({ id: data });
				break;
			default:
				console.error('handleToggle ---- error');
		}
	};

	const [downloadFileUrl, setDownloadFileUrl] = useState({ url: '' });

	// Get download file url
	const getLogsCreateFile = useCallback(async () => {
		const { token } = user;

		const id = listDownloadId.id;

		if (id && id !== '') {
			try {
				await axios
					.post(`${SERVER_URL}/${AXIOS_API_CALL.logsCreateFile}`, { id: id, department: PERMISSIONS.grocery }, { withCredentials: false, headers: { 'Access-Control-Allow-Origin': '*', Authorization: `Bearer ${token}` } })
					.then((res) => {
						if (res.status === 200) {
							try {
								axios
									.get(`${SERVER_URL}/${AXIOS_API_CALL.logsDownload}`, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } })
									.then((res) => {
										if (res.status === 200) {
											const url = window.URL.createObjectURL(new Blob([res.data]));
											const link = document.createElement('a');
											link.href = url;
											link.setAttribute('download', `activity-log-name.txt`);
											document.body.appendChild(link);
											link.click();
											link.parentNode.removeChild(link);
										}
									})
									.catch((err) => console.error(err))
									.finally(() => {});
							} catch (err) {
								console.error(err);
							}
						}
					})
					.catch((err) => {
						console.error(err);
					})
					.finally(() => {});
			} catch (err) {}
		}
	}, [user, listDownloadId]);

	useEffect(() => {
		getLogsCreateFile();
	}, [getLogsCreateFile]);

	const onRangeChange = (dates) => {
		if (dates) {
			setDatePicker({
				from: dates[0]._d,
				to: dates[1]._d,
			});
		} else {
			setDatePicker({
				from: '',
				to: '',
			});
		}
	};

	// TABLE
	const tableActionsProps = {
		error: error,
		handleToggle: handleToggle,
	};

	// PAGINATION
	const paginationProps = {
		current: currentPage,
		setCurrentPage: setCurrentPage,
		limitPage: limitPage,
		buttonLimit: 3,
		pagesCount: list.data?.pagesCount,
		totalCount: list.data?.totalCount,
	};

	return (
		<>
			<h2 className="page-title">Activity Log</h2>
			<Section className="section__wrapper section__activity-log">
				<header className="section__header">
					{!loading ? (
						<div className="filter">
							<span style={{ color: '#333', fontWeight: '500' }}>Show:</span>
							<SelectCustom options={options} label={'Items'} limit={limitPage} setLimit={setLimitPage} setRefetch={setRefetch} loading={loading} />
						</div>
					) : (
						<LoadingPlaceholder style={{ width: '155.5px', height: '50px' }} />
					)}

					<div className="filter filter-date">
						<span style={{ color: '#333', fontWeight: '500' }}>Date:</span>
						<div className="date-picker">
							<Space direction="vertical">
								<RangePicker onChange={onRangeChange} data-cy="date-picker" />
							</Space>
						</div>
						{loading && <LoadingPlaceholder style={{ width: '350.5px', height: '50px' }} />}
					</div>

					{!loading ? (
						<div className="search">
							<form className="search-form" onSubmit={(e) => handleOnSubmitSearch(e)} data-cy="search-roles">
								<div className="form-group m-0">
									<span className="icon icon-search">
										<img src="/assets/icons/search.svg" alt="Search" title="Search" data-cy="search-input-field" />
									</span>
									<input value={searchForm.query || ''} type="text" className="input" placeholder="Search" onChange={(e) => handleOnChangeSearch(e.target.value)} />
									<span className={`icon icon-close ${!!searchForm.query && searchForm.query.length > 0 ? 'isActive' : ''}`} onClick={() => handleOnClearSearch()}>
										<img src="/assets/icons/times.svg" alt="Clear" title="Clear" />
									</span>
								</div>
								<button className="form-submit" data-cy="search-submit-btn" disabled={!searchForm.query || searchForm.query.length === 0}>
									Search
								</button>
							</form>
						</div>
					) : (
						<LoadingPlaceholder style={{ width: '100%', maxWidth: '850px', height: '50px' }} />
					)}
				</header>

				<Main className="section__content relative min-h-table-content">{loading ? <Loading /> : <Table data={list.data?.Data} {...tableActionsProps} pagination={paginationProps} />}</Main>
			</Section>
		</>
	);
};

export default Activity;
