import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../../context/useAuth';
import { SERVER_URL } from '../../../config/index';
import { Select, Button, notification } from 'antd';
import Table from './Table';
import SelectCustom from '../../../components/customSelect/CustomSelect';
import Loading from '../../../components/loading/Loading';
import LoadingPlaceholder from '../../../components/loadingPlaceholder/LoadingPlaceholder';
import { Main, Section } from '../../../components/content';
import { notificationMessages } from '../../../helpers/notificationMessages';
import { jwtDecode } from 'jwt-decode';
import { getGroceryPermissions } from '../../../middleware';
import { AXIOS_API_CALL } from '../../../utils/endpoint';
import { PERMISSIONS } from '../../../utils/permissions';
import { CreateForm } from './create-form/CreateForm';
import { UpdateFormAllSettings } from './update-form/update-form-all-settings/UpdateFormAllSettings';
import { UpdateEmployeeStatusSingleChanges } from './update-form/update-employee-status-single-changes/UpdateEmployeeStatusSingleChanges';
import { UpdateEmployeeStatusMultiChanges } from './update-form/update-employee-status-multi-changes/UpdateEmployeeStatusMultiChanges';
import { DeleteForm } from './delete-form/DeleteForm';

const options = [5, 10, 20, 50, 100];
const { Option } = Select;

const Employees = () => {
	const { user } = useAuth();
	const location = useLocation();
	const [groceryPermissions, setGroceryPermissions] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [limitPage, setLimitPage] = useState(10);
	const [list, setList] = useState([]);
	const [refetch, setRefetch] = useState(false);
	const [loading, setLoading] = useState(true);
	const [fetchedData, setFetchedData] = useState([]);

	// GET USER TOKEN
	useEffect(() => {
		const { token } = user || {};
		if (token) {
			const decodedToken = jwtDecode(token);
			const permissions = decodedToken?.roleData?.permissions;
			if (permissions && location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`)) {
				if (Object.keys(permissions).some((permission) => permission.includes('grocery'))) {
					setGroceryPermissions(permissions.grocery || []);
				}
			}
		}
	}, [user, location]);

	// LOADER PROTECTION
	const [createBtnLoader, setCreateBtnLoader] = useState(false);
	const [editBtnLoader, setEditBtnLoader] = useState(false);
	const [deactivateBtnLoader, setDeactivateBtnLoader] = useState(false);
	const [deactivateBlkBtnLoader, setDeactivateBlkBtnLoader] = useState(false);
	const [deleteBtnLoader, setDeleteBtnLoader] = useState(false);

	// SEARCH
	const [search, setSearch] = useState(null);
	const [searchForm, setSearchForm] = useState({ query: '' });
	const [filterStatus, setFilterStatus] = useState(null);

	const handleOnSubmitSearch = (e) => {
		e.preventDefault();
		setSearch(searchForm.query);
		setRefetch((prevState) => !prevState);
	};

	const handleOnChangeSearch = (value) => {
		setSearchForm({ query: value });
	};

	const handleOnClearSearch = () => {
		setSearch(null);
		setSearchForm({ query: '' });
		setRefetch((prevState) => !prevState);
	};

	const getTableData = useCallback(async () => {
		const { token } = user;
		setLoading(true);
		try {
			await axios
				.get(
					`${SERVER_URL}/${AXIOS_API_CALL.usersGetEmployee}/${currentPage}/${limitPage}/${search || null}/${filterStatus || null}`,

					{
						withCredentials: false,
						headers: { Authorization: `Bearer ${token}` },
					}
				)
				.then((res) => {
					setList(res?.data.data);
					setTimeout(() => {
						setLoading(false);
					}, 700);
				})
				.catch((err) => {
					setLoading(false);
					console.error(err);
				});
		} catch (err) {
			console.error(err);
			setLoading(false);
		}
	}, [user, currentPage, limitPage, search, filterStatus]);

	// FETCH DATA FOR `TABLE`
	useEffect(() => {
		getTableData();
	}, [getTableData, refetch]);

	useEffect(() => {
		if (!!search) {
			setCurrentPage(1);
		}
	}, [search]);

	useEffect(() => {
		setCurrentPage(1);
	}, [limitPage]);

	// FILTER STATUS
	const handleStatusFilter = (value) => {
		setFilterStatus(value);
		setRefetch((prevState) => !prevState);
	};

	// Button (deactive, delete...) in section header - change status if checked one or more to the Enable button
	const [disabledButton, setDisabledButton] = useState(false);
	const handleDisabledButton = (status) => {
		setDisabledButton(status);
	};

	// Get selected rows id one or more
	const [checkedRow, setCheckedRow] = useState([]);
	const handleCheckedRows = (data) => {
		setCheckedRow(data);
	};

	const [employeeUpdateId, setEmployeeUpdateId] = useState('');
	const [employeeTestUpdateId, setEmployeeTestUpdateId] = useState({
		id: '',
	});
	const [employeeDeleteId, setEmployeeDeleteId] = useState('');
	const [employeeStatusSingleId, setEmployeeStatusSingleId] = useState({
		id: '',
		status: '',
	});

	const [employeeStatusMultiId, setEmployeeStatusMultiId] = useState([]);

	const handleToggle = (props) => {
		const { name, state, data } = props;
		switch (name) {
			case 'create':
				state.onToggle((prevState) => !prevState);
				break;
			case 'update':
				setToggleUpdate(true);
				setUpdateEmployee(data);
				setEmployeeUpdateId(data);
				break;
			case 'status':
				setUpdateEmployee(data);
				state.onToggle((prevState) => !prevState);
				break;
			case 'updateStatusMulti':
				setEmployeeStatusMultiId(data);
				state.onToggle((prevState) => !prevState);
				break;
			case 'delete':
				setEmployeeDeleteId(data);
				state.onToggle((prevState) => !prevState);
				break;
			default:
				console.error('handleToggle ---- error');
		}
	};

	// Refs
	const createModalFormRef = useRef(null);
	const updateModalFormRef = useRef(null);
	const deleteModalFormRef = useRef(null);
	const statusModalSingleFormRef = useRef(null);
	const statusModalMultiFormRef = useRef(null);

	// State
	const [toggleCreate, setToggleCreate] = useState(false);
	const [toggleUpdate, setToggleUpdate] = useState(false);
	const [toggleDelete, setToggleDelete] = useState(false);
	const [toggleStatusSingle, setToggleStatusSingle] = useState(false);
	const [toggleStatusMulti, setToggleStatusMulti] = useState(false);

	// Store
	const [createEmployee, setCreateEmployee] = useState([]);
	const [updateEmployee, setUpdateEmployee] = useState([]);
	const [deleteEmployee, setDeleteEmployee] = useState([]);
	const [statusSingleEmployee, setStatusSingleEmoloyee] = useState([]);
	const [statusMultiEmployee, setStatusMultiEmoloyee] = useState([]);
	const [toggleNewPassword, setToggleNewPassword] = useState(false);

	const onChange = (e) => {
		if (e.target.checked) {
			setToggleNewPassword(true);
		} else {
			setToggleNewPassword(false);
		}
	};

	// FETCH ROLES
	const [roles, setRoles] = useState([]);
	useEffect(() => {
		const { token } = user;

		try {
			axios
				.get(
					`${SERVER_URL}/${AXIOS_API_CALL.getRoles}/1/5/null`,

					{
						withCredentials: false,
						headers: { Authorization: `Bearer ${token}` },
					}
				)
				.then((res) => {
					setRoles(res.data?.data.Data);
				})
				.catch((err) => console.error(err))
				.finally(setTimeout(() => {}, 700));
		} catch (err) {
			console.error(err);
		}
	}, [user]);

	const [status, setStatus] = useState([]);

	useEffect(() => {
		setStatus([
			{ name: 'ACTIVE', value: 'ACTIVE' },
			{ name: 'INACTIVE', value: 'INACTIVE' },
		]);
	}, [setStatus]);

	const [loadingEmployee, setLoadingEmployee] = useState(false);

	// FETCH UPDATE DATA
	useEffect(() => {
		const employeeUpdateId = employeeTestUpdateId.id;
		const { token } = user;

		if (!!employeeUpdateId) {
			try {
				setLoadingEmployee(true);
				axios
					.put(
						`${SERVER_URL}/${AXIOS_API_CALL.usersGetEmployee}/${employeeUpdateId}`,
						{ department: PERMISSIONS.grocery },
						{
							withCredentials: false,
							headers: { Authorization: `Bearer ${token}` },
						}
					)
					.then((res) => {
						setUpdateEmployee(res.data);
						setTimeout(() => {
							setLoadingEmployee(false);
						}, 700);
					})
					.catch((err) => {
						setLoadingEmployee(false);
						console.error(err);
					});
			} catch (err) {
				console.error(err);
			}
		}
	}, [user, employeeTestUpdateId, refetch]);

	const handleOnFinish = async (props) => {
		const { name, data } = props;

		const { token } = user;

		switch (name) {
			case 'create':
				setCreateBtnLoader(true);
				try {
					const createData = {
						firstName: data?.firstName || '',
						lastName: data?.lastName || '',
						user_role: data?.role,
						address: [
							{
								address: data?.address || '',
								zip_code: data?.zipCode || '',
								state: data?.state || '',
								city: data?.city || '',
							},
						],
						password: data?.password || '',
						email: data?.email || '',
						userType: 'dashboard',
						department: 'grocery',
					};

					let fetchData = await axios.post(
						`${SERVER_URL}/${AXIOS_API_CALL.createEmployee}`,
						{ ...createData },
						{
							withCredentials: false,
							headers: { Authorization: `Bearer ${token}` },
						}
					);

					if (fetchData.status === 201) {
						setRefetch((prevState) => !prevState);
						setCurrentPage(1);
						setToggleCreate(false);
						setCreateEmployee([]);

						createModalFormRef.current.resetFields();

						// notification
						notification.success({
							message: notificationMessages.successCreateEmployee,
							placement: 'bottomLeft',
						});
					}
					setTimeout(() => {
						setCreateBtnLoader(false);
					}, 700);
				} catch (err) {
					setCreateBtnLoader(false);
					console.error(err);

					notification.error({
						message: notificationMessages.userAlreadyExists,
						placement: 'bottomLeft',
					});
				}
				break;
			case 'update':
				setEditBtnLoader(true);
				try {
					const newPassword = Object.keys(data).includes('newPassword');
					const userId = data?.updateUserId;
					const roleId = roles.find((role) => role.name === data?.role)?._id || '';
					let updateData = [];

					// Date to backend --
					if (!newPassword) {
						updateData = updateData = {
							firstName: data?.firstName || '',
							lastName: data?.lastName || '',
							user_role: roleId || '',
							status: data?.status || '',
							phone: data?.phone || '',
							email: data?.email || '',
							address: [
								{
									address: data?.address || '',
									zip_code: data?.zip_code || '',
									state: data?.state || '',
									city: data?.city || '',
								},
							],
						};
					} else {
						updateData = updateData = {
							firstName: data?.firstName || '',
							lastName: data?.lastName || '',
							user_role: roleId || '',
							status: data?.status || '',
							password: data?.newPassword || '',
							phone: data?.phone || '',
							email: data?.email || '',
							address: [
								{
									address: data?.address || '',
									zip_code: data?.zip_code || '',
									state: data?.state || '',
									city: data?.city || '',
								},
							],
						};
					}
					const employeeUpdateResponse = await axios.put(
						`${SERVER_URL}/${AXIOS_API_CALL.usersUpdateEmoloyee}/${userId}`,
						{ ...updateData },
						{
							withCredentials: false,
							headers: { Authorization: `Bearer ${token}` },
						}
					);
					const isEmailChanged = employeeUpdateResponse.data.message.isEmailChanged;
					if (employeeUpdateResponse.request.status === 200) {
						setTimeout(() => {
							setEditBtnLoader(false);
						}, 700);
					}
					setRefetch((prevState) => !prevState);
					setCurrentPage(1);
					setToggleUpdate(false);
					setUpdateEmployee([]);
					setEmployeeTestUpdateId({ id: '' });
					setTimeout(() => {
						updateModalFormRef.current.resetFields();
					}, 750);

					isEmailChanged
						? notification.success({
								message: notificationMessages.successUpdateEmployeeEmail,
								placement: 'bottomLeft',
						  })
						: notification.success({
								message: notificationMessages.successUpdateEmployee,
								placement: 'bottomLeft',
						  });
				} catch (err) {
					console.error(err);
					setEditBtnLoader(false);
				}
				break;
			case 'status':
				//setDeactivateBtnLoader(true);
				try {
					const userId = data?.statusSingleUserId;
					const roleId = roles.find((role) => role.name === data?.role)?._id || '';
					const userStatusData = {
						firstName: data?.firstName,
						lastName: data?.lastName,
						user_role: roleId,
						password: data?.newPassword,
						phone: data?.phone,
						email: data?.email,
						address: [
							{
								address: data?.address,
								zip_code: data?.zip_code,
								state: data?.state,
								city: data?.city,
							},
						],
						status: data?.status === 'INACTIVE' ? 'ACTIVE' : 'INACTIVE',
					};

					await axios.put(
						`${SERVER_URL}/${AXIOS_API_CALL.usersUpdateEmoloyee}/${userId}`,
						{ ...userStatusData },
						{
							withCredentials: false,
							headers: { Authorization: `Bearer ${token}` },
						}
					);

					setRefetch((prevState) => !prevState);
					setCurrentPage(1);
					setToggleStatusSingle(false);
					setStatusSingleEmoloyee([]);
					setFilterStatus(null);
					setEmployeeStatusSingleId({
						id: '',
						status: '',
					});

					statusModalSingleFormRef.current.resetFields();

					notification.success({
						message: notificationMessages.successUpdateStatusEmployee,
						placement: 'bottomLeft',
					});
					setTimeout(() => {
						setDeactivateBtnLoader(false);
					}, 700);
				} catch (err) {
					console.error(err);
					setDeactivateBtnLoader(false);
				}
				break;
			case 'updateStatusMulti':
				setDeactivateBlkBtnLoader(true);
				try {
					const deactiveMultiData = {
						employees: data?.statusMultiUser,
						department: PERMISSIONS.grocery,
					};

					await axios.post(
						`${SERVER_URL}/${AXIOS_API_CALL.usersBulkDeactivateEmployee}`,
						{ ...deactiveMultiData },
						{
							withCredentials: false,
							headers: { Authorization: `Bearer ${token}` },
						}
					);

					setRefetch((prevState) => !prevState);
					setCurrentPage(1);
					setToggleStatusMulti(false);
					setStatusMultiEmoloyee([]);
					setFilterStatus(null);

					statusModalMultiFormRef.current.resetFields();

					notification.success({
						message: notificationMessages.successUpdateStatusMultiEmployee,
						placement: 'bottomLeft',
					});
					setTimeout(() => {
						setDeactivateBlkBtnLoader(false);
					}, 700);
				} catch (err) {
					console.error(err);
					setDeactivateBlkBtnLoader(false);
				}
				break;
			case 'delete':
				setDeleteBtnLoader(true);
				try {
					const userId = data?.deleteUserId;
					await axios.delete(
						`${SERVER_URL}/${AXIOS_API_CALL.usersGetEmployee}/${userId}`,

						{
							withCredentials: false,
							headers: { Authorization: `Bearer ${token}` },
						}
					);

					setRefetch((prevState) => !prevState);
					setCurrentPage(1);
					setToggleDelete(false);
					setDeleteEmployee([]);
					setEmployeeDeleteId('');

					deleteModalFormRef.current.resetFields();

					notification.success({
						message: notificationMessages.successDeleteEmployee,
						placement: 'bottomLeft',
					});
					setTimeout(() => {
						setDeleteBtnLoader(false);
					}, 700);
				} catch (err) {
					console.error(err);
					notification.error({
						message: notificationMessages.failedDeleteEmployee,
						placement: 'bottomLeft',
					});
					setDeleteBtnLoader(false);
				}
				break;
			default:
				console.error('handleOnFinish ---- error');
		}
	};

	// Create Employee
	const createProps = {
		active: toggleCreate,
		onToggle: setToggleCreate,
		formRef: createModalFormRef,
		data: createEmployee,
		onData: setCreateEmployee,
	};

	// Update Employee
	const updateProps = {
		active: toggleUpdate,
		onToggle: setToggleUpdate,
		formRef: updateModalFormRef,
		data: updateEmployee,
		onData: setUpdateEmployee,
		loading: loadingEmployee,
		status: status,
	};

	// Delete Employee
	const deleteProps = {
		active: toggleDelete,
		onToggle: setToggleDelete,
		formRef: deleteModalFormRef,
		data: deleteEmployee,
		onData: setDeleteEmployee,
	};

	// Status Single Update Employee
	const statusSingleProps = {
		active: toggleStatusSingle,
		onToggle: setToggleStatusSingle,
		formRef: statusModalSingleFormRef,
		data: statusSingleEmployee,
		onData: setStatusSingleEmoloyee,
	};

	// Status Multi Update Employee
	const statusMultiProps = {
		active: toggleStatusMulti,
		onToggle: setToggleStatusMulti,
		formRef: statusModalMultiFormRef,
		data: statusMultiEmployee,
		onData: setStatusMultiEmoloyee,
	};

	// TABLE
	const tableProps = {
		handleDisabledButton: handleDisabledButton,
		handleCheckedRows: handleCheckedRows,
	};

	const tableActionsProps = {
		// custom for this table - always changes for next implementation
		handleToggle: handleToggle,

		updateActions: updateProps,
		deleteActions: deleteProps,
		statusActions: statusSingleProps,

		location: location,
		groceryPermissions: groceryPermissions,
		getGroceryPermissions: getGroceryPermissions,
	};

	const tableFiltersProps = {
		handleStatusFilter: handleStatusFilter,
	};

	// Table Pagination
	const paginationProps = {
		current: currentPage,
		setCurrentPage: setCurrentPage,
		limitPage: limitPage,
		buttonLimit: 3,
		pagesCount: list?.pagesCount,
		totalCount: list?.totalCount,
	};

	const createFormFields = [
		{
			name: ['role'],
			value: '',
		},
		{
			name: ['state'],
			value: '',
		},
		{
			name: 'createUserId',
			value: user.id,
		},
		{
			name: 'createUserType',
			value: 'dashboard',
		},
	];

	const updateFormFields = [
		{
			name: 'role',
			value: updateEmployee?.user_role || '',
		},
		{
			name: 'updateUserId',
			value: updateEmployee._id,
		},
		{
			name: 'firstName',
			value: updateEmployee.firstName,
		},
		{
			name: 'lastName',
			value: updateEmployee.lastName,
		},
		{
			name: 'email',
			value: updateEmployee.email,
		},
		{
			name: 'status',
			value: updateEmployee.status,
		},
		{
			name: ['phone'],
			value: updateEmployee.phone,
		},
		{
			name: 'newPassword',
			value: '',
		},
		{
			name: 'state',
			value: (!!updateEmployee?.address?.[0] && updateEmployee.address?.[0].state) || '',
		},
		{
			name: 'address',
			value: (!!updateEmployee?.address?.[0] && updateEmployee.address?.[0].address) || '',
		},
		{
			name: 'city',
			value: (!!updateEmployee?.address?.[0] && updateEmployee.address?.[0].city) || '',
		},
		{
			name: 'zip_code',
			value: (!!updateEmployee?.address?.[0] && updateEmployee.address?.[0].zip_code) || '',
		},
	];

	const deleteFormFields = [
		{
			name: 'deleteUserId',
			value: employeeDeleteId,
		},
	];

	const statusSingleFormFields = [
		{
			name: 'role',
			value: updateEmployee?.user_role || '',
		},
		{
			name: 'firstName',
			value: updateEmployee.firstName,
		},
		{
			name: 'lastName',
			value: updateEmployee.lastName,
		},
		{
			name: 'email',
			value: updateEmployee.email,
		},
		{
			name: 'status',
			value: updateEmployee.status,
		},
		{
			name: ['phone'],
			value: updateEmployee.phone,
		},
		{
			name: 'newPassword',
			value: '',
		},
		{
			name: 'state',
			value: (!!updateEmployee?.address?.[0] && updateEmployee.address?.[0].state) || '',
		},
		{
			name: 'address',
			value: (!!updateEmployee?.address?.[0] && updateEmployee.address?.[0].address) || '',
		},
		{
			name: 'city',
			value: (!!updateEmployee?.address?.[0] && updateEmployee.address?.[0].city) || '',
		},
		{
			name: 'zip_code',
			value: (!!updateEmployee?.address?.[0] && updateEmployee.address?.[0].zip_code) || '',
		},
		{
			name: 'statusSingleUserId',
			value: updateEmployee._id,
		},
		{
			name: 'status',
			value: updateEmployee.status,
		},
	];

	const statusMultiFormFields = [
		{
			name: 'statusMultiUser',
			value: employeeStatusMultiId,
		},
	];

	const createFormProps = {
		createProps,
		createModalFormRef,
		handleOnFinish,
		createFormFields,
		roles,
		createBtnLoader,
		setToggleCreate,
	};

	const updateFormAllSettingsProps = {
		updateProps,
		loadingEmployee,
		updateModalFormRef,
		updateFormFields,
		onChange,
		toggleNewPassword,
		roles,
		editBtnLoader,
		handleOnFinish,
		setToggleUpdate,
	};

	const updateEmployeeStatusSingleChangesProps = {
		statusSingleProps,
		statusModalSingleFormRef,
		handleOnFinish,
		statusSingleFormFields,
		setToggleStatusSingle,
		deactivateBtnLoader,
	};

	const updateEmployeeStatusMultiChangesProps = {
		statusMultiProps,
		statusModalMultiFormRef,
		handleOnFinish,
		statusMultiFormFields,
		deactivateBlkBtnLoader,
		setToggleStatusMulti,
	};

	const deleteFormProps = {
		deleteProps,
		deleteModalFormRef,
		handleOnFinish,
		deleteFormFields,
		deleteBtnLoader,
		setToggleDelete,
	};

	return (
		<>
			<h2 className="page-title">Admin Users</h2>

			<Section className="section section__wrapper section__employees">
				{/* Main Header */}
				<header className="section__header">
					{!loading ? (
						<div className="filter">
							<span style={{ color: '#333', fontWeight: '500' }}>Show:</span>
							<SelectCustom options={options} label={'Items'} limit={limitPage} setLimit={setLimitPage} setRefetch={setRefetch} loading={loading} />
						</div>
					) : (
						<LoadingPlaceholder style={{ width: '155.5px', height: '50px' }} />
					)}

					{!loading ? (
						<div className="search">
							<form className="search-form" onSubmit={(e) => handleOnSubmitSearch(e)} data-cy="search-roles">
								<div className="form-group m-0">
									<span className="icon icon-search">
										<img src="/assets/icons/search.svg" alt="Search" title="Search" data-cy="search-input-field" />
									</span>
									<input value={searchForm.query || ''} type="text" className="input" placeholder="Search" onChange={(e) => handleOnChangeSearch(e.target.value)} />
									<span className={`icon icon-close ${!!searchForm.query && searchForm.query.length > 0 ? 'isActive' : ''}`} onClick={() => handleOnClearSearch()}>
										<img src="/assets/icons/times.svg" alt="Clear" title="Clear" />
									</span>
								</div>
								<button className="form-submit" data-cy="search-submit-btn" disabled={!searchForm.query || searchForm.query.length === 0}>
									Search
								</button>
							</form>
						</div>
					) : (
						<LoadingPlaceholder style={{ width: '100%', maxWidth: '850px', height: '50px' }} />
					)}

					<div className="actions">
						{getGroceryPermissions(groceryPermissions, PERMISSIONS.createEmployees) && (
							<>
								{!loading ? (
									<Button type="button" htmlType="button" onClick={() => handleToggle({ name: 'create', state: createProps })} data-cy="create-employee-btn" className="btn btn-primary pl-4 pr-4">
										<span className="text">Create Employee</span>
									</Button>
								) : (
									<LoadingPlaceholder style={{ width: '180.5px', height: '50px' }} />
								)}
							</>
						)}

						{/* {getGroceryPermissions(groceryPermissions, PERMISSIONS.updateEmployees) && (
              <>
                {!loading ? (
                  <Button
                    type="button"
                    htmlType="button"
                    onClick={() =>
                      handleToggle({
                        name: 'updateStatusMulti',
                        state: statusMultiProps,
                        data: checkedRow
                      })
                    }
                    data-cy="bulk-deactive-employees-btn"
                    className="btn btn-primary-outline pl-4 pr-4"
                    disabled={disabledButton ? false : true}
                  >
                    <span className="text">Deactivate Employee</span>
                  </Button>
                ) : (
                  <LoadingPlaceholder style={{ width: '207px', height: '50px' }} />
                )}
              </>
            )} */}
					</div>
				</header>

				{/* Main Content */}
				<Main className="section__content relative min-h-table-content">{loading ? <Loading /> : <Table data={list?.Data} {...tableProps} {...tableActionsProps} {...tableFiltersProps} pagination={paginationProps} />}</Main>

				{/* Update Employee - all settings */}
				<UpdateFormAllSettings {...updateFormAllSettingsProps} />

				<CreateForm {...createFormProps} />

				<UpdateEmployeeStatusSingleChanges {...updateEmployeeStatusSingleChangesProps} />

				{/* Delete Employee - delete */}
				<DeleteForm {...deleteFormProps} />
			</Section>
		</>
	);
};

export default Employees;
