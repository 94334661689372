import React, { useState } from 'react';
import Modal from '../../../../components/modal/Modal';
import { Form } from 'antd';

export const ProductBulk = ({ productBulk, onSubmit, setViewProductBulk }) => {
	const [skuForms, setSkuForms] = useState([{ sku: '', goat: false, stockx: false }]);

	const handleInputChange = (index, field, value) => {
		const updatedForms = [...skuForms];
		updatedForms[index][field] = value;
		setSkuForms(updatedForms);
	};

	const addNewForm = () => {
		setSkuForms([...skuForms, { sku: '', goat: false, stockx: false }]);
	};

	const handleFormSubmit = (event) => {
		console.log(skuForms);
		event.preventDefault();
		onSubmit({ event: event, name: 'productBulk', data: skuForms });
		setTimeout(() => {
			if (skuForms.every((form) => form.goat)) {
				setSkuForms([{ sku: '', goat: false, stockx: false }]);
			}
		}, 2000);
	};

	const handleCancel = () => {
		setSkuForms([{ sku: '', goat: false, stockx: false }]);
		setViewProductBulk(false);
	};

	return (
		<Modal {...productBulk} className="sidebar__modal--center product-bulk" title="Add Products Auto">
			<form name="add-sku" onSubmit={(event) => handleFormSubmit(event)}>
				{skuForms.map((form, index) => (
					<div key={index} className="form-group form-group-modal mb-4">
						<div className="form-input">
							<label>SKU</label>
							<input type="text" className="form-control" value={form.sku} onChange={(e) => handleInputChange(index, 'sku', e.target.value)} required />
						</div>

						<div className="form-check">
							<input type="checkbox" className="form-check-input" checked={form.goat} onChange={(e) => handleInputChange(index, 'goat', e.target.checked)} />
							<label className="form-check-label">Goat</label>
						</div>

						<div className="form-check">
							<input type="checkbox" className="form-check-input" checked={form.stockx} onChange={(e) => handleInputChange(index, 'stockx', false)} />
							<label className="form-check-label">StockX</label>
						</div>
					</div>
				))}

				<button type="button" className="btn btn-add" onClick={addNewForm}>
					+
				</button>

				<div className="form-group form-group-modal">
					<button type="submit" className="btn btn-primary-link">
						<span className="text">Submit</span>
					</button>

					<button type="button" className="btn btn-primary" onClick={handleCancel}>
						<span className="text">Cancel</span>
					</button>
				</div>
			</form>
		</Modal>
	);
};
