import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Popover } from 'antd';
import Checkbox from '../../../components/customCheckbox/CustomCheckbox';
import { PERMISSIONS } from '../../../utils/permissions';
import Message from '../../../components/message/Message';
import { contentMessage } from '../../../helpers/contentMessage';
import Pagination from '../../../components/pagination/Pagination';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

const Table = (props) => {
	// TABLE
	const { data, handleCheckedRows, handleDisabledButton, handleToggle } = props;
	// PAGINATION
	const { pagination } = props;
	// CUSTOM FOR ACTIONS
	const { deleteActions, groceryPermissions, getGroceryPermissions } = props;



	
	
	

	return (
		<>
			<table className="table">
				<thead className="thead">
					<tr>
						<th style={{ textAlign: 'left' }}>Title</th>
						<th>Version</th>
						<th>Created At</th>
						<th>Action</th>
					</tr>
				</thead>
				<tbody className="tbody">
					{data && data.length > 0 ? (
						data.map((item, idx) => (
							<tr key={item._id}>
								<td style={{ textAlign: 'left' }}>{item.description}</td>
								<td>{item.version.toFixed(1)}</td>
								<td>{item.createdAt}</td>

								<td>
									{Object.values(groceryPermissions).some((key) => [PERMISSIONS.updateProducts, PERMISSIONS.deleteProducts, PERMISSIONS.readProducts].includes(key.name)) && (
										<Popover
											className="tbody-actions"
											placement="left"
											trigger="click"
											content={() => (
												<div className="actions" data-cy="actions-popover-products">
													{getGroceryPermissions(groceryPermissions, PERMISSIONS.updateProducts) && (
														<Link to={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/legal-documents/${item._id}`} className="link btn-actions link-details">
															<span className="icon d-flex">
																<img alt="EDIT" src="/assets/icons/eye.svg" style={{ display: 'block' }} />
															</span>
															<span className="text">VIEW</span>
														</Link>
													)}
													{getGroceryPermissions(groceryPermissions, PERMISSIONS.deleteProducts) && (
														<button className="btn btn-actions" type="button" data-cy="actions-delete-product" onClick={() => handleToggle({ name: 'delete', state: deleteActions, data: item._id })}>
															<span className="icon">
																<img alt="Delete" src="/assets/icons/delete.svg" />
															</span>
															<span className="text">
																<strong>DELETE</strong>
															</span>
														</button>
													)}
												</div>
											)}
										>
											<button className="btn" type="button" data-cy="actions-toggle-products">
												<span className="icon">
													<img className="d-block pl-1 pr-1" alt="Dots" src="/assets/icons/dots-y.svg" />
												</span>
											</button>
										</Popover>
									)}
								</td>
							</tr>
						))
					) : (
						<tr>
							<td colSpan={20} style={{ width: '100%' }}>
								<Message className="content-message">
									<h2 style={{ color: '#666' }}>{contentMessage.dataNotFound}</h2>
								</Message>
							</td>
						</tr>
					)}
				</tbody>
			</table>

			{data && data.length > 0 && <Pagination {...pagination} />}
		</>
	);
};

export default Table;
