import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { useAuth } from '../../../context/useAuth';
import { SERVER_URL } from '../../../config/index';
import Loading from '../../../components/loading/Loading';
import { Section, Main } from '../../../components/content';
import { PERMISSIONS } from '../../../utils/permissions';
import { AXIOS_API_CALL } from '../../../utils/endpoint';
import { ProductProvider } from '../../../context/products';
import Table from './Table';
import { getGroceryPermissions } from '../../../middleware';
import { notification } from 'antd';
import LoadingPlaceholder from '../../../components/loadingPlaceholder/LoadingPlaceholder';
import ViewError from './viewError/ViewError';

const UserErrorTracking = () => {
	const { user } = useAuth();
	const navigate = useNavigate();
	const location = useLocation();
	const [groceryPermissions, setGroceryPermissions] = useState([]);

	// GET USER TOKEN
	useEffect(() => {
		const { token } = user || {};
		if (token) {
			const decodedToken = jwtDecode(token);
			const permissions = decodedToken?.roleData?.permissions;
			console.log(permissions);
			if (permissions && location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`)) {
				if (Object.keys(permissions).some((permission) => permission.includes('grocery'))) {
					setGroceryPermissions(permissions.grocery || []);
				}
			}
		}
	}, [user, location]);

	// SEARCH
	const [search, setSearch] = useState(null);
	const [searchForm, setSearchForm] = useState({ query: '' });
	const [refetch, setRefetch] = useState(false);

	const [toggle, setToggle] = useState(false);
	const [fullData, setFullData] = useState([]);

	// READ
	const [readData, setReadData] = useState([]);
	const [readDataRefetch, setReadDataRefetch] = useState(false);
	const [readDataLoading, setReadDataLoading] = useState(true);
	const [currentPage, setCurrentPage] = useState(1);
	const [limitPage, setLimitPage] = useState(10);
	const [filterStatus, setFilterStatus] = useState(null);
	const getReadData = useCallback(async () => {
		const { token } = user;

		const payload = {
			department: PERMISSIONS.grocery,
			searchTerm: search,
		};

		try {
      const response = await axios
        .get(`${SERVER_URL}/${AXIOS_API_CALL.errorLogs}/${currentPage}/${limitPage}`, {
          params: {
            searchTerm: search ? encodeURIComponent(search) : null,
          },
          withCredentials: false,
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          console.log('Response data:', res.data);
          return res;
        })
        .catch((err) => console.error(err))
        .finally(
          setTimeout(() => {
            setReadDataLoading(false);
          }, 200)
        );
			return response;
		} catch (err) {
			console.error('Error fetching data:', err);
		}
	}, [user, limitPage, search, currentPage, filterStatus]);

	useEffect(() => {
		let isMounted = true;

		new Promise((resolve, reject) => {
			setReadDataLoading(true);
			setTimeout(() => {
				resolve(getReadData());
			}, 700);
		}).then((res) => {
			if (isMounted) {
				setReadData(res?.data);
			}
		});

		return () => {
			isMounted = false;
		};
	}, [getReadData, readDataRefetch]);

	const [imageFile, setImageFile] = useState([]);

	const [bannerData, setBannerData] = useState({});
	const [bannerDeleteId, setBannerDeleteId] = useState('');
	const deleteModalFormRef = useRef(null);

	const [bannerUpdateId, setBannerUpdateId] = useState('');
	const updateModalFormRef = useRef(null);
	const [toggleUpdate, setToggleUpdate] = useState(false);

	const [shoeSearch, getShoeSearch] = useState('');

	const [toggleView, setToggleView] = useState(false);
	const [viewLoading, setViewLoading] = useState(false);
	const [viewData, setViewData] = useState({});

	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		const page = params.get('page');
		if (page) {
			setCurrentPage(parseInt(page, 10));
		}
	}, []);

	console.log('fullData', fullData);

	useEffect(() => {
		// Save page number to localStorage
		if (fullData?.data?.pageNumber !== undefined && fullData?.data?.pageNumber !== null) {
			console.log(fullData?.data?.pageNumber);
			localStorage.setItem('currentPage', fullData?.data?.pageNumber);
		}
	}, [currentPage, fullData]);

	useEffect(() => {
		navigate(`?page=${currentPage}`);
	}, [currentPage, navigate]);

	const handleToggle = (props) => {
		const { name, state, data } = props;

		switch (name) {
			case 'view':
				setViewData(data);
				state.onToggle((prevState) => !prevState);
				break;
			default:
				console.error('handleToggle ---- error');
		}
	};

	useEffect(() => {
		console.log('readData', readData);
	}, [readData]);

	const [toggleDelete, setToggleDelete] = useState(false);

	const handleOnChangeSearch = (value) => {
		setSearchForm({ query: value });
	};

	const handleOnSubmitSearch = (e) => {
		e.preventDefault();
		setSearch(searchForm.query);
		setReadDataRefetch((prevState) => !prevState);
		console.log(search);
	};
	const handleOnClearSearch = () => {
		setSearch(null);
		setSearchForm({ query: '' });
		setReadDataRefetch((prevState) => !prevState);
	};
	const handleClose = () => {
		setToggleView(false);
	};
	const handleStatusFilter = (value) => {
		setFilterStatus(value);
		setReadDataRefetch((prevState) => !prevState);
	};

	const deleteProps = {
		active: toggleDelete,
		onToggle: setToggleDelete,
		formRef: deleteModalFormRef,
	};
	const viewProps = {
		active: toggleView,
		onToggle: setToggleView,
		loading: viewLoading,
		data: viewData,
	};
	const viewErrorProps = {
		viewProps: viewProps,
		viewLoading: viewLoading,
		viewData: viewData,
		handleClose,
	};

	const tableActionsProps = {
		location: location,
		handleToggle: handleToggle,
		groceryPermissions: groceryPermissions,
		getGroceryPermissions: getGroceryPermissions,
		setGroceryPermissions: setGroceryPermissions,
		deleteActions: deleteProps,
		viewActions: viewProps,
	};

	const tableFiltersProps = {
		handleStatusFilter: handleStatusFilter,
	};

	// PAGINATION
	const paginationProps = {
		current: currentPage,
		setCurrentPage: setCurrentPage,
		limitPage: limitPage,
		buttonLimit: 3,
		pagesCount: readData?.data?.pagesCount,
		totalCount: readData?.data?.totalCount,
	};

	return (
		<>
			<h2 className="page-title">User Error Tracking</h2>

			<Section className="section__wrapper section__gallery">
				<header className="section__header">
					<div className="search">
						{!readDataLoading ? (
							<div className="search">
								<form className="search-form" onSubmit={(e) => handleOnSubmitSearch(e)} data-cy="search-roles">
									<div className="form-group m-0">
										<span className="icon icon-search">
											<img src="/assets/icons/search.svg" alt="Search" title="Search" data-cy="search-input-field" />
										</span>
										<input value={searchForm.query || ''} type="text" className="input" placeholder="Search" onChange={(e) => handleOnChangeSearch(e.target.value)} />
										<span className={`icon icon-close ${!!searchForm.query && searchForm.query.length > 0 ? 'isActive' : ''}`} onClick={() => handleOnClearSearch()}>
											<img src="/assets/icons/times.svg" alt="Clear" title="Clear" />
										</span>
									</div>
									<button className="form-submit" data-cy="search-submit-btn" disabled={!searchForm.query || searchForm.query.length === 0}>
										Search
									</button>
								</form>
							</div>
						) : (
							<LoadingPlaceholder style={{ width: '100%', maxWidth: '850px', height: '50px' }} />
						)}
					</div>

					<div className="actions"></div>
				</header>

				<Main className="section__content relative min-h-table-content">
					{readDataLoading ? (
						<Loading />
					) : (
						<>
							<Table data={readData?.data?.Data} {...tableActionsProps} {...tableFiltersProps} pagination={paginationProps} />
						</>
					)}
				</Main>
				<ViewError {...viewErrorProps} />
			</Section>
		</>
	);
};

export default UserErrorTracking;
