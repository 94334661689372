import React from 'react';
import { Menu, Dropdown } from 'antd';

const menuData = [
	{
		key: 'PENDING_ADMIN_APPROVAL',
		value: 'PENDING_ADMIN_APPROVAL',
		label: 'Pending Admin Approval',
	},
	{
		key: 'PENDING_CONFIRMATION',
		value: 'PENDING_CONFIRMATION',
		label: 'Pending Confirmation',
	},
	{
		key: 'PENDING_VERIFICATION',
		value: 'PENDING_VERIFICATION',
		label: 'Pending Verification',
	},
	{
		key: 'SENT_ON_VERIFICATION',
		value: 'SENT_ON_VERIFICATION',
		label: 'Sent on Verification',
	},
	{
		key: 'PENDING_SHIPMENT_TO_ADMIN',
		value: 'PENDING_SHIPMENT_TO_ADMIN',
		label: 'Pending Shipment to Admin (Seller Confirmed)',
	},
	{
		key: 'PENDING_SHIPMENT_TO_BUYER',
		value: 'PENDING_SHIPMENT_TO_BUYER',
		label: 'Pending Shipment to Buyer (Seller Confirmed)',
	},
	{
		key: 'PENDING_SHIPMENT_TO_SELLER',
		value: 'PENDING_SHIPMENT_TO_SELLER',
		label: 'Pending Shipment to Seller',
	},
	{
		key: 'IN_TRANSIT_TO_ADMIN',
		value: 'IN_TRANSIT_TO_ADMIN',
		label: 'In Transit to Admin (Seller Shipped to Admin)',
	},
	{
		key: 'IN_TRANSIT_TO_BUYER',
		value: 'IN_TRANSIT_TO_BUYER',
		label: 'In Transit to Buyer (Admin Shipped to Buyer, Seller Shipped to Buyer)',
	},
	{
		key: 'IN_TRANSIT_TO_SELLER',
		value: 'IN_TRANSIT_TO_SELLER',
		label: 'In Transit to Seller',
	},
	{
		key: 'CANCELED',
		value: 'CANCELED',
		label: 'Canceled',
	},
	{
		key: 'REJECTED',
		value: 'REJECTED',
		label: 'Rejected',
	},
	{
		key: 'RETURNED_TO_SELLER',
		value: 'RETURNED_TO_SELLER',
		label: 'Returned to Seller',
	},
	{
		key: 'RETURNED_TO_ADMIN',
		value: 'RETURNED_TO_ADMIN',
		label: 'Returned to Admin',
	},
	{
		key: 'DELIVERED_FOR_AUTHENTICATION',
		value: 'DELIVERED_FOR_AUTHENTICATION',
		label: 'Delivered for Authentification (Seller Delivered to Admin)',
	},
	{
		key: 'DELIVERED',
		value: 'DELIVERED',
		label: 'Delivered  (Seller Delivered to Buyer, Admin Delivered to Buyer)',
	},
	{
		key: 'COMPLETED',
		value: 'COMPLETED',
		label: 'Completed',
	},
	{
		key: 'PAYMENT_FAILED',
		value: 'PAYMENT_FAILED',
		label: 'Payment Failed',
	},
	{
		key: 'REFUNDED',
		value: 'REFUNDED',
		label: 'Refunded',
	},
];

const OrderStatusDropdown = ({ orderStatus, handleStatusChange }) => {
	const menu = (
		<Menu>
			{menuData.map((item) => (
				<Menu.Item key={item.key}>
					<li className={`${orderStatus === item.value ? 'selected-order-status-item' : ''} status-dropdown-item`} onClick={() => handleStatusChange(item.value)}>
						{item.label}
					</li>
				</Menu.Item>
			))}
		</Menu>
	);

	return (
		<Dropdown overlay={menu} trigger={['click']}>
			<button data-cy="change-status-btn" type="button" className="ant-btn btn btn-primary-link btn-block btn-cancel-order">
				<span className="text">Change Status</span>
			</button>
		</Dropdown>
	);
};

export default OrderStatusDropdown;
