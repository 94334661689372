import React from 'react';
import { Select, Input, Button, Form, Row, Col } from 'antd';
import SidebarModal from '../../../../components/sidebarModal/SidebarModal';
import { stateUS } from '../../../../helpers/state';

const { Option } = Select;

export const CreateForm = ({ createProps, createModalFormRef, handleOnFinish, createFormFields, roles, createBtnLoader, setToggleCreate }) => {
	return (
		<SidebarModal {...createProps} className="sidebar__modal--right" title="Create Employee">
			<Form name="create" ref={createModalFormRef} wrapperCol={{ span: 24 }} initialValues={{ remember: false }} onFinish={(data) => handleOnFinish({ name: 'create', data: data })} fields={createFormFields}>
				<Form.Item name="firstName" label="First Name:" rules={[{ required: true, message: 'Please input your First Name!' }]}>
					<Input placeholder="First Name:" />
				</Form.Item>
				<Form.Item name="lastName" label="Last Name:" rules={[{ required: true, message: 'Please input your Last Name!' }]}>
					<Input placeholder="Last Name:" />
				</Form.Item>
				<Form.Item
					name="email"
					label="Email:"
					rules={[
						{ required: true, message: 'Please input your email!' },
						{
							pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
							message: 'Invalid email address',
						},
					]}
				>
					<Input placeholder="Email:" type="email" />
				</Form.Item>
				<Form.Item name="state" label="Select State:" rules={[{ required: false, message: 'Please input your state!' }]}>
					<Select>
						<Option key={''}>Select State</Option>

						{stateUS.map((item) => {
							return (
								<Option key={item.name} value={item.abbreviation}>
									{item.name}
								</Option>
							);
						})}
					</Select>
				</Form.Item>
				<Form.Item name="city" label="City:" rules={[{ required: false, message: 'Please input your city!' }]}>
					<Input placeholder="City:" />
				</Form.Item>
				<Form.Item name="address" label="Address:" rules={[{ required: false, message: 'Please input your address!' }]}>
					<Input placeholder="Address:" />
				</Form.Item>
				<Form.Item name="zipCode" label="Zip Code:" rules={[{ required: false, message: 'Please input your zip code!' }]}>
					<Input placeholder="Zip Code:" />
				</Form.Item>
				<Form.Item name="phone" label="Phone:" rules={[{ required: false, message: 'Please input your phone!' }]}>
					<Input placeholder="Phone:" />
				</Form.Item>
				<Form.Item name="password" label="Password:" rules={[{ required: true, message: 'Please input your password!' }]}>
					<Input.Password placeholder="Password:" />
				</Form.Item>
				<Form.Item name="role" label="Select Roles:" rules={[{ required: true, message: 'Please input your role!' }]}>
					<Select>
						<Option key={''}>Select Roles</Option>

						{roles.map((item) => {
							return (
								<Option key={item.name} value={item._id}>
									{item.name}
								</Option>
							);
						})}
					</Select>
				</Form.Item>
				<Form.Item name="createUserId" style={{ display: 'none' }}>
					<Input type="hidden" />
				</Form.Item>
				<Form.Item name="createUserType" style={{ display: 'none' }}>
					<Input type="hidden" />
				</Form.Item>
				<Form.Item wrapperCol={{ offset: 8 }} className="mt-4 mb-0">
					<Row gutter={16}>
						<Col span={12}>
							<Button type="button" htmlType="button" className="btn btn-primary-link btn-block" onClick={() => setToggleCreate(false)}>
								<span>Cancel</span>
							</Button>
						</Col>
						<Col span={12}>
							{!createBtnLoader ? (
								<Button type="submit" htmlType="submit" className="btn btn-primary btn-block">
									<span>Add User</span>
								</Button>
							) : (
								<Button type="button" className="btn btn-primary-outline btn-block">
									<span>Adding...</span>
								</Button>
							)}
						</Col>
					</Row>
				</Form.Item>
			</Form>
		</SidebarModal>
	);
};
