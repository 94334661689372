import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { Link, useLocation, useParams,useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../context/useAuth';
import { SERVER_URL } from '../../../../config';
import { AXIOS_API_CALL } from '../../../../utils/endpoint';
import { PERMISSIONS } from '../../../../utils/permissions';

const ViewDocument = () => {
	const { user } = useAuth();
	const location = useLocation();
  const navigate = useNavigate();
	const [groceryPermissions, setGroceryPermissions] = useState([]);
	const [refetch, setRefetch] = useState(false);
	const { documentId } = useParams();

	const [readData, setReadData] = useState([]);
	const [readDataRefetch, setReadDataRefetch] = useState(false);
	const [readDataLoading, setReadDataLoading] = useState(true);

	const [currentPage, setCurrentPage] = useState(1);
	const [limitPage, setLimitPage] = useState(10);

	const [search, setSearch] = useState(null);
	const [searchForm, setSearchForm] = useState({ query: '' });
	const [filterStatus, setFilterStatus] = useState(null);
	console.log(documentId);

	// GET USER TOKEN
	useEffect(() => {
		const { token } = user || {};
		if (token) {
			const decodedToken = jwtDecode(token);
			const permissions = decodedToken?.roleData?.permissions;
			console.log(permissions);
			if (permissions && location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`)) {
				if (Object.keys(permissions).some((permission) => permission.includes('grocery'))) {
					setGroceryPermissions(permissions.grocery || []);
				}
			}
		}
	}, [user, location]);

	const getReadData = useCallback(async () => {
		const { token } = user;

		const payload = {
			department: PERMISSIONS.grocery,
			searchTerm: search,
		};

		try {
			const response = await axios
				.get(`${SERVER_URL}/${AXIOS_API_CALL.getDocuments}/${documentId}`, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } })
				.then((res) => {
					console.log('Response data:', res.data);
					return res;
				})
				.catch((err) => console.error(err))
				.finally(
					setTimeout(() => {
						setReadDataLoading(false);
					}, 200)
				);
			return response;
		} catch (err) {
			console.error('Error fetching data:', err);
		}
	}, [user, limitPage, search, currentPage, filterStatus]);

	useEffect(() => {
		let isMounted = true;

		new Promise((resolve, reject) => {
			setReadDataLoading(true);
			setTimeout(() => {
				resolve(getReadData());
			}, 700);
		}).then((res) => {
			if (isMounted) {
				setReadData(res?.data?.legalDocument);
			}
		});

		return () => {
			isMounted = false;
		};
	}, [getReadData, readDataRefetch]);

	const formatDate = (isoString) => {
		const date = new Date(isoString);
		return date.toLocaleString(); // You can customize this forma
	};

  const onClose = useCallback(() => {
		navigate(-1);
	}, [navigate, location]);


	return (
		<main>
			<div className="document">
				<div className="documentActions">
					<h1>Showing {readData.description}</h1>
					<div className="actions">
						<button onClick={onClose} data-cy="modal-close-btn" type="button" className="ant-btn btn btn-primary-link btn-block btn-back">
							<span className="text">Go Back</span>
						</button>
						<Link to={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/legal-documents/${readData._id}/edit`} className="btn btn-primary pr-4 pl-4 d-flex items-center align-center">
							<span className="text">
								<strong>Edit {readData.description}</strong>
							</span>
						</Link>
					</div>
				</div>
				<div className="documentHeader">
					<div className="id">
						<span>ID:</span>
						<span>{readData.docId}</span>
					</div>
					<div className="version">
						<span>Version:</span>
						<span>{readData?.version?.toFixed(1)}</span>
					</div>
					<div className="id">
						<span>Created at:</span>
						<span>{formatDate(readData.createdAt)}</span>
					</div>
					<div className="version">
						<span>Updated At:</span>
						<span>{formatDate(readData.updatedAt)}</span>
					</div>
				</div>

				<div className="documentBody">
					<h1>{readData.description}</h1>
					<div className="body" dangerouslySetInnerHTML={{ __html: readData.content }} />
				</div>
			</div>
		</main>
	);
};

export default ViewDocument;
