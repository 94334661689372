import React from 'react';
import Modal from '../../../../components/modal/Modal';
import { Input, Button, Form, Row } from 'antd';

export const RejectForm = ({ rejectProps, rejectModalFormRef, handleOnFinish, rejectFormFields, rejectBtnLoader, setToggleReject }) => {
	return (
		<Modal {...rejectProps} className="sidebar__modal--center" title="">
			<Form name="reject" ref={rejectModalFormRef} wrapperCol={{ span: 24 }} initialValues={{ remember: false }} onFinish={(data) => handleOnFinish({ name: 'reject', data: data })} fields={rejectFormFields}>
				<h2 className="text-center mb-4">Are you sure you want to reject the selected item ?</h2>

				<Form.Item name="rejectUserId" style={{ display: 'none' }}>
					<Input type="hidden" />
				</Form.Item>

				<Form.Item>
					<Row gutter={16} style={{ justifyContent: 'center', columnGap: '20px' }}>
						{!rejectBtnLoader ? (
							<Button type="submit" htmlType="submit" className="btn btn-primary-link btn-reject">
								<span>Yes</span>
							</Button>
						) : (
							<Button type="button" className="btn btn-primary-link btn-reject">
								<span>Deleting...</span>
							</Button>
						)}

						<Button type="primary" htmlType="button" className="btn btn-primary btn-reject" onClick={() => setToggleReject(false)}>
							<span>No</span>
						</Button>
					</Row>
				</Form.Item>
			</Form>
		</Modal>
	);
};
