import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Popover } from 'antd';
import Checkbox from '../../../components/customCheckbox/CustomCheckbox';
import { PERMISSIONS } from '../../../utils/permissions';
import Message from '../../../components/message/Message';
import { contentMessage } from '../../../helpers/contentMessage';
import Pagination from '../../../components/pagination/Pagination';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

const Table = (props) => {
	// TABLE
	const { data, handleCheckedRows, handleToggle } = props;
	console.log(data);
	// PAGINATION
	const { pagination } = props;
	// CUSTOM FOR ACTIONS
	const { deleteActions, groceryPermissions, getGroceryPermissions, updateActions, viewActions } = props;

	const [isCheckAll, setIsCheckAll] = useState(false);
	const [isCheck, setIsCheck] = useState([]);

	console.log(pagination);

	// HANDLE CHECKED ALL
	const handleSelectAll = (e) => {
		setIsCheckAll(!isCheckAll);
		setIsCheck(data.map((li) => li._id));
		if (isCheckAll) {
			setIsCheck([]);
		}
	};

	// HANDLE CHECKED CURRENT
	const handleClick = (e) => {
		const { id, checked } = e.target;
		setIsCheckAll(true);
		setIsCheck([...isCheck, id]);
		if (!checked) {
			setIsCheck(isCheck.filter((item) => item !== id));
		}
	};

	// UNTOGGLE CHECKED TOP
	useEffect(() => {
		if (isCheck.length < 1) {
			setIsCheckAll(false);
		}
	}, [isCheck, setIsCheckAll]);

	return (
		<>
			<table className="table">
				<thead className="thead">
					<tr>
						<th>ID</th>
						<th>Phone Number</th>
						<th>Error Type</th>
						<th>Time stamp</th>
						<th>Action</th>
					</tr>
				</thead>
				<tbody className="tbody">
					{data && data.length > 0 ? (
						data.map((item, idx) => (
							<tr key={item._id}>
								<td>{item._id}</td>
								<td>{item.metadata.phoneNumber}</td>
								<td>{item.message}</td>
								<td>{item.timestamp}</td>
								<td>
									<Popover
										className="tbody-actions"
										placement="left"
										trigger="click"
										content={() => (
											<div className="actions" data-cy="actions-popover-brands">
												{getGroceryPermissions(groceryPermissions, PERMISSIONS.updateProducts) && (
													<button className="btn btn-actions" type="button" data-cy="actions-delete-product" onClick={() => handleToggle({ name: 'view', state: viewActions, data: item })}>
														<span className="icon d-flex">
															<img alt="EDIT" src="/assets/icons/eye.svg" style={{ display: 'block' }} />
														</span>
														<span className="text">VIEW</span>
													</button>
												)}
											</div>
										)}
									>
										<button className="btn" type="button" data-cy="actions-toggle-brands">
											<span className="icon">
												<img className="d-block pl-1 pr-1" alt="Dots" src="/assets/icons/dots-y.svg" />
											</span>
										</button>
									</Popover>
								</td>
							</tr>
						))
					) : (
						<tr>
							<td colSpan={20} style={{ width: '100%' }}>
								<Message className="content-message">
									<h2 style={{ color: '#666' }}>{contentMessage.dataNotFound}</h2>
								</Message>
							</td>
						</tr>
					)}
				</tbody>
			</table>

			{data && data.length > 0 && <Pagination {...pagination} />}
		</>
	);
};

export default Table;
