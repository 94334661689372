const AXIOS_API_CALL = {
	// ROLES
	getRole: 'get-role',
	getRoles: 'get-roles',
	createRole: 'create-role',
	updateRole: 'update-role',
	deleteRole: 'delete-role',
	bulkDeleteRole: 'bulk-delete-role',
	updateAskPrice: 'dashboard/asks/update-ask-price',
	deactivateAsk: 'dashboard/asks/deactivate-ask',

	// CUSTOMERS
	getCustomers: 'dashboard/customers',
	getBlockedCustomers: 'dashboard/blocked-customers',
	deleteCustomers: 'dashboard/customers/delete-account',
	updateCustomers: 'dashboard/customers/edit-customer',
	bulkUpdateStatus: 'dashboard/bulk-deactivate-customer',
	createCustomer: 'dashboard/customers/create-customer',

	// EMPLOYEES/Admins
	usersEmployees: 'users',
	usersGetEmployee: 'users/employees',
	createEmployee: 'users/employees',
	usersUpdateEmoloyee: 'users/employees',
	usersBulkDeactivateEmployee: 'users/bulk-deactivate-employee',
	usersDeleteEmployee: 'users/employees',

	// GALLERY
	galleryImages: 'images',
	galleryImagesSearch: 'images?searchTerm=',
	galleryUploadLocalImage: 'upload-local-image',
	updateGalleryImage: 'images-update',
	deleteGalleryImage: 'images-delete',
	uploadLocalImage: 'upload-local-image',
	getImagesById: 'images',
  getBanner: 'images/banners',

  getSearchShoes: 'dashboard/search-shoes-by-name',

	productGalleryImages: 'product-gallery-images',

	// addresses
	updateAddress: 'dashboard/addresses/update-shipping-address',

	//transactions
	transaction: 'dashboard/transaction/get-all',
	transactionReview: 'dashboard/transaction/review-cashout-request',

	//documents
	getDocuments: 'legal-documents',

	// ADDRESSES - CUSTOMERS
	createDashboardAddresses: 'dashboard/addresses/create-address',
	deleteDashboardAddresses: 'dashboard/addresses/delete-address',

	// PRODUCTS
	getProduct: 'dashboard/shoes',
	getProducts: 'dashboard/shoes',
	deleteProduct: 'shoes',
	deleteProductBulk: 'shoes',
	updateProduct: 'dashboard/shoes',
	discountsProducts: 'get-price-drops',
	promosProducts: 'get-trending',
	createProduct: 'shoe-create',

	// VERIFICATION
	getVerifications: 'dashboard/verifications',
	verifyUser: 'dashboard/verifications/verify-user',

	// ASKS

	getAsks: 'dashboard/asks',
	deleteAsks: 'dashboard/delete-ask',

	// Costs

	getCosts: 'costs',
	addCost: 'cost',

	// ORDERS
	getOrders: 'orders',
	updateOrder: 'update-order',
	orderDetails: 'order-details',
	getOrderDetails: 'dashboard/orders',
	verifyOrder: 'orders/verify',
	confirmOrder: 'orders/confirm-order',
	rejectVerification: 'orders/reject-verification',
	authenticateOrder: 'dashboard/orders/authenticate-order',
	rejectOrder: 'dashboard/orders/reject-order',
	cancelOrder: 'dashboard/orders/cancel-order',
	approveRejectTransaction: 'dashboard/orders/approve-or-reject-transaction',

	// EMPLOYEE E-MAIL VERIFICATION
	employeeEmailVerification: 'employee-email-verification',

	// ACTIVITY LOG
	logs: 'logs',
	logsDownload: 'logs/download',
	logsCreateFile: 'logs/create-file',

	// CATEGORIES
	categories: 'categories',
	categoriesGetAll: 'get-all-categories',
	checkCategorySlug: 'check-category-slug',
	checkCategoryName: 'check-category-name',

	// BRANDS
	brands: 'dashboard/brands',
	createBrands: 'dashboard/brand-create',
	getBrands: 'brands-get-all',
	deleteBrandBulk: 'dashboard/brands/bulk-delete-brands',

	// SLIDESHOW

	slideshowgalleryImages: 'dashboard/images/banners',
  imagesOrder: 'images/banners/update-order',
	slideshowuploadLocalImage: 'images',
	slideshowgalleryDeleteImages: 'images-delete',
	slideshowgalleryUpdateImages: 'images-update',

	// ATTRIBUTES
	attributes: 'attributes',
	createAttribute: 'create-attribute',

	// UNITS
	units: 'units',
	addUnitToAttribute: 'add-unit-to-attribute',

	// LOGIN
	login: 'login-dashboard',

	// FORGOT PASSWORD
	forgotPassword: 'forgot-password',

	// PASSWORD RESET
	passwordReset: 'password-reset',

	// PROFILE
	updateEmployee: 'users/update-employee',

	// USERS
	employeeLogs: 'employee/logs',

	// PERMISSIONS
	getPermissions: 'get-permissions',

	// USER CHECK TOKEN
	userCheckToken: 'users/check-token',

	// GROCERY
	grocery: 'grocery',
	departmentGrocery: 'grocery',

	// VARIATIONS
	deleteVariation: 'delete-variation',
};

export { AXIOS_API_CALL };
