import React, { useState, useEffect } from 'react';
import { Select, Input, Button, Form, Row, Col, Checkbox, Upload } from 'antd';
import Loading from '../../../../components/loading/Loading';
import SidebarModal from '../../../../components/sidebarModal/SidebarModal';
import { UploadOutlined } from '@ant-design/icons';

export const EditBanner = ({ updateProps, loadingBanner, updateModalFormRef, updateFormFields, editBtnLoader, onSubmit, setToggleUpdate, imageData, setImageData, shoeSearch, setImageFile, imageFile }) => {
	console.log('update props', updateProps, updateFormFields );
	const [bannerKind, setBannerKind] = useState(updateProps?.data?.redirectionType);
	const [bannerProduct, setBannerProduct] = useState(updateProps?.data?.relatedShoe || '');
	const [link, setLink] = useState(updateProps?.data?.sliderUrlRedirect);
const active = updateFormFields.find((field) => field.name === 'activeBanner')?.value;
	
console.log(active)
useEffect(() => {
		setBannerKind(updateProps?.data?.redirectionType);
		setLink(updateProps?.data?.sliderUrlRedirect);
	}, [updateProps]);

	console.log(bannerKind);
	const handleKindChange = (value) => {
		console.log('value', value);
		setBannerKind(value);
		if (value === 'url' || value === 'search') {
			setLink('');
		}
	};
	const handleProductChange = (value) => {
		setBannerProduct(value);
	};

	const imageProps = {
		type: 'banner',
		slideshow: false,
		navigate: '/admin/shoes/banners',
		multiple: false,
	};

	const kind = [
		{ name: 'Product', abbreviation: 'product' },
		{ name: 'URL', abbreviation: 'url' },
		{ name: 'Search', abbreviation: 'search' },
		// Add more kinds as needed
	];

	return (
		<SidebarModal {...updateProps} className="sidebar__modal--right" title="Edit Banner">
			{!loadingBanner ? (
				<Form name="update" ref={updateModalFormRef} wrapperCol={{ span: 24 }} initialValues={{ remember: false }} onFinish={(data) => onSubmit({ event, name: 'update', data: { ...data, image: imageData } })} fields={updateFormFields}>
					<Form.Item name="updateBannerId" style={{ display: 'none' }}>
						<Input type="hidden" />
					</Form.Item>
					<Form.Item
						name="bannerTitle"
						label="Banner Title:"
						rules={[
							{
								required: true,
								message: 'Please input banner name!',
							},
						]}
					>
						<Input placeholder="Banner title:" />
					</Form.Item>

					<Form.Item name="activeBanner" valuePropName="checked" className="initial-height">
						<Checkbox checked={active}>Active</Checkbox>
					</Form.Item>
					<Form.Item name="bannerImage" label="Image:">
						{imageData && <img src={imageData} alt="Uploaded banner image" width={400} height={150} style={{ border: 'dashed 1px gray', objectFit: 'contain' }} />}
						{/* <Upload {...uploadProps} fileList={imageFile}>
							<Button icon={<UploadOutlined />}>Select Files</Button>
						</Upload> */}
						<label className="ant-form-item-required upload-btn pt-2 block">
							<span>Upload New Image:</span>
							<input
								type="file"
								onChange={(e) => {
									setImageFile(e.target.files[0]);
									setImageData(URL.createObjectURL(e.target.files[0]));
								}}
							/>
						</label>
					</Form.Item>

					<Form.Item name="bannerKind" label="Kind:" rules={[{ required: true, message: 'Please input your kind!' }]}>
						<Select onChange={handleKindChange}>
							<Option value="" defaultValue>
								Select
							</Option>
							{kind.map((item) => {
								return (
									<Option key={item.name} value={item.abbreviation}>
										{item.name}
									</Option>
								);
							})}
						</Select>
					</Form.Item>

					{bannerKind && bannerKind === 'product' && (
						<Form.Item name="bannerProduct" label="Product:" rules={[{ required: true, message: 'Please select a product!' }]}>
							<Select value={bannerProduct} onChange={handleProductChange}>
								<Option value="">Select Product</Option>
								{Array.isArray(shoeSearch) && shoeSearch.length > 0 ? (
									shoeSearch.map((shoe) => (
										<Option key={shoe._id} value={shoe._id}>
											{`${shoe.name} (${shoe.colorway})`}
										</Option>
									))
								) : (
									<Option disabled>No products available</Option>
								)}
							</Select>
						</Form.Item>
					)}

					{bannerKind && (bannerKind === 'url' || bannerKind === 'search') && (
						<Form.Item name="bannerSliderUrlRedirect" label="Link:" rules={[{ required: true, message: 'Please input a valid link!' }]}>
							<Input placeholder="Banner link:" value={link} onChange={(e) => setLink(e.target.value)} />
						</Form.Item>
					)}

					<Form.Item className="ant-col-btn" wrapperCol={{ offset: 8 }}>
						<Row gutter={16}>
							<Col span={12}>
								<Button type="primary" htmlType="button" className="btn btn-primary-link btn-block" onClick={() => setToggleUpdate(false)}>
									<span>Cancel</span>
								</Button>
							</Col>
							<Col span={12}>
								{!editBtnLoader ? (
									<Button type="submit" htmlType="submit" className="btn btn-primary btn-block">
										<span>Save Changes</span>
									</Button>
								) : (
									<Button type="button" className="btn btn-primary-outline btn-block">
										<span>Editing...</span>
									</Button>
								)}
							</Col>
						</Row>
					</Form.Item>
				</Form>
			) : (
				<Loading />
			)}
		</SidebarModal>
	);
};
