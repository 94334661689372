import React from 'react';
import Modal from '../../../../components/modal/Modal';
import { Input, Button, Form, Row } from 'antd';

export const DeactivateAsk = ({ deactivateProps, deactivateModalFormRef, handleOnFinish, deactivateFormFields, deactivateBtnLoader, setToggleDeactivate }) => {
					return (
						<Modal {...deactivateProps} className="sidebar__modal--center" title="">
							<Form name="deactivateAsk" ref={deactivateModalFormRef} wrapperCol={{ span: 24 }} initialValues={{ remember: false }} onFinish={(data) => handleOnFinish({ name: 'deactivate', data: data })} fields={deactivateFormFields}>
								<h2 className="text-center mb-4">Are you sure you want to deactivate the selected ask?</h2>

								<Form.Item name="deactivateUserId" style={{ display: 'none' }}>
									<Input type="hidden" />
								</Form.Item>

								<Form.Item>
									<Row gutter={16} style={{ justifyContent: 'center', columnGap: '20px' }}>
										{!deactivateBtnLoader ? (
											<Button type="submit" htmlType="submit" className="btn btn-primary-link btn-block">
												<span>Yes</span>
											</Button>
										) : (
											<Button type="button" className="btn btn-primary-link btn-block">
												<span>Deactivating...</span>
											</Button>
										)}

										<Button type="primary" htmlType="button" className="btn btn-primary btn-block" onClick={() => setToggleDeactivate(false)}>
											<span>No</span>
										</Button>
									</Row>
								</Form.Item>
							</Form>
						</Modal>
					);
				};
