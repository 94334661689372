import React from 'react';

const PaginationBullet = ({ data, current, onTarget }) => {
	return (
		<>
			{data.map((item, idx) => {
				return (
					<button data-cy={`pagination-bullet-${idx + 1}`} key={idx} onClick={() => onTarget(item)} className={`btn btn-pagination ${current === item ? 'is-active' : ''}`}>
						<span>{item}</span>
					</button>
				);
			})}
		</>
	);
};

const Pagination = ({ current, setCurrentPage, limitPage, buttonLimit, pagesCount, totalCount }) => {
	console.log(current, limitPage, buttonLimit, pagesCount, totalCount);
	const onPrev = () => {
		setCurrentPage((prevState) => Math.max(prevState - 1, 1));
	};

	const onNext = () => {
		setCurrentPage((prevState) => Math.min(prevState + 1, pagesCount));
	};

	const onFirst = () => {
		setCurrentPage(1);
	};

	const onLast = () => {
		setCurrentPage(pagesCount);
	};

	const onTarget = (target) => {
		setCurrentPage(target);
	};

	// Calculate pages
	const numbers = [];
	for (let i = 1; i <= Math.ceil(totalCount / limitPage); i++) {
		numbers.push(i);
	}

	const getPaginationButton = () => {
		let numShown = buttonLimit;
		let start = Math.min(numShown, numbers.length);
		let first = current - Math.floor(start / 2);
		first = Math.max(first, 1);
		first = Math.min(first, numbers.length - start + 1);

		return [...Array(start)].map((k, i) => i + first);
	};

	const paginationButton = getPaginationButton();
	const maxOfNumbers = numbers.length ? Math.max(...numbers) : 1;
	const minOfNumbers = numbers.length ? Math.min(...numbers) : 1;

	return (
		<div className="pagination">
			<button data-cy="pagination-first" className={`btn btn-pagination btn-first ${paginationButton.length > 1 && current !== minOfNumbers ? 'show' : ''}`} onClick={() => onFirst()}>
				<img alt="Arrow" src="/assets/icons/double-arrow-pagination.svg" />
			</button>

			<button data-cy="pagination-prev" className="btn btn-prev" onClick={() => onPrev()} disabled={`${current <= 1 ? 'disabled' : ''}`}>
				<img alt="Arrow" src="/assets/icons/arrow-pagination.svg" />
			</button>

			<div data-cy="pagination-bullet" className="bullet">
				<PaginationBullet data={paginationButton} current={current} onTarget={onTarget} />
			</div>

			<button data-cy="pagination-next" className="btn btn-next" onClick={() => onNext()} disabled={`${current === numbers.length ? 'disabled' : ''}`}>
				<img alt="Arrow" src="/assets/icons/arrow-pagination.svg" />
			</button>

			<button data-cy="pagination-last" className={`btn btn-pagination btn-last ${paginationButton.length > 1 && current !== maxOfNumbers ? 'show' : ''}`} onClick={() => onLast()}>
				<img alt="Arrow" src="/assets/icons/double-arrow-pagination.svg" />
			</button>
		</div>
	);
};

export default Pagination;
