import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../context/useAuth';
import { SERVER_URL } from '../../../../config';
import { AXIOS_API_CALL } from '../../../../utils/endpoint';
import { PERMISSIONS } from '../../../../utils/permissions';
import { notification } from 'antd';
import JoditEditor from 'jodit-react';


const EditDocument = () => {
	const { user } = useAuth();
	const location = useLocation();
	const [groceryPermissions, setGroceryPermissions] = useState([]);
	const [refetch, setRefetch] = useState(false);
	const { documentId } = useParams();
  	const navigate = useNavigate();

	const [readData, setReadData] = useState([]);
	const [readDataRefetch, setReadDataRefetch] = useState(false);
	const [readDataLoading, setReadDataLoading] = useState(true);

	const [currentPage, setCurrentPage] = useState(1);
	const [limitPage, setLimitPage] = useState(10);

	const [search, setSearch] = useState(null);
	const [searchForm, setSearchForm] = useState({ query: '' });
	const [filterStatus, setFilterStatus] = useState(null);

	const [formData, setFormData] = useState({ description: '', content: '', version: '' });

	const editor = useRef(null);

	const config = {
		readonly: false, // All options from https://xdsoft.net/jodit/docs/
		toolbar: true,
		buttons: ['bold', 'italic', 'underline', 'strikethrough', 'link', 'unlink', '|', 'ul', 'ol', '|', 'fontsize', '|', 'align', 'undo', 'redo'],
		buttonsMD: ['source','bold', 'italic', 'underline', 'strikethrough', 'link', 'unlink', '|', 'ul', 'ol', '|', 'fontsize', '|', 'align', 'undo', 'redo'],
		clipboard: {
			plainText: false, // Allow rich text pasting
		},
		allowPasteFromWord: true,
	};

	// GET USER TOKEN
	useEffect(() => {
		const { token } = user || {};
		if (token) {
			const decodedToken = jwtDecode(token);
			const permissions = decodedToken?.roleData?.permissions;
			console.log(permissions);
			if (permissions && location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`)) {
				if (Object.keys(permissions).some((permission) => permission.includes('grocery'))) {
					setGroceryPermissions(permissions.grocery || []);
				}
			}
		}
	}, [user, location]);

	const getReadData = useCallback(async () => {
		const { token } = user;

		const payload = {
			department: PERMISSIONS.grocery,
			searchTerm: search,
		};

		try {
			const response = await axios
				.get(`${SERVER_URL}/${AXIOS_API_CALL.getDocuments}/${documentId}`, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } })
				.then((res) => {
					console.log('Response data:', res.data);
					return res;
				})
				.catch((err) => console.error(err))
				.finally(
					setTimeout(() => {
						setReadDataLoading(false);
					}, 200)
				);
			return response;
		} catch (err) {
			console.error('Error fetching data:', err);
		}
	}, [user, limitPage, search, currentPage, filterStatus]);

	useEffect(() => {
		let isMounted = true;

		new Promise((resolve, reject) => {
			setReadDataLoading(true);
			setTimeout(() => {
				resolve(getReadData());
			}, 700);
		}).then((res) => {
			if (isMounted) {
				setReadData(res?.data?.legalDocument);
				setFormData({
					description: res.data.legalDocument.description,
					content: res.data.legalDocument.content,
					version: res?.data.legalDocument.version.toFixed(1),
				});
			}
		});

		return () => {
			isMounted = false;
		};
	}, [getReadData, readDataRefetch]);
	const handleChange = (e) => {
		const { name, value } = e.target;
		console.log(name, value);
		setFormData((prev) => ({ ...prev, [name]: value }));
	};

	const handleEditorChange = (newContent) => {
		setFormData((prev) => ({ ...prev, content: newContent }));
		console.log(formData);
	};

	const handlePaste = (event) => {
		event.preventDefault(); // Prevent the default paste behavior
		const text = event.clipboardData.getData('text/plain'); // Get the pasted text
		setFormData((prev) => ({ ...prev, content: prev.content + text })); // Update the content state
	};
	const handleSubmit = async (e) => {
		e.preventDefault();
		const { token } = user;

		try {
			await axios.put(`${SERVER_URL}/${AXIOS_API_CALL.getDocuments}/${documentId}`, formData, {
				headers: { Authorization: `Bearer ${token}` },
			});
			notification.success({
				message: 'Document updated successfully!',
				placement: 'bottomLeft',
			});
			setTimeout(() => {
				window.location.reload();
			}, 700);
		} catch (err) {
			console.error('Error updating document:', err);
			notification.error({
				message: 'Failed to update document.',
				placement: 'bottomLeft',
			});
		}
    
	};


  const onClose = useCallback(() => {
		navigate(-1);
	}, [navigate, location]);

	return (
		<main>
			<div className="editDocument">
				<div className="editDocument__header">
					<h1>Edit {formData.description}</h1>
					<button onClick={onClose} data-cy="modal-close-btn" type="button" className="ant-btn btn btn-primary-link btn-block btn-back">
						<span className="text">Go Back</span>
					</button>
				</div>
				<form onSubmit={handleSubmit}>
					<div className="form-group">
						<label htmlFor="version">Version</label>
						<input type="text" id="version" name="version" disabled value={formData.version} onChange={handleChange} required className={`input `} />
					</div>
					<div className="form-group">
						<label htmlFor="description">Description</label>
						<input type="text" id="description" name="description" value={formData.description} onChange={handleChange} required className={`input `} />
					</div>
					<div className="form-group">
						<label htmlFor="content">Content</label>

						<JoditEditor
							name="content"
							ref={editor}
							value={formData.content}
							config={config}
							tabIndex={1} // tabIndex of textarea
							onBlur={handleEditorChange} // preferred to use only this option to update the content for performance reasons
							//onChange={handleEditorChange}
							onPaste={(event) => {
								console.log('Pasting content:', event.clipboardData.getData('text'));
							}}
						/>
					</div>

					<button type="submit" className="btn btn-primary pr-4 pl-4">
						Save Changes
					</button>
				</form>
			</div>
		</main>
	);
};

export default EditDocument;
