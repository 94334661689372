import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Popover } from 'antd';
import Checkbox from '../../../components/customCheckbox/CustomCheckbox';
import { PERMISSIONS } from '../../../utils/permissions';
import Message from '../../../components/message/Message';
import { contentMessage } from '../../../helpers/contentMessage';
import Pagination from '../../../components/pagination/Pagination';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

const Table = (props) => {
	// TABLE
	const { data, handleToggle } = props;
	// PAGINATION
	const { pagination } = props;
	// CUSTOM FOR ACTIONS
  const { deleteActions, deactivateActions, editAskPriceActions, groceryPermissions, getGroceryPermissions } = props;
  const navigate = useNavigate()
	console.log(data);

	return (
		<>
			<table className="table">
				<thead className="thead">
					<tr>
						{/*
						 */}
						<th style={{ textAlign: 'left' }}>Name</th>
						<th>ColorWay</th>
						<th>Product</th>
						<th>Price</th>
						<th>Size</th>
						<th>Product Image</th>
						<th>Status</th>
						<th>Seller</th>
						<th>Action</th>
					</tr>
				</thead>
				<tbody className="tbody">
					{data && data.length > 0 && Array.isArray(data) ? (
						data?.map((item, idx) => (
							<tr key={item._id}>
								<td style={{ textAlign: 'left' }}>{item.name}</td>
								<td>{item.colorway}</td>
								<td>{item.sku}</td>
								<td>{item.regularPrice}</td>
								<td>{item.size}</td>
								<td>
									<img className="img" src={item.image} alt={item.name} width={120} style={{ borderRadius: '0.25rem' }} />{' '}
								</td>

								<td> {item.status}</td>
								<td style={{ cursor: 'pointer' }} onClick={() => navigate('/admin/shoes/users', { state: { sellerId: item?.sellerId } })}>
									{item.sellerName}
								</td>
								<td>
									{Object.values(groceryPermissions).some((key) => [PERMISSIONS.deleteShoes].includes(key.name)) && (
										<Popover
											className="tbody-actions"
											placement="left"
											trigger="click"
											content={() => (
												<div className="actions" data-cy="actions-popover-employees">
													{location && location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`) && (
														<>
															{getGroceryPermissions(groceryPermissions, PERMISSIONS.deleteEmployees) && (
																<button className="btn btn-actions" type="button" data-cy="actions-delete-employee" onClick={() => handleToggle({ name: 'delete', state: deleteActions, data: item._id })}>
																	<span className="icon">
																		<img alt="Delete" src="/assets/icons/delete.svg" />
																	</span>
																	<span className="text">
																		<strong>DELETE</strong>
																	</span>
																</button>
															)}
															{getGroceryPermissions(groceryPermissions, PERMISSIONS.deleteEmployees) && (
																<button className="btn btn-actions" type="button" data-cy="actions-deactivate-employee" onClick={() => handleToggle({ name: 'deactivate', state: deactivateActions, data: item._id })}>
																	<span className="icon">
																		<img alt="deactivate" src="/assets/icons/block.svg" />
																	</span>
																	<span className="text">
																		<strong>DEACTIVATE ASK</strong>
																	</span>
																</button>
															)}
															{getGroceryPermissions(groceryPermissions, PERMISSIONS.updateEmployees) && (
																<button className="btn btn-actions" type="button" data-cy="actions-update-employee" onClick={() => handleToggle({ name: 'edit-ask-price', state: editAskPriceActions, data: item })}>
																	<span className="icon">
																		<img alt="Edit" src="/assets/icons/edit.svg" />
																	</span>
																	<span className="text">
																		<strong>EDIT ASK PRICE</strong>
																	</span>
																</button>
															)}
														</>
													)}
												</div>
											)}
										>
											<button className="btn" type="button" data-cy="actions-toggle-employees">
												<span className="icon">
													<img className="d-block pl-1 pr-1" alt="Dots" src="/assets/icons/dots-y.svg" />
												</span>
											</button>
										</Popover>
									)}
								</td>
							</tr>
						))
					) : (
						<tr>
							<td colSpan={20} style={{ width: '100%' }}>
								<Message className="content-message">
									<h2 style={{ color: '#666' }}>{contentMessage.dataNotFound}</h2>
								</Message>
							</td>
						</tr>
					)}
				</tbody>
			</table>

			{data && data.length > 0 && Array.isArray(data) && <Pagination {...pagination} />}
		</>
	);
};

export default Table;
