import { PERMISSIONS } from './permissions';
import { getGroceryPermissions } from '../middleware';

const groceryMenuItems = (props) => {
	const { groceryPermissions, handleLogout, onHide } = props;
	const totalAsks = localStorage.getItem('totalActiveAsks');
	const valueAsks = localStorage.getItem('valueOfActiveAsks');
	const grocery = [
		{
			_id: '',
			label: 'Admin Users',
			path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/employees`,
			group: 'user',
			type: 'anchor',
			icon: '/assets/icons/supervisor.svg',
			expanded: false,
			protected: false,
			dataCy: 'sidebar-grocery-attributes',
			visible: true,
			onClick: () => {
				onHide(false);
			},
		},
		{
			_id: '',
			label: 'Users',
			path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/users`,
			group: 'user',
			type: 'anchor',
			icon: '/assets/icons/group.svg',
			expanded: false,
			protected: false,
			dataCy: 'sidebar-grocery-attributes',
			visible: true,
			onClick: () => {
				onHide(false);
			},
		},
		{
			_id: '',
			label: 'Block Lists',
			path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/block-list`,
			group: 'user',
			type: 'anchor',
			icon: '/assets/icons/block-white.svg',
			expanded: false,
			protected: false,
			dataCy: 'sidebar-grocery-attributes',
			visible: true,
			onClick: () => {
				onHide(false);
			},
		},
		{
			_id: '',
			label: 'Seller Verification Requests',
			path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/seller-verfication-requests`,
			group: 'user',
			type: 'anchor',
			icon: '/assets/icons/domain-verification.svg',
			expanded: false,
			protected: false,
			dataCy: 'sidebar-grocery-attributes',
			visible: true,
			onClick: () => {
				onHide(false);
			},
		},
		{
			_id: '',
			label: 'Seller Payout Requests',
			path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/seller-payout-requests`,
			group: 'user',
			type: 'anchor',
			icon: '/assets/icons/domain-verification.svg',
			expanded: false,
			protected: false,
			dataCy: 'sidebar-grocery-brands',
			visible: true,
			onClick: () => {
				onHide(false);
			},
		},
		{
			_id: '',
			label: 'User Error Logs',
			path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/user-error-tracking`,
			group: 'user',
			type: 'anchor',
			icon: '/assets/icons/user-error.svg',
			expanded: true,
			protected: false,
			dataCy: 'sidebar-grocery-profile',
			visible: true,
			onClick: function() {
				onHide(false);
			},
			children: [],
		},
		{
			_id: '',
			label: 'Brands',
			path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.brands}`,
			group: 'product',
			type: 'anchor',
			icon: '/assets/icons/steps.svg',
			expanded: false,
			protected: false,
			dataCy: 'sidebar-grocery-brands',
			visible: true,
			onClick: () => {
				onHide(false);
			},
		},
		{
			_id: '',
			label: 'Products',
			path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.products}`,
			group: 'product',
			type: 'anchor',
			icon: '/assets/icons/products.svg',
			expanded: false,
			protected: false,
			dataCy: 'sidebar-grocery-products',
			visible: true,
			onClick: function() {
				onHide(false);
			},
			children: [],
		},
		{
			_id: '',
			label: 'Costs',
			path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/costs`,
			group: 'product',
			type: 'anchor',
			icon: '/assets/icons/pen.svg',
			expanded: false,
			protected: false,
			dataCy: 'sidebar-grocery-costs',
			visible: true,
			onClick: function() {
				onHide(false);
			},
			children: [],
		},
		{
			_id: '',
			label: 'Asks',
			path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/asks`,
			group: 'product',
			type: 'anchor',
			icon: '/assets/icons/footprint.svg',
			expanded: false,
			protected: false,
			dataCy: 'sidebar-grocery-attributes',
			visible: true,
			onClick: () => {
				onHide(false);
			},
		},

		{
			_id: '',
			label: 'Orders',
			path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.orders}`,
			group: 'order',
			type: 'anchor',
			icon: '/assets/icons/cart.svg',
			expanded: false,
			protected: false,
			dataCy: 'sidebar-grocery-orders',
			visible: true,
			onClick: function() {
				onHide(false);
			},
			children: [],
		},
		// {
		// 	_id: '',
		// 	label: 'Order Attempts',
		// 	path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/order-attempts`,
		// 	group: 'order',
		// 	type: 'anchor',
		// 	icon: '/assets/icons/list.svg',
		// 	expanded: false,
		// 	protected: false,
		// 	dataCy: 'sidebar-grocery-attributes',
		// 	visible: true,
		// 	onClick: () => {
		// 		onHide(false);
		// 	},
		// },
		{
			_id: '',
			label: 'Discounts',
			path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/discounts`,
			group: 'order',
			type: 'anchor',
			icon: '/assets/icons/percent.svg',
			expanded: false,
			protected: false,
			dataCy: 'sidebar-grocery-attributes',
			visible: true,
			onClick: () => {
				onHide(false);
			},
		},
		{
			_id: '',
			label: 'Promos',
			path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/promos`,
			group: 'order',
			type: 'anchor',
			icon: '/assets/icons/campaign.svg',
			expanded: false,
			protected: false,
			dataCy: 'sidebar-grocery-attributes',
			visible: true,
			onClick: () => {
				onHide(false);
			},
		},
		{
			_id: '',
			label: 'Tarifs',
			path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/tarifs`,
			group: 'order',
			type: 'anchor',
			icon: '/assets/icons/currency.svg',
			expanded: false,
			protected: false,
			dataCy: 'sidebar-grocery-attributes',
			visible: true,
			onClick: () => {
				onHide(false);
			},
		},
		{
			_id: '',
			label: 'Home page banners',
			path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/banners`,
			group: 'banner',
			type: 'anchor',
			icon: '/assets/icons/supervisor.svg',
			expanded: false,
			protected: false,
			dataCy: 'sidebar-grocery-attributes',
			visible: true,
			onClick: () => {
				onHide(false);
			},
		},
		{
			_id: '',
			label: 'Legal Documents',
			path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/legal-documents`,
			group: 'banner',
			type: 'anchor',
			icon: '/assets/icons/user.svg',
			expanded: true,
			protected: false,
			dataCy: 'sidebar-grocery-profile',
			visible: true,
			onClick: function() {
				onHide(false);
			},
			children: [],
		},
		{
			_id: '',
			label: 'Profile',
			path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.profile}`,
			group: 'account',
			type: 'anchor',
			icon: '/assets/icons/user.svg',
			expanded: true,
			protected: false,
			dataCy: 'sidebar-grocery-profile',
			visible: true,
			onClick: function() {
				onHide(false);
			},
			children: [],
		},
		{
			_id: '',
			label: 'Logout',
			path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.login}`,
			group: 'account',
			type: 'button',
			icon: '/assets/icons/logout.svg',
			expanded: true,
			protected: false,
			dataCy: 'sidebar-grocery-logout',
			visible: true,
			onClick: () => {
				handleLogout();
			},
			children: [],
		},
	];

	return grocery;
};

export { groceryMenuItems };
