import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../context/useAuth';
import { SERVER_URL } from '../../../../config';
import { AXIOS_API_CALL } from '../../../../utils/endpoint';
import { PERMISSIONS } from '../../../../utils/permissions';
import SidebarModal from '../../../../components/sidebarModal/SidebarModal';

const ViewError = ({ viewProps, viewLoading, viewData, handleClose }) => {
	const { data } = viewProps;

	// GET USER TOKEN

	return (
		<SidebarModal {...viewProps} className="sidebar__modal--right" title="View Error Details">
			{!viewLoading ? (
				<div className="error-details">
					<div className="form-group">
						<label className="label mb-1 d-block" htmlFor="viewType">
							<b>Type:</b>
						</label>
						<p id="viewType" className="value viewValue">
							{data.level || 'N/A'}
						</p>
					</div>
					<div className="form-group">
						<label className="label mb-1 d-block" htmlFor="viewMessage">
							<b>Message:</b>
						</label>
						<p id="viewMessage" className="value viewValue">
							{Array.isArray(data.message) ? data.message.map((item, index) => <span key={index}>{item}</span>) : data.message || 'N/A'}
						</p>
					</div>
					<div className="form-group">
						<label className="label mb-1 d-block" htmlFor="viewPhoneNumber">
							<b>Phone Number:</b>
						</label>
						<p id="viewPhoneNumber" className="value viewValue">
							{data.metadata?.phoneNumber || 'N/A'}
						</p>
					</div>
					<div className="form-group">
						<label className="label mb-1 d-block" htmlFor="viewMetadata">
							<b>Meta data:</b>
						</label>
						<pre id="viewMetadata" className="value viewValue">
							{data.metadata ? JSON.stringify(data.metadata, null, 2) : 'N/A'}
						</pre>
					</div>
					<div className="form-group">
						<label className="label mb-1 d-block" htmlFor="viewTimestamp">
							<b>Timestamp:</b>
						</label>
						<p id="viewTimestamp" className="value viewValue">
							{data.timestamp || 'N/A'}
						</p>
					</div>
				
				</div>
			) : (
				<Loading />
			)}

			<div className="form-group form-group-sidebar">
				<button data-cy="modal-close-btn" type="button" className="btn btn-primary-link" onClick={handleClose}>
					<span className="text">Close</span>
				</button>
			</div>
		</SidebarModal>
	);
};

export default ViewError;
