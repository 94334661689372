import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Popover } from 'antd';
import Checkbox from '../../../components/customCheckbox/CustomCheckbox';
import { PERMISSIONS } from '../../../utils/permissions';
import Message from '../../../components/message/Message';
import { contentMessage } from '../../../helpers/contentMessage';
import Pagination from '../../../components/pagination/Pagination';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

const Table = (props) => {
	// TABLE
	const { data } = props;
	// PAGINATION
	const { pagination } = props;
	const { deleteActions, groceryPermissions, getGroceryPermissions, viewActions } = props;

	const formatDate = (isoString) => {
		const date = new Date(isoString);
		return date.toLocaleString(); // You can customize this format
	};

	return (
		<>
			<table className="table">
				<thead className="thead">
					<tr>
						<th>ID</th>
						<th>Name</th>
						<th>ColorWay</th>
						<th style={{ width: '40%' }}>Product</th>
						<th>Number</th>
						<th>Status</th>
						<th>Verify Method</th>
						<th>Purchase price</th>
						<th>Total Payout</th>
						<th>Size</th>
						<th>Buyers</th>
						<th>Created At</th>
						<th>Ship by</th>
						<th>Penalty at</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody className="tbody">
					{data && data.length > 0 ? (
						data.map((item, idx) => (
							<tr key={item._id}>
								<td>{item.ID}</td>
								<td>{item.name}</td>

								<td>{item.colorway}</td>
								<td style={{ width: '40%', whiteSpace: 'pre-line' }}>{item.product}</td>
								<td>{item.orderNumber}</td>
								<td>{item.status}</td>
								<td>{!!item.verifiedViaMiddleman ? 'Middleman' : 'Digital'} verification</td>
								<td>{Number(item.totalPrice).toFixed(2)}</td>
								<td>{item.totalSellerPayout.toFixed(2)}</td>
								<td> {item.size}</td>
								<td> {item.buyer}</td>
								<td> {formatDate(item.createdAt)}</td>
								<td> {item.shipBy}</td>
								<td> {item.penaltyAt}</td>
								<td>
									{Object.values(groceryPermissions).some((key) => [PERMISSIONS.updateProducts, PERMISSIONS.deleteProducts, PERMISSIONS.readProducts].includes(key.name)) && (
										<Popover
											className="tbody-actions"
											placement="left"
											trigger="click"
											content={() => (
												<div className="actions" data-cy="actions-popover-products">
													{getGroceryPermissions(groceryPermissions, PERMISSIONS.updateProducts) && (
														<Link to={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.readOrders}/${item._id}`} className="link btn-actions link-details">
															<span className="icon d-flex">
																<img alt="EDIT" src="/assets/icons/edit-user.svg" style={{ display: 'block' }} />
															</span>
															<span className="text">EDIT</span>
														</Link>
													)}
													{/* {getGroceryPermissions(groceryPermissions, PERMISSIONS.updateProducts) && (
														<Link to={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.updateProduct}/${item._id}`} className="link btn-actions link-details">
															<span className="icon d-flex">
																<img alt="EDIT" src="/assets/icons/edit-user.svg" style={{ display: 'block' }} />
															</span>
															<span className="text">EDIT</span>
														</Link>
													)} */}
													{/* {getGroceryPermissions(groceryPermissions, PERMISSIONS.deleteProducts) && (
														<button className="btn btn-actions" type="button" data-cy="actions-delete-product" onClick={() => handleToggle({ name: 'delete', state: deleteActions, data: item._id })}>
															<span className="icon">
																<img alt="Delete" src="/assets/icons/delete.svg" />
															</span>
															<span className="text">
																<strong>DELETE</strong>
															</span>
														</button>
													)} */}
												</div>
											)}
										>
											<button className="btn" type="button" data-cy="actions-toggle-products">
												<span className="icon">
													<img className="d-block pl-1 pr-1" alt="Dots" src="/assets/icons/dots-y.svg" />
												</span>
											</button>
										</Popover>
									)}
								</td>
							</tr>
						))
					) : (
						<tr>
							<td colSpan={20} style={{ width: '100%' }}>
								<Message className="content-message">
									<h2 style={{ color: '#666' }}>{contentMessage.dataNotFound}</h2>
								</Message>
							</td>
						</tr>
					)}
				</tbody>
			</table>

			{data && data.length > 0 && <Pagination {...pagination} />}
		</>
	);
};

export default Table;
