import React, { useEffect, useState } from 'react';
import { Popover } from 'antd';
import Checkbox from '../../../components/customCheckbox/CustomCheckbox';
import { PERMISSIONS } from '../../../utils/permissions';
import Message from '../../../components/message/Message';
import { contentMessage } from '../../../helpers/contentMessage';
import Pagination from '../../../components/pagination/Pagination';

const Table = (props) => {
	// TABLE
	const { data = [], handleCheckedRows, handleDisabledButton, handleToggle } = props;
	// PAGINATION
	const { pagination } = props;
	// CUSTOM FOR ACTIONS
	const { updateActions, viewActions, statusActions, deleteActions, blockActions, location, groceryPermissions, getGroceryPermissions } = props;
	// CUSTOM FOR FILTERS
	const { handleStatusFilter } = props;

	console.log(data);

	const [isCheckAll, setIsCheckAll] = useState(false);
	const [isCheck, setIsCheck] = useState([]);

	// HANDLE CHECKED ALL
	const handleSelectAll = (e) => {
		setIsCheckAll(!isCheckAll);
		setIsCheck(data.map((li) => li._id));
		if (isCheckAll) {
			setIsCheck([]);
		}
	};

	// HANDLE CHECKED CURRENT
	const handleClick = (e) => {
		const { id, checked } = e.target;
		setIsCheckAll(true);
		setIsCheck([...isCheck, id]);
		if (!checked) {
			setIsCheck(isCheck.filter((item) => item !== id));
		}
	};

	// UNTOGGLE CHECKED TOP
	useEffect(() => {
		if (isCheck.length < 1) {
			setIsCheckAll(false);
		}
	}, [isCheck, setIsCheckAll]);

	useEffect(() => {
		handleDisabledButton(isCheckAll);
	}, [isCheckAll, handleDisabledButton]);

	useEffect(() => {
		handleCheckedRows(isCheck);
	}, [isCheck, handleCheckedRows]);
  console.log(data)

	return (
		<>
			<table className="table">
				<thead className="thead">
					<tr>
						{/* <th>{Object.values(groceryPermissions).some((key) => [PERMISSIONS.updateEmployees].includes(key.name)) && <Checkbox type="checkbox" name="select-all-employees" id="selectAll" handleClick={handleSelectAll} isChecked={isCheckAll} />}</th> */}

						<th>Full Name</th>
						<th>Email</th>
						<th>Phone</th>
						<th>Registration Date and Time</th>
						<th>Status</th>
						<th>Action</th>
					</tr>
				</thead>
				<tbody className="tbody">
					{data && data?.length > 0 ? (
						data?.map((item, idx) => {
							return (
								<tr key={item._id}>
									{/* <td data-cy={`checkbox-item-employees-${idx}`}>{Object.values(groceryPermissions).some((key) => [PERMISSIONS.updateEmployees].includes(key.name)) && <Checkbox key={item._id} type="checkbox" name={item._id} id={item._id} handleClick={handleClick} isChecked={isCheck.includes(item._id)} />}</td> */}

									<td>
										{' '}
										{item.firstName} {item.lastName}
									</td>

									<td>
										<a href={`mailto:${item.email}`} className="link">
											{item.email}
										</a>
									</td>
									<td>{item.phone}</td>
									<td style={{ textAlign: 'center' }}>{item.createdAt}</td>
									<td>{item.status}</td>

									<td className="y-top">
										{Object.values(groceryPermissions).some((key) => [PERMISSIONS.updateEmployees, PERMISSIONS.deleteEmployees].includes(key.name)) && (
											<Popover
												className="tbody-actions"
												placement="left"
												trigger="click"
												content={() => (
													<div className="actions" data-cy="actions-popover-employees">
														{location && location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`) && (
															<>
																{Object.values(groceryPermissions).some((key) => [PERMISSIONS.updateEmployees].includes(key.name)) && (
																	<div className="actions-group">
																		{getGroceryPermissions(groceryPermissions, PERMISSIONS.updateEmployees) && (
																			<button className="btn btn-actions" type="button" data-cy="actions-edit-employee" onClick={() => handleToggle({ name: 'view', state: viewActions, data: item })}>
																				<span className="icon">
																					<img alt="Delete" src="/assets/icons/eye.svg" />
																				</span>
																				<span className="text">
																					<strong>VIEW</strong>
																				</span>
																			</button>
																		)}
																		{getGroceryPermissions(groceryPermissions, PERMISSIONS.updateEmployees) && (
																			<button className="btn btn-actions" type="button" data-cy="actions-edit-employee" onClick={() => handleToggle({ name: 'update', state: updateActions, data: item })}>
																				<span className="icon">
																					<img alt="Delete" src="/assets/icons/edit-user.svg" />
																				</span>
																				<span className="text">
																					<strong>EDIT</strong>
																				</span>
																			</button>
																		)}

																		{getGroceryPermissions(groceryPermissions, PERMISSIONS.updateEmployees) && (
																			<button className="btn btn-actions" type="button" data-cy={`${item?.status === 'ACTIVE' || item?.status === 'WAITING_FOR_ACTIVATION' ? 'actions-deactivate-employee' : 'actions-activate-employee'}`} onClick={() => handleToggle({ name: 'status', state: statusActions, data: [{ id: item._id, status: item.status }] })}>
																				{item?.status === 'ACTIVE' || item?.status === 'WAITING_FOR_ACTIVATION' ? (
																					<>
																						<span className="icon">
																							<img alt="Delete" src="/assets/icons/deactivate-user.svg" />
																						</span>
																						<span className="text">
																							<strong>DEACTIVATE</strong>
																						</span>
																					</>
																				) : (
																					<>
																						<span className="icon">
																							<img alt="Delete" src="/assets/icons/activate-user.svg" />
																						</span>
																						<span className="text">
																							<strong>ACTIVATE</strong>
																						</span>
																					</>
																				)}
																			</button>
																		)}
																	</div>
																)}

																{getGroceryPermissions(groceryPermissions, PERMISSIONS.deleteEmployees) && (
																	<button className="btn btn-actions" type="button" data-cy="actions-delete-employee" onClick={() => handleToggle({ name: 'delete', state: deleteActions, data: item._id })}>
																		<span className="icon">
																			<img alt="Delete" src="/assets/icons/delete.svg" />
																		</span>
																		<span className="text">
																			<strong>DELETE</strong>
																		</span>
																	</button>
																)}
																{getGroceryPermissions(groceryPermissions, PERMISSIONS.deleteEmployees) && (
																	<button className="btn btn-actions" type="button" data-cy="actions-block-employee" onClick={() => handleToggle({ name: 'block', state: blockActions, data: item._id })}>
																		<span className="icon">
																			<img alt="Block" src="/assets/icons/block.svg" />
																		</span>
																		<span className="text">
																			<strong>BLOCK</strong>
																		</span>
																	</button>
																)}
															</>
														)}
													</div>
												)}
											>
												<button className="btn" type="button" data-cy="actions-toggle-employees">
													<span className="icon">
														<img className="d-block pl-1 pr-1" alt="Dots" src="/assets/icons/dots-y.svg" />
													</span>
												</button>
											</Popover>
										)}
									</td>
								</tr>
							);
						})
					) : (
						<tr>
							<td colSpan={20}>
								<Message className="content-message">
									<h2 style={{ color: '#666' }}>{contentMessage.dataNotFound}</h2>
								</Message>
							</td>
						</tr>
					)}
				</tbody>
			</table>

			{data && data.length > 0 && <Pagination {...pagination} />}
		</>
	);
};

export default Table;
