import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { PERMISSIONS } from '../../../utils/permissions';
import { Popover } from 'antd';
import { Link } from 'react-router-dom';

const ItemType = {
	ROW: 'row',
};

const DraggableRow = ({ item, index, moveRow, groceryPermissions, getGroceryPermissions, deleteActions, handleToggle }) => {
	const [, ref] = useDrag({
		type: ItemType.ROW,
		item: { index },
	});

	const [, drop] = useDrop({
		accept: ItemType.ROW,
		hover: (draggedItem) => {
			if (draggedItem.index !== index) {
				moveRow(draggedItem.index, index);
				draggedItem.index = index; // Update the dragged item's index
			}
		},
	});

	return (
		<tr ref={(node) => drop(ref(node))}>
			<td>
				<img src="/assets/icons/dnd.svg" width={16} alt="drag" style={{ cursor: 'move' }} />
			</td>
			<td>{item._id}</td>
			<td>{item.description}</td>
			<td>{item.type}</td>
			<td>{item.status}</td>
			<td>
				{Object.values(groceryPermissions).some((key) => [PERMISSIONS.updateProducts, PERMISSIONS.deleteProducts, PERMISSIONS.readProducts].includes(key.name)) && (
					<Popover
						className="tbody-actions"
						placement="left"
						trigger="click"
						content={() => (
							<div className="actions" data-cy="actions-popover-products">
								{getGroceryPermissions(groceryPermissions, PERMISSIONS.updateProducts) && (
									<button className="btn btn-actions" type="button" data-cy="actions-delete-product" onClick={() => handleToggle({ name: 'update', state: deleteActions, data: item })}>
										<span className="icon d-flex">
											<img alt="EDIT" src="/assets/icons/edit-user.svg" style={{ display: 'block' }} />
										</span>
										<span className="text">EDIT</span>
									</button>
								)}
								{getGroceryPermissions(groceryPermissions, PERMISSIONS.deleteProducts) && (
									<button className="btn btn-actions" type="button" data-cy="actions-delete-product" onClick={() => handleToggle({ name: 'delete', state: deleteActions, data: item._id })}>
										<span className="icon">
											<img alt="Delete" src="/assets/icons/delete.svg" />
										</span>
										<span className="text">
											<strong>DELETE</strong>
										</span>
									</button>
								)}
							</div>
						)}
					>
						<button className="btn" type="button" data-cy="actions-toggle-products">
							<span className="icon">
								<img className="d-block pl-1 pr-1" alt="Dots" src="/assets/icons/dots-y.svg" />
							</span>
						</button>
					</Popover>
				)}
			</td>
		</tr>
	);
};

export default DraggableRow;
