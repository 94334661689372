import React, { useState, useEffect, useCallback } from 'react';
import { Wrapper } from '../../../../../components/content';
import axios from 'axios';
import { SERVER_URL } from '../../../../../config/index';
import { useProducts } from '../../../../../context/products';
import { Description } from './description/Description';
import { jwtDecode } from 'jwt-decode';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../../../../context/useAuth';
import { AXIOS_API_CALL } from '../../../../../utils/endpoint';
import { PERMISSIONS } from '../../../../../utils/permissions';
import { DatePicker } from 'antd';
import moment from 'moment';

export const ProductInfo = (props) => {
	const { form, onChange, formError, setForm, setSelectedBrand, selectedBrand } = useProducts();
	const { className } = props;
	const [loadingBrand, setLoadingBrand] = useState(false);
	const [allBrands, setAllBrands] = useState([]);
	const [allCategories, setAllCategories] = useState([]);
	const { user } = useAuth();
	const location = useLocation();
	const [groceryPermissions, setGroceryPermissions] = useState([]);

	// GET USER TOKEN
	useEffect(() => {
		const { token } = user || {};
		if (token) {
			const decodedToken = jwtDecode(token);
			const permissions = decodedToken?.roleData?.permissions;
			if (permissions && location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`)) {
				if (Object.keys(permissions).some((permission) => permission.includes('grocery'))) {
					setGroceryPermissions(permissions.grocery || []);
				}
			}
		}
	}, [user, location]);

	const getCategories = useCallback(async () => {
		const { token } = user;

		const payload = {
			department: PERMISSIONS.grocery,
		};

		try {
			const response = await axios
				.post(`${SERVER_URL}/${AXIOS_API_CALL.categoriesGetAll}`, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } })
				.then((res) => {
					setAllCategories(res.data);
					return res;
				})
				.catch((err) => console.error(err))
				.finally(setTimeout(() => {}, 200));
			return response;
		} catch (err) {
			console.error('Error fetching data:', err);
		}
	}, [user]);

	const getBrands = useCallback(async () => {
		const { token } = user;

		const payload = {
			department: PERMISSIONS.grocery,
		};

		try {
			const response = await axios
				.get(`${SERVER_URL}/${AXIOS_API_CALL.getBrands}`, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } })
				.then((res) => {
					setAllBrands(res.data);
					return res;
				})
				.catch((err) => console.error(err))
				.finally(setTimeout(() => {}, 200));
			return response;
		} catch (err) {
			console.error('Error fetching data:', err);
		}
	}, [user]);

	useEffect(() => {
		const fetchData = async () => {
			await getCategories();
			await getBrands();
		};

		fetchData();
	}, [getBrands, getCategories]);

	const handleBrandChange = async (e) => {
		const selectedBrandId = e.target.value;
		setForm((prevForm) => ({
			...prevForm,
			productBrand: selectedBrandId,
		}));
		// Fetch additional brand details if needed
		if (selectedBrandId) {
			try {
				const response = await axios.get(`${SERVER_URL}/${AXIOS_API_CALL.brands}/${selectedBrandId}`, {
					headers: { Authorization: `Bearer ${user.token}` },
				});
				const brandDetails = response.data;
				// Update form with additional brand details if necessary
				setForm((prevForm) => ({
					...prevForm,
					brandDetails,
				}));
				setSelectedBrand(brandDetails);
				console.log(brandDetails);
			} catch (error) {
				console.error('Error fetching brand details:', error);
			}
		}
	};

	const handleDateChange = (date, dateString) => {
		setForm((prevForm) => ({
			...prevForm,
			productReleaseDate: dateString,
		}));
	};

	const handleAvailableSizesChange = (sizes) => {
		setSelectedSizes(sizes);
		setForm((prevForm) => ({
			...prevForm,
			productAvailableSizes: sizes,
		}));
	};

	const defaultBrandId = selectedBrand?._id || form.productBrand?._id || '';

	return (
		<Wrapper className={`product__card ${className}`}>
			<div className="wrapper-element p-0">
				<header className="card-header">
					<h4>Product Info</h4>
				</header>

				<main className="card-content relative">
					<form name="product-info" className="form">
						<div className="form-group">
							<label className="label" htmlFor="productID">
								<b>ID:</b>
							</label>
							<input value={form.productID || ''} onChange={onChange} name="productID" disabled placeholder="Product ID" type="text" id="productID" className={`input ${formError.productID ? 'error' : ''}`} />
						</div>
						<div className="form-group">
							<label className="label" htmlFor="productBrand">
								<b>Brand Name:</b>
							</label>
							<select value={defaultBrandId || ''} onChange={handleBrandChange} name="productBrand" id="productBrand" className={`select ${formError.productBrand ? 'error' : ''}`}>
								<option value="" disabled>
									Select a brand
								</option>
								{allBrands.map((brand) => (
									<option key={brand._id} value={brand._id}>
										{brand.name}
									</option>
								))}
							</select>
							<p data-cy="" className={`error__onblur ${selectedBrand == null ? 'error' : ''}`}>
								* This field is required!
							</p>
						</div>
						<div className="form-group">
							<label className="label" htmlFor="productName">
								<b>Product Name:</b>
							</label>
							<input value={form.productName || ''} onChange={onChange} name="productName" placeholder="Product Name" type="text" id="productName" className={`input ${formError.productName ? 'error' : ''}`} />
							<p data-cy="" className={`error__onblur ${formError.productName ? 'error' : ''}`}>
								* This field is required!
							</p>
						</div>
						<div className="form-group">
							<label className="label" htmlFor="productSku">
								<b>SKU:</b>
							</label>

							<input onChange={onChange} name="productSku" type="text" placeholder="SKU" id="productSku" className={`input ${formError.productSku ? 'error' : ''}`} value={form.productSku} />
							<p data-cy="" className={`error__onblur ${formError.productSku ? 'error' : ''}`}>
								* This field is required!
							</p>
						</div>

						<div className="form-group">
							<label className="label" htmlFor="productStockQuantity">
								<b>Stock Quantity:</b>
							</label>

							<input value={form.productStockQuantity || ''} onChange={onChange} name="productStockQuantity" type="text" pattern="[0-9]*" placeholder="Stock Quantity" id="productStockQuantity" className="input" />
						</div>
						{/* <div className="form-group">
							<label className="label" htmlFor="productCategory">
								<b>Category:</b>
							</label>
							<select value={form.productCategory || ''} onChange={onChange} name="productCategory" id="productCategory" className={`select ${formError.productCategory ? 'error' : ''}`}>
								<option value="" disabled>
									Select a category
								</option>
								{allCategories.map((category) => (
									<option key={category._id} value={category._id}>
										{category.name}
									</option>
								))}
							</select>
							<p data-cy="" className={`error__onblur ${formError.productCategory ? 'error' : ''}`}>
								* This field is required!
							</p>
						</div> */}

						<div className="form-group">
							<span className="label">
								<b>Active status:</b>
							</span>
							<div className="checkbox-group">
								<div className="item">
									<input checked={form.productActive || false} onChange={onChange} name="productActive" type="checkbox" id="productActive" className="checkbox" />
									<label className="label" htmlFor="productActive">
										<b>Active</b>
									</label>
								</div>
							</div>
						</div>
						<div className="form-group">
							<span className="label">
								<b>Middleman:</b>
							</span>
							<div className="checkbox-group">
								<div className="item">
									<input checked={form.productMiddleman || false} onChange={onChange} name="productMiddleman" type="checkbox" id="productMiddleman" className="checkbox" />
									<label className="label" htmlFor="productMiddleman">
										<b>Use Middleman authentification for this shoe</b>
									</label>
								</div>
							</div>
						</div>
						<div className="form-group">
							<span className="label">
								<b>Is new release:</b>
							</span>
							<div className="checkbox-group">
								<div className="item">
									<input checked={form.productNewRelease || false} onChange={onChange} name="productNewRelease" type="checkbox" id="productNewRelease" className="checkbox" />
									<label className="label" htmlFor="productNewRelease">
										<b>New release</b>
									</label>
								</div>
							</div>
						</div>
						<div className="form-group">
							<label className="label" htmlFor="productReleaseDate">
								<b>Release date:</b>
							</label>
							<DatePicker
								value={form.productReleaseDate ? moment(form.productReleaseDate, 'YYYY-MM-DD') : null}
								onChange={handleDateChange}
								placeholder="Release date"
								className={`input ${formError.productReleaseDate ? 'error' : ''}`}
								format="YYYY-MM-DD" // Adjust format as needed
							/>
							<p data-cy="" className={`error__onblur ${formError.productReleaseDate ? 'error' : ''}`}>
								* This field is required!
							</p>
						</div>
					</form>
				</main>
			</div>
		</Wrapper>
	);
};
