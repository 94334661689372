import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../../context/useAuth';
import { SERVER_URL } from '../../../config/index';
import { Section, Main } from '../../../components/content';
import { AXIOS_API_CALL } from '../../../utils/endpoint';
import { PERMISSIONS } from '../../../utils/permissions';
import SelectCustom from '../../../components/customSelect/CustomSelect';
import Loading from '../../../components/loading/Loading';
import Table from './Table';
import LoadingPlaceholder from '../../../components/loadingPlaceholder/LoadingPlaceholder';
import { getGroceryPermissions } from '../../../middleware';
import { notification } from 'antd';
import { VerifyForm } from './verify-form/VerifyForm';
import { RejectForm } from './reject-form/RejectForm';

const options = [5, 10, 20, 50, 100];

const SellerPayoutRequest = () => {
	const { user } = useAuth();
	const location = useLocation();
	const [groceryPermissions, setGroceryPermissions] = useState([]);
	const [refetch, setRefetch] = useState(false);

	// GET USER TOKEN
	useEffect(() => {
		const { token } = user || {};
		if (token) {
			const decodedToken = jwtDecode(token);
			const permissions = decodedToken?.roleData?.permissions;

			if (permissions && location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`)) {
				if (Object.keys(permissions).some((permission) => permission.includes('grocery'))) {
					setGroceryPermissions(permissions.grocery || []);
				}
			}
		}
	}, [user, location]);

	// READ
	const [readData, setReadData] = useState([]);
	const [readDataRefetch, setReadDataRefetch] = useState(false);
	const [readDataLoading, setReadDataLoading] = useState(true);

	// SEARCH
	const [search, setSearch] = useState(null);
	const [searchForm, setSearchForm] = useState({ query: '' });
	const [filterStatus, setFilterStatus] = useState(null);

	const handleOnSubmitSearch = (e) => {
		e.preventDefault();
		setSearch(searchForm.query);
		setReadDataRefetch((prevState) => !prevState);
	};

	const handleOnChangeSearch = (value) => {
		setSearchForm({ query: value });
	};

	const handleOnClearSearch = () => {
		setSearch(null);
		setSearchForm({ query: '' });
		setReadDataRefetch((prevState) => !prevState);
	};

	// PAGE
	const [currentPage, setCurrentPage] = useState(1);
	const [limitPage, setLimitPage] = useState(10);

	// FILTER STATUS
	const handleStatusFilter = (value) => {
		setFilterStatus(value);
		setReadDataRefetch((prevState) => !prevState);
	};

	// FILTERS

	// Get selected rows id one or more
	const [checkedRow, setCheckedRow] = useState([]);
	const handleCheckedRows = (data) => {
		setCheckedRow(data);
	};

	const getReadData = useCallback(async () => {
		const { token } = user;

		try {
			const response = await axios
				.get(`${SERVER_URL}/${AXIOS_API_CALL.transaction}/${currentPage}/${limitPage}/${search || null}/${filterStatus || null}`, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } })
				.then((res) => {
					return res;
				})
				.catch((err) => console.error(err))
				.finally(
					setTimeout(() => {
						setReadDataLoading(false);
					}, 200)
				);
			return response;
		} catch (err) {
			console.error('Error fetching data:', err);
		}
	}, [user, limitPage, search, currentPage, filterStatus]);

	useEffect(() => {
		let isMounted = true;

		new Promise((resolve, reject) => {
			setReadDataLoading(true);
			setTimeout(() => {
				resolve(getReadData());
			}, 700);
		}).then((res) => {
			if (isMounted) {
				setReadData(res?.data);
			}
		});

		return () => {
			isMounted = false;
		};
	}, [getReadData, readDataRefetch, refetch]);

	useEffect(() => {
		if (!!search) {
			setCurrentPage(1);
		}
	}, [search, setCurrentPage]);

	useEffect(() => {
		setCurrentPage(1);
	}, [limitPage, setCurrentPage]);

	useEffect(() => {
		getReadData();
	}, [getReadData, refetch]);

	const [verifyTransaction, setVerifyTransaction] = useState([]);
	const [rejectTransaction, setRejectTransaction] = useState([]);

	const [toggleVerify, setToggleVerify] = useState(false);
	const [toggleReject, setToggleReject] = useState(false);
	const verifyModalFormRef = useRef(null);
	const rejectModalFormRef = useRef(null);
	const [verifyBtnLoader, setVerifyBtnLoader] = useState(false);
	const [rejectBtnLoader, setRejectBtnLoader] = useState(false);

	const verifyFormFields = [
		{
			name: 'verifyTransactionId',
			value: verifyTransaction,
		},
		{
			name: 'status',
			value: verifyTransaction.status,
		},
	];

	const rejectFormFields = [
		{
			name: 'rejectTransactionId',
			value: rejectTransaction,
		},
		{
			name: 'status',
			value: rejectTransaction.status,
		},
	];

	function handleToggle(props) {
		const { name, state, data } = props;
		switch (name) {
			case 'verify':
				setVerifyTransaction(data);
				state.onToggle((prevState) => !prevState);
				break;

			case 'reject':
				setRejectTransaction(data);
				state.onToggle((prevState) => !prevState);
				break;
			default:
				console.warn('Default of: handleToggle function');
		}
	}

	const handleOnFinish = async (props) => {
		const { name, data } = props;
		const { token } = user;
		console.log(data);
		switch (name) {
			case 'verify':
				try {
					const transactionId = data?.verifyTransactionId?._id;
					const verifyData = {
						status: 'approved',
					};
					await axios.post(
						`${SERVER_URL}/${AXIOS_API_CALL.transactionReview}/${transactionId}`,
						{ ...verifyData },
						{
							withCredentials: false,
							headers: { Authorization: `Bearer ${token}` },
						}
					);

					notification.success({
						message: 'User is verified.',
						placement: 'bottomLeft',
					});
					setToggleVerify(false);
					setReadDataRefetch((prevState) => !prevState);
				} catch (err) {
					notification.error({
						message: err.response?.data?.message,
						placement: 'bottomLeft',
					});
          setToggleVerify(false);
					console.error(err);
				}
				break;
			case 'reject':
				try {
					const transactionId = data?.rejectTransactionId?._id;
					const rejectData = {
						status: 'rejected',
					};
					console.log(rejectData, data);
					await axios.post(
						`${SERVER_URL}/${AXIOS_API_CALL.transactionReview}/${transactionId}`,
						{ ...rejectData },
						{
							withCredentials: false,
							headers: { Authorization: `Bearer ${token}` },
						}
					);

					notification.success({
						message: 'User is rejected.',
						placement: 'bottomLeft',
					});
					setToggleReject(false);
					setReadDataRefetch((prevState) => !prevState);
				} catch (err) {
					console.log(data);
					notification.error({
						message: err.response?.data?.message,
						placement: 'bottomLeft',
					});
          setToggleReject(false);
					console.error(err);
				}
				break;

			default:
				console.error('handleOnFinish ---- error');
		}
	};

	const verifyProps = {
		active: toggleVerify,
		onToggle: setToggleVerify,
		formRef: verifyModalFormRef,
		data: verifyTransaction,
		onData: setVerifyTransaction,
	};

	const rejectProps = {
		active: toggleReject,
		onToggle: setToggleReject,
		formRef: rejectModalFormRef,
		data: rejectTransaction,
		onData: setRejectTransaction,
	};

	const verifyFormProps = {
		verifyProps,
		verifyModalFormRef,
		handleOnFinish,
		verifyFormFields,
		verifyBtnLoader,
		setToggleVerify,
	};

	const rejectFormProps = {
		rejectProps,
		rejectModalFormRef,
		handleOnFinish,
		rejectFormFields,
		rejectBtnLoader,
		setToggleReject,
	};

	const tableProps = {};

	const tableActionsProps = {
		handleToggle: handleToggle,
		verifyActions: verifyProps,
		rejectActions: rejectProps,
		location: location,
		groceryPermissions: groceryPermissions,
		getGroceryPermissions: getGroceryPermissions,
	};

	const tableFiltersProps = {
		handleStatusFilter: handleStatusFilter,
	};

	// PAGINATION
	const paginationProps = {
		current: currentPage,
		setCurrentPage: setCurrentPage,
		limitPage: limitPage,
		buttonLimit: 3,
		pagesCount: 1,
		totalCount: 1,
	};

	console.log(readData?.data?.Data);

	return (
		<>
			<h2 className="page-title">Seller Payout Request</h2>
			<Section className="section__wrapper section__blockList">
				<header className="section__header">
					{!readDataLoading ? (
						<div className="filter">
							<span style={{ color: '#333', fontWeight: '500' }}>Show:</span>
							<SelectCustom options={options} label={'Items'} limit={limitPage} setLimit={setLimitPage} setRefetch={setReadDataRefetch} loading={readDataLoading} />
						</div>
					) : (
						<LoadingPlaceholder style={{ width: '155.5px', height: '50px' }} />
					)}
					{!readDataLoading ? (
						<div className="search">
							<form className="search-form" onSubmit={(e) => handleOnSubmitSearch(e)} data-cy="search-roles">
								<div className="form-group m-0">
									<span className="icon icon-search">
										<img src="/assets/icons/search.svg" alt="Search" title="Search" />
									</span>
									<input value={searchForm.query || ''} type="text" data-cy="search-input-field" className="input" placeholder="Search" onChange={(e) => handleOnChangeSearch(e.target.value)} />
									<span className={`icon icon-close ${!!searchForm.query && searchForm.query.length > 0 ? 'isActive' : ''}`} onClick={() => handleOnClearSearch()}>
										<img src="/assets/icons/times.svg" alt="Clear" title="Clear" />
									</span>
								</div>
								<button className="form-submit" data-cy="search-submit-btn" disabled={!searchForm.query || searchForm.query.length === 0}>
									Search
								</button>
							</form>
						</div>
					) : (
						<LoadingPlaceholder style={{ width: '100%', maxWidth: '850px', height: '50px' }} />
					)}
				</header>
				<Main className="section__content section__content relative min-h-table-content">{readDataLoading ? <Loading /> : <Table data={readData?.data?.Data} {...tableActionsProps} {...tableFiltersProps} pagination={paginationProps} />}</Main>
				{/* Delete User - delete */}
				<VerifyForm {...verifyFormProps} />
				<RejectForm {...rejectFormProps} />
			</Section>
		</>
	);
};

export default SellerPayoutRequest;
